.ant-menu-submenu,
.ant-menu-submenu-popup,
.ant-menu-light,
.ant-menu-submenu-placement-bottomLeft {
  & > ul {
    border-bottom: none !important;
    li {
      height: 60px !important;

      .ant-menu-submenu-title {
        span {
          font-size: 20px;
        }
      }
    }
  }
}
.minimal-menu-popup {
  & > ul > li {
    height: 40px !important;
    padding: 0 !important;
  }
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #4285f4 !important;
  & > span > div {
    i {
      svg {
        fill: #4285f4;
        path {
          fill: #4285f4;
        }
      }
    }
  }
}

.ant-menu-item-selected {
  color: #4285f4 !important;
  background-color: #ffffff !important;
  & > span > div > i > svg > path {
    fill: #4285f4;
  }
  & svg {
    fill: #4285f4;
  }
  & path {
    fill: #4285f4;
  }
}

// Navbar text hover color
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  color: #4285f4 !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item-active svg {
  fill: #4285f4 !important;
}
.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item-active path {
  fill: #4285f4 !important;
}
.ant-menu-vertical:not(.ant-menu-dark) .ant-menu-item-active svg {
  fill: #4285f4 !important;
}
.ant-menu-vertical:not(.ant-menu-dark) .ant-menu-item-active path {
  fill: #4285f4 !important;
}
// Navbar border color
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #4285f4 !important;
}
// Navbar submenu width
.ant-menu-vertical.ant-menu-sub {
  min-width: 110px !important;
}
.ant-menu-vertical.ant-menu-sub .header-menu__wrapper {
  min-width: 170px;
  border-radius: 8px;
  padding: 5px 8px;
  & .header-menu__link {
    border-radius: 8px;
    padding: 5px 8px;
  }
  & .header-menu__title {
    display: block;
    width: 100%;
  }
  // & .header-menu__link {
  //   // border: 1px solid transparent;
  //   border-radius: 8px;
  //   padding: 5px 8px;
  //   &:hover {
  //     border: 1px solid #5087ec;
  //   }
  // }
}
.ant-menu-vertical.ant-menu-sub .header-menu__wrapper.without_text {
  width: 100%;
  min-width: 100%;
  & .header-menu__link {
    justify-content: center;
  }
}

// Decrease icons align in submenu
.ant-menu-vertical > .ant-menu-item {
  line-height: 35px;
}
.ant-menu-overflow-item-rest {
  &:hover {
    color: #4285f4 !important;

    & > div > div > svg {
      fill: #4285f4;

      path {
        fill: #4285f4;
      }
    }
    div > div > p {
      color: #4285f4 !important;
    }
  }
  text-align: center;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active {
  color: #4285f4 !important;
}

// Change default hover text color
.ant-menu-item:hover {
  color: #4285f4 !important;
}
