.time-card-table {
  &-alert {
    text-align: center;
    color: red;
    margin-bottom: 20px;
  }
  &-button {
    margin-bottom: 20px;
  }
  &-form {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .timecard {
    position: relative;
    & > .row {
      margin: 5px !important;
      font-size: 11px;

      & > .row {
        width: 104%;

        .date {
          margin-left: 10px !important;
        }

        .date,
        .work-time {
          margin-right: 10px !important;
        }

        .myArrow {
          i {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 499px) {
  .timecard {
    position: relative;
    & > .row {
      margin: 5px !important;
      font-size: 8px;

      & > .row {
        width: 104%;

        .date {
          margin-left: 5px !important;
        }

        .date,
        .work-time {
          margin-right: 5px !important;
        }

        .myArrow {
          i {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}
