.modal_update_card {
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    gap: 50px;
  }

  // .ant-modal-content {
  //   padding: 37px 37px 37px 3px;
  //   .ant-modal-footer {
  //     border-top: none;
  //     margin-left: 37px;
  //     display: flex;
  //     justify-content: center;
  //     .ant-btn-primary {
  //       font-weight: 500;
  //       width: 76px;
  //       font-size: 14px;
  //       color: #ffffff;
  //       background: #4285f4;
  //       border-radius: 4px;
  //     }
  //     .ant-btn {
  //       width: 90px;
  //       // height: 41px;
  //       font-weight: 500;
  //       font-size: 14px;
  //     }
  //   }
  // }
}
