.proposal-form-signature_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-signature {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 5px 100px;
    border: 1px solid #dcdfe5;
    border-radius: 4px;
    & > img {
      width: 200px;
      height: 130px;
      object-fit: contain;
    }
  }
  &-signature_info {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 8px;
    opacity: 0.5;
  }
  &-no_signature_info {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.01em;
    color: #191919;

    @media (max-width: 499px) {
      font-size: 10px;
    }
  }

  @media (max-width: 499px) {
    &-signature {
      padding: 5px 30px;
    }
  }
}
