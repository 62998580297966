.MuiButton-outlined.edit-button {
  padding: 0;
  margin-left: 10px;
  min-width: 35px;
  height: 30px;
  align-self: flex-end;

  .MuiButton-label {
    margin-right: -12px;
  }
}
