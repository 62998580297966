$mainColorLighter: #1790ff;

.MuiOutlinedInput-root:hover:not(.Mui-error):not(.Mui-disabled) fieldset {
  border-color: $mainColorLighter;
}
.Mui-focused:not(.Mui-error) {
  fieldset {
    border-color: $mainColorLighter;
    border-width: 1.5px;
  }
}

.MuiSelect-select:focus {
  background-color: initial;
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-select-selection:hover {
  border-color: $mainColorLighter;
}
/* .ant-select {
  &:hover {
    .ant-select-selector {
      border-color: $mainColorLighter !important;
    }
  }
} */
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f7f7f7;
}

.ant-checkbox {
  margin-right: 7px;
}

//Global styles for mui elements

.MuiTextField-root,
.MuiFormControl-root {
  font-size: 1rem;
  .MuiInputLabel-outlined {
    &.MuiInputLabel-marginDense {
      transform: translate(14px, 8px) scale(1);
    }
    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 5px;
  }

  .MuiOutlinedInput-root {
    input.MuiOutlinedInput-inputMarginDense {
      padding: 5.5px 15px;

      @media (max-width: 500px) {
        padding: 5.5px 5px;
      }
    }
    .MuiOutlinedInput-inputMultiline {
      padding: 5.5px 15px;
      min-height: 20px;

      @media (max-width: 500px) {
        padding: 5px 10px;
      }
    }

    .MuiSelect-outlined.MuiSelect-outlined {
      padding: 5px 32px 5px 14px;

      @media (max-width: 500px) {
        padding: 5px 25px 5px 5px;
      }
    }
  }

  .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
    padding-top: 0;
    padding-bottom: 0;
    padding: 0;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] {
    padding: 1px 5px;
  }

  .MuiInputAdornment-positionStart {
    margin-right: 0;
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: 10px;
    & > input {
      padding-left: 0 !important;
    }
  }

  /*  @media (max-width: 499px) {
    .MuiOutlinedInput-root {
      font-size: 8px;
      line-height: 10px;

      .MuiOutlinedInput-inputMultiline {
        padding: 5px 10px;
      }


    }
  } */
}

.MuiInputAdornment-root {
  .MuiTypography-root {
    margin-right: 2px;
    font-size: 0.85rem;
  }
}

.freeze-all .MuiTextField-root .MuiOutlinedInput-adornedStart,
.MuiFormControl-root .MuiOutlinedInput-adornedStart {
  color: rgba(0, 0, 0, 0.65);
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 25px !important;
}

.ant-input-lg,
.ant-select-auto-complete.ant-select .ant-input {
  height: 30px;
}
.ant-form-item-control,
.ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
  line-height: 1;
}

.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  top: 35%;
}

//Edn of Global styles for mui elements

@media (max-width: 499px) {
  input.ant-calendar-picker-input.ant-input {
    height: 25px;
  }

  .ant-time-picker {
    width: 76px;
    input.ant-time-picker-input {
      height: 25px;
      font-size: 9px;
    }
    &-icon {
      display: none;
    }
  }

  .MuiAutocomplete-popper {
    .MuiAutocomplete-option {
      min-height: 20px;
      font-size: 8px;
      padding: 6px 10px;
      & > div {
        font-size: 8px !important;

        i {
          font-size: 14px !important;
          line-height: 1;
        }
      }
    }
  }
  .MuiPopover-root {
    .MuiMenu-list {
      & > li {
        min-height: 20px;
        font-size: 8px;
        padding: 6px 10px;
      }
    }
  }

  //Ant styles

  .ant-tooltip {
    font-size: 9px;
  }
  .ant-modal-body {
    padding: 10px;
  }
  .ant-checkbox {
    margin-right: 3px;
  }
}

.ant-legacy-form-item {
  margin-bottom: 0 !important;
}

.ant-legacy-form-explain {
  font-size: 12px !important;
}

.ant-popover {
  z-index: 1060; //temp

  .btn {
    font-size: 8px;
    height: 20px;
    margin-top: 5px;
  }

  .mobile-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .btn {
      margin-top: 0;
    }
  }
}

.ant-calendar-picker-container {
  z-index: 1060;
}

.timepicker-popup {
  .ant-picker-content {
    height: initial;

    ul.ant-picker-time-panel-column {
      &:hover {
        overflow-y: hidden;
      }

      &::after {
        content: none;
      }

      li {
        height: 25px;
        line-height: 25px;
      }
    }
  }
}

.ant-legacy-form-item {
  margin-bottom: 0;
}
