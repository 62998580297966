body {
  background-color: #ffffff;
  // font-family: 'Poppins', sans-serif;
}

/* h1,
h2,
h3,
h4 {
  font-family: 'Poppins', sans-serif;
} */

.admin-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.admin-page-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.admin-module-wrapper {
  // ::-webkit-scrollbar {
  //   width: 50px;
  //   background-color: #4285f4;
  // }
}

.sa-error-field-response {
  color: #f44336;
  font-size: 11px;
  font-weight: 400;
  display: inherit;
}

/* CONFIRMATION MODAL */
.sa-confModalBackdrop {
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
  height: 100%;
}

.sa-confModal {
  position: absolute;
  border-radius: 4px;
  width: 600px;
  padding: 16px 16px 36px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 10;
  text-align: center;
}

.sa-closeBtnRow {
  display: flex;
  justify-content: flex-end;
}

.sa-closeBtn {
  border: none;
  background-color: transparent;
}

.sa-confText {
  margin: 0 auto;
  width: 400px;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #000;
}

.sa-cancelBtn {
  color: #000000;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 11px 22px;
  background-color: #ffffff;
}

.sa-submitBtn {
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 11px 22px;
  background-color: #4285f4;
  margin-left: 38px;
}

// Hide arrows for input number
/* Chrome, Safari, Edge, Opera */
input.arrow-hidden::-webkit-outer-spin-button,
input.arrow-hidden::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'].arrow-hidden {
  -moz-appearance: textfield;
}

.errorEditor {
  .tox-tinymce {
    border: 1px solid #f44336;
  }
}
