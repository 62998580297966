.custom-page-layout {
  //padding: 10px;
  // .card-body {
  //   padding: 0 1rem;
  // }
  & .page-title-boxs {
    padding: 10px 20px 0;
  }
  max-width: 3000px;
  .page-title {
    margin: 0;
    font-size: 21px;
    font-weight: 600;
  }
  .custom-page-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .page-title {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 20px;
      margin-right: 30px;
    }

    .page-action {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .page-content {
      width: 100%;
    }

    @media (max-width: 499px) {
      padding: 10px;
    }
  }
  .loading {
    width: 100%;
  }

  // @media(max-width: ;)
}
.custom-page-layout.w100 {
  width: 100%;
  max-width: none;
}

.custom-page-layout.table-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow: scroll;
  background-color: white;
  & .ant-table-content {
    height: 89vh !important;
  }
}
@media screen and (max-width: 499px) {
  .custom-page-layout {
    .page-title {
      font-size: 18px;
      // margin-bottom: 20px;
    }

    .card-body {
      padding: 10px;
    }
  }

  .custom-page-headers .page-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 10px;
  }
  .page-action {
    width: 100%;
  }
}
