.row-title {
  font-size: 1rem;
  font-weight: bold;
  margin: 15px 0;
}

.row-head {
  display: grid;
  grid-template-columns: 73% 5% 5%;
  grid-template-rows: auto auto auto;
  .row-sub-title {
    font-size: 1rem;
  }
}

.row-container__outer {
  padding: 10px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  margin-bottom: 10px;
  & .deleted-text,
  .updated-text,
  .created-text {
    line-height: 1;
    // display: flex;
    // justify-content: space-between;
    // height: 15px;
    i {
      font-size: 10px;
      color: #000000d9;
      opacity: 0.5;
    }
    .deleted-text__tooltip {
      cursor: pointer;
    }
  }
}

.row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  margin: 5px 0;
  & .MuiInputBase-multiline {
    padding: 6px !important;
  }
  & > .row-text-input {
    margin-right: 20px;
    width: 95%;
    height: 30px;
  }
  & > .error {
    border-color: red !important;
  }
  & > .row-price-input {
    width: 150px;
    margin-bottom: 0 !important;
    .MuiInputBase-root,
    .MuiOutlinedInput-root,
    .MuiInputBase-formControl,
    .MuiInputBase-marginDense,
    .MuiOutlinedInput-marginDense {
      height: 100%;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  & > .error {
    .MuiInputBase-root,
    .MuiOutlinedInput-root,
    .MuiInputBase-formControl,
    .MuiInputBase-marginDense,
    .MuiOutlinedInput-marginDense {
      fieldset {
        border-color: red !important;
      }
    }
  }

  & > .row-actions {
    width: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    .ant-badge > .fas,
    .fa-paperclip {
      font-size: 17px;
      cursor: pointer;
      margin-right: 5px;
    }

    .fas,
    .addIcon {
      cursor: pointer;
    }

    .delete-action {
      padding-bottom: 5px;
      cursor: pointer;
      text-align: center;
      & > svg {
        width: 17px;
        height: 19px;
      }
    }
  }
}

.new-row {
  max-width: 300px;
}

/* @media (max-width: 767px) {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: 50px 50px;
  gap: 0px 0px;
  & > :nth-child(2) {
    display: none;
  }

  .row-container {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-end;

    .new-row {
      max-width: 100%;
      width: 100%;
    }

    .row-text-input {
      width: 100%;
      height: 20px;
      order: 1;
      margin-right: 0;
    }

    .row-price-input {
      width: 50%;
      order: 3;
      .MuiInputBase-root,
      .MuiOutlinedInput-root,
      .MuiInputBase-formControl,
      .MuiInputBase-marginDense,
      .MuiOutlinedInput-marginDense {
        margin-top: 5px;
        height: 20px;
        width: 100%;
      }
    }

    .row-actions {
      width: 50%;
      height: 20px;
      justify-content: space-evenly;
      order: 2;

      .ant-badge {
        order: 2;
      }
      .delete-action {
        order: 1;
        margin-left: 5px;
      }
    }
  }
}
 */
