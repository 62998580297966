.create-proposal {
  // width: 350px;
  // margin-top: 20px;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .buttons {
    display: flex;
    justify-content: center;

    & > :nth-child(1) {
      margin-right: 10px;
    }
  }
}
