.personal-information {
  & > .MuiTextField-root {
    width: calc(100% - 140px);
  }

  & > div {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &.phone-row,
    &.email-row {
      display: flex;

      .phone-type {
        flex: 0 1 160px;
        margin-left: 20px;
      }

      .email-field {
        width: calc(100% - 125px);
      }

      .radio-group,
      .checkbox-group {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        flex: 0 1 100px;
        margin-left: 30px;
        margin-right: 15px;

        .MuiFormControlLabel-root {
          margin: 0;
          position: relative;
        }

        .MuiFormControlLabel-label {
          font-size: 8px;
          line-height: 1;
          text-align: center;
          position: absolute;
          top: -12px;
          width: 50px;
        }
      }
    }
  }
}
