.custom-page-layout {
  max-width: 3000px;

  &.matrix-containter {
    overflow: hidden;
  }

  .card-body {
    padding: 0 5px;
  }
  .page-title {
    margin: 0;
    font-size: 21px;
    font-weight: 600;
  }
  .custom-page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .page-title {
      flex: 0 0 auto;
      margin-right: 50px;
    }

    .page-actions {
      flex: 1 0 auto;
      display: flex;
      gap: 10px;
    }

    .page-content {
      width: 100%;
    }

    @media (max-width: 499px) {
      padding: 10px;
    }
  }
  .loading {
    width: 100%;
  }

  // @media(max-width: ;)
}
.custom-page-layout.w100 {
  width: 100%;
  max-width: none;
}
@media screen and (max-width: 499px) {
  .custom-page-layout {
    .page-title {
      font-size: 18px;
      // margin-bottom: 20px;
    }

    .card-body {
      padding: 10px;
    }
  }
}
