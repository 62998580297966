$timeLineColor: #ea4335;
.matrix-containter .card-body {
  padding: 5px 10px 10px 10px;
}
.matrix_search-input {
  position: fixed;
  top: unset;
}
.react-transform-wrapper__inner {
  padding-top: 240px !important;
  @media screen and (min-width: 670px) {
    padding-top: 170px !important;
  }
  @media screen and (min-width: 1140px) {
    padding-top: 130px !important;
  }
}

.matrix-v2 {
  // height: calc(100vh - 180px);
  & header {
    width: 100%;
    // width: 100vw;
  }
  // & .box_popever {
  //   min-width: 185px;
  // }
  & .matrix__spin {
    position: fixed;
    left: 48%;
    top: 300px;
  }

  .matrix__header-settings {
    position: absolute;
    width: 100%;
    background-color: white;
    padding: 10px;
    z-index: 1;
    transition: all 0.3s;
    transform: translateY(0);
  }

  .hide-matrix__header {
    transform: translateY(-60px);
    transition: all 0.3s;
    z-index: 1;
  }
  .show-matrix__header {
    transform: translateY(0);
    transition: all 0.3s;
    // z-index: 2;
  }

  & .visible {
    max-height: 70px;
  }
  & .hidden {
    max-height: 0px;
    display: none;
  }

  .settings__wrapper {
    position: absolute;
    right: 50px;
    top: 10px;
    z-index: 999;
    cursor: pointer;
    & svg {
      fill: #4285f4;
    }
  }

  .sticky_header {
    transform: translateY(-300%);
    transition: all 0.3s linear;
    display: none !important;
    visibility: hidden;
    margin-bottom: 0 !important;
  }

  .header {
    display: flex;
    transition: all 0.3s linear;
    // justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    // max-width: fit-content;
    // margin-bottom: 15px;
    gap: 25px;
    // margin-top: 10px;

    @media (max-width: 800px) {
      /*  justify-content: center;
      .d-flex-center {
        margin: 10px 0;
      } */
    }
    .d-flex-center {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
    }

    // .btn {
    /*  @media (max-width: 400px) {
        height: auto;
      } */
    // height: 41px;
    // margin: 0 15.5px;
    // }
    .queue-icon {
      position: relative;
    }
    .btn-arrow {
      width: 34px;
      height: 34px;
      color: black;
      background-color: #f7f7f7;
      border-radius: 20px;
      border: none;
      margin: 0 4px;
      &:hover {
        background-color: #eeeefc;
        color: #4d53ef;
      }
    }
    .counter {
      width: 15px;
      height: 15px;
      position: absolute;
      background: orange;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      right: -6px;
      top: -6px;
      font-size: 8px;
    }
    .icons {
      display: flex;
      justify-content: space-around;
      margin-right: 15px;

      .icon {
        transform: none;
        height: 40px;
      }
      i {
        font-size: 14px;
      }
      p {
        font-size: 10px;
      }
      .active {
        i {
          color: #38a4f8;
        }
      }
      div {
        text-align: center;
        cursor: pointer;
        &:hover {
          i {
            color: #4d53ef;
          }
        }
      }
    }
    .calendar-header {
      display: flex;
      justify-content: center;
      min-width: 350px;
      margin: 10px 10px;
      position: relative;
    }
    .mdi-calendar-multiselect {
      font-size: 25px;
      top: 10px;
    }
    .group {
      min-width: 74px;
      button {
        margin: 0px 2px;
      }
    }

    .today {
      // margin-left: 15px;
      background-color: #f7f7f7;
      color: black;
      &:hover {
        background-color: #eeeefc;
        color: #4d53ef;
      }

      /*       & + div {
        margin-right: 15px;
      } */
    }
    .ant-calendar-picker {
      input {
        border: none;
        box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
      }

      .ant-input {
        border: none;
      }
    }
  }

  & > .body {
    // display: flex;
    overflow: hidden;
    position: relative;
    flex-wrap: wrap;
    height: 100%;

    .body {
      position: relative;
      display: flex;
      margin-right: 10px;
      // overflow-x: auto;
      display: flex;
      // height: -webkit-fill-available;
      // & .time-container {
      //   height: fit-content;
      // }
    }

    .queues {
      flex: 0 0 100%;
      // padding-top: 4px;
      // margin-bottom: 1rem;
      // overflow-x: auto;

      & > * {
        margin-bottom: 4px;

        @media (max-width: 500px) {
          margin-bottom: 0;
        }
      }

      p {
        margin-bottom: 0;
        line-height: 1;
      }

      .queues-icons {
        display: flex;
        gap: 10px;
        overflow: auto;
      }
    }

    & > .queue {
      div[data-simplebar] {
        box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
      }
      margin-left: 10px;

      .queue-header {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        height: 70px;
        box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
        width: 60px;
        padding: 5px;
        margin-bottom: 5px;
        font-size: 8px;
        font-weight: 900;
      }
      .matrix-popover {
        box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.5);
      }

      .queue-column {
        width: 60px;
        display: flex;
        flex-direction: column;
        overflow-x: auto;
        overflow-x: hidden;
        position: relative;
        &::-webkit-scrollbar {
          display: none;
          -ms-overflow-style: none;
          scrollbar-width: none;
        }
        .events {
          top: 0;
          position: absolute;
          display: flex;
          flex-direction: column;
        }
        /*  .queue-event {
          position: relative;
          width: 60px;
          overflow: hidden;
          margin: 2.5px 0;
          border-radius: 4px;
          height: 40px;
          background-color: #4b94d8;
          .draggable-event {
            cursor: grab;

            height: 100%;
            display: flex;
            color: white;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            display: flex;
            justify-content: center;
            font-size: 6px;
            line-height: 1.33;

            & > span > * {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          &.lead {
            background-color: #22c906;
          }

          &.recurring {
            border-radius: 18px;
          }

          &.job {
            // border: 2px dashed white;
            background-position: 0 0, 0 0, 100% 0, 0 100%;
            background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
            background-repeat: no-repeat;
            background-image: repeating-linear-gradient(
                0deg,
                #fff,
                #fff 10px,
                #000 10px,
                #000 20px
              ),
              repeating-linear-gradient(
                90deg,
                #fff,
                #fff 10px,
                #000 10px,
                #000 20px
              ),
              repeating-linear-gradient(
                180deg,
                #fff,
                #fff 10px,
                #000 10px,
                #000 20px
              ),
              repeating-linear-gradient(
                270deg,
                #fff,
                #fff 10px,
                #000 10px,
                #000 20px
              );
          }
        } */
      }

      @media (max-width: 500px) {
        margin-left: 3px;
      }
    }

    & > .to-do-column {
      margin-right: 10px;
      position: relative;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      .todo-header {
        height: 70px;
        text-align: center;
        box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
        width: 60px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 5px;
        margin-bottom: 5px;
        font-size: 8px;
        font-weight: 900;
      }
      .todo-events {
        top: 75px;
        position: absolute;
        display: flex;
        flex-direction: column;

        &.myqueue {
          gap: 5px;
          margin-top: 2.5px;

          .queue-event {
            width: 60px;
            height: 40px;
          }
        }

        .todo-event {
          position: relative;
          width: 60px;
          overflow: hidden;
          margin: 2.5px 0;
          border-radius: 4px;
          height: 40px;
          background-color: #4b94d8;
          font-size: 6px;
          line-height: 1.33;
          color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .task-name {
            width: 90%;
            text-align: center;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
          }

          &.past {
            background-color: #d91717;
          }
          &.actual {
            background-color: #fcfc1e;

            p {
              color: #000;
            }
          }
          &.future {
            background-color: #00ca00;
          }
        }
      }

      @media (max-width: 500px) {
        margin-left: 2px;

        .todo-header {
          height: 20px;
          width: 20px;
          font-size: 4px;
          padding: 2px;
        }

        .todo-events {
          top: 25px;

          .todo-event {
            width: 20px;
            height: 20px;
            font-size: 3px;
          }
        }
      }
    }
  }
  .preloader-matrix {
    z-index: 1001;
    position: absolute;
    top: 54.5px;
    left: 30px;
    right: 0;
    bottom: 0;
    /* height: 100%;
    width: 100%; */
    background: rgba(255, 255, 255, 0.6);
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .resources {
    overflow-x: auto;

    /*    .box {
      &:last-child {
        background-color: #ddd !important;
      }
    } */
  }
  .resources-scroll_top {
    overflow-y: hidden;
    height: 15px;
  }
  .resource-title {
    height: 70px;
    text-align: center;
    box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
    // width: 125px;
    width: 60px;
    padding: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .avatar {
      margin: auto;
    }

    &.active {
      background-color: #4c53ef17;
    }

    // img {
    //   height: auto;
    //   width: 32px;

    //   flex: 0;
    //   object-fit: cover;
    //   border-radius: 14px;
    // }
    .tech-text {
      flex: 1;
      // width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      // margin-left: 5px;
    }
    .tech-title {
      font-size: 8px;
      width: 100%;
      font-weight: 900;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
    .tech-role {
      clear: both;
      font-size: 7px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  }
  .time-container {
    position: relative;
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    .time {
      height: 40px;
      width: 30px;
      box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
      border-radius: 4px;
      margin: 2.5px;
      font-size: 10px;
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: 700;
      // justify-content: center;
    }
  }
  .column-container {
    display: flex;
    margin-right: 1.5px;
    overflow: hidden;

    .column {
      display: flex;
      flex-direction: column;
      margin: 0 2.5px;
      .playground {
        position: relative;
      }
    }
  }

  .boxes {
    display: flex;
    flex-direction: column;
    .hovered_box:hover {
      background-color: #eef3f5 !important;
    }

    .box {
      position: relative;
      height: 40px;
      width: 60px;
      margin: 2.5px 0;
      border-radius: 4px;
      background-color: white;
      box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
      cursor: grabbing;

      // &:hover {
      //   background-color: #d2e4ed;
      // }
      & .box__background {
        position: absolute;
        top: 0;
        height: 0;
        width: 100%;
      }
    }

    @media (max-width: 500px) {
      .box {
        width: 20px;
        height: 20px;
      }
    }
  }
  .prefered {
    border: 2.5px solid rgb(209, 109, 106);
  }
  /*   .current {
    opacity: 0.6;
  } */
  .event {
    position: absolute;
    // width: 125px;
    width: 100%;
    margin: 2.5px 0;
    min-height: 30px;
    border-radius: 4px;
    font-size: 6px;
    font-family: 'Poppins';
    line-height: 1.2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    // text-align: center;
    // overflow: hidden;
    pointer-events: visible;

    .draggable-event {
      cursor: grab;
      height: 100%;
      color: white;
      padding: 1px 2px;
      // display: flex;
      // justify-content: space-around;
      // flex-direction: column;
      // text-align: center;
      border-radius: 4px;
      overflow: hidden;

      & > span > * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // text-align: center;
        width: 100%;

        &.zip {
          position: relative;

          i {
            color: black;
            padding-left: 2px;
            // position: absolute;
            line-height: 1.5;
          }
        }
      }

      // &.job {
      //   background-position: 0 0, 0 0, 100% 0, 0 100%;
      //   background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
      //   background-repeat: no-repeat;
      //   background-image: repeating-linear-gradient(
      //       0deg,
      //       #fff,
      //       #fff 5px,
      //       #000 5px,
      //       #000 10px
      //     ),
      //     repeating-linear-gradient(90deg, #fff, #fff 5px, #000 5px, #000 10px),
      //     repeating-linear-gradient(180deg, #fff, #fff 5px, #000 5px, #000 10px),
      //     repeating-linear-gradient(270deg, #fff, #fff 5px, #000 5px, #000 10px);
      // }
    }
    a {
      text-decoration: none;
      color: white;
    }
    button {
      height: 10px;
      position: absolute;
      line-height: 8px;
      border: none;
      background: none;
      color: white;
      font-size: 20px;
      letter-spacing: 4px;
      justify-content: center;
      z-index: 990;
      left: calc(50% - 10px);
      display: flex;
      .circle {
        height: 8px;
        width: 8px;
        background-color: white;
        opacity: 0;
        z-index: 400;
        border-radius: 90px;
      }
    }
    .first {
      top: 0;
      z-index: 400;
      cursor: ns-resize;
    }
    .second {
      bottom: 0;
      z-index: 400;
      cursor: ns-resize;
    }
    &.show-resize {
      .circle {
        opacity: 1;
      }
    }
    &:hover {
      .circle {
        opacity: 1;
      }
    }

    &.nodrag {
      .draggable-event {
        cursor: pointer;
      }
    }

    &.recurring {
      .draggable-event {
        border-radius: 15px;
        padding-left: 6px;
      }
    }
    &.recurring-job {
      .draggable-event {
        border: 1px dashed #000;
      }
    }

    &.job {
      .draggable-event {
        border: 1.4px dashed black;
        // background-position: 0 0, 0 0, 100% 0, 0 100%;
        // background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
        // background-repeat: no-repeat;
        // background-image: repeating-linear-gradient(
        //     0deg,
        //     #fff,
        //     #fff 5px,
        //     #000 5px,
        //     #000 10px
        //   ),
        //   repeating-linear-gradient(90deg, #fff, #fff 5px, #000 5px, #000 10px),
        //   repeating-linear-gradient(180deg, #fff, #fff 5px, #000 5px, #000 10px),
        //   repeating-linear-gradient(270deg, #fff, #fff 5px, #000 5px, #000 10px);
      }
    }
  }
  .misc-column {
    display: flex;
    flex-direction: column;
    position: relative;
    //overflow-y: auto;
    //overflow-x: hidden;
    margin: 0 2.5px;
    .events {
      top: 0;
      position: absolute;
      display: flex;
      flex-direction: column;

      .queue-event {
        position: relative;
        overflow: hidden;
        width: 60px;
        margin: 2.5px 0;
        border-radius: 4px;
        height: 40px;
        .draggable-event {
          cursor: grab;

          height: 100%;
          color: white;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          display: flex;
          font-size: 10px;

          & > span > * {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .timeoff {
    .timeoff-event {
      // width: 125px;
      width: 100%;
      margin: 2.5px 0;
      border-radius: 4px;
      font-size: 6px;
      // padding-top: 10%;
      padding-top: 2px;
      justify-content: center;
      flex-direction: column;
      // text-align: center;
      display: flex;
      background-color: #f1f1f1;
      position: absolute;
      overflow: hidden;
      // z-index: 1;
      border: 1px solid #eaeaea;

      & > .draggable-event > span > :nth-child(2) {
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      }
    }
  }
  .in_progress {
    background: linear-gradient(
      to top right,
      #fff0 calc(50% - 2px),
      rgb(255, 255, 255),
      #fff0 calc(50% + 2px)
    );
  }
  .in_route {
    background: linear-gradient(
      to top right,
      #fff0 calc(50% - 2px),
      rgb(255, 106, 0),
      #fff0 calc(50% + 2px)
    );
  }
  .completed {
    background: linear-gradient(
      to top right,
      #fff0 calc(50% - 2px),
      rgb(0, 155, 10),
      #fff0 calc(50% + 2px)
    );
  }
  .canceled {
    background: linear-gradient(
        to top right,
        #fff0 calc(50% - 2px),
        #ff0000,
        #fff0 calc(50% + 2px)
      ),
      linear-gradient(
        to top left,
        #fff0 calc(50% - 2px),
        #ff0000,
        #fff0 calc(50% + 2px)
      );
  }
  /* .denied_1 {
    width: 100%;
    border-bottom: 2px solid red;
    transform: rotateZ(45deg);
    top: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .denied_2 {
    width: 100%;
    border-bottom: 2px solid red;
    transform: rotateZ(-45deg);
    top: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
  } */
  .matrix-time-line {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: calc(100% - 32px);
    left: 35px;
    transform: translate(-6px, -50%);
    z-index: 2;
    pointer-events: none;

    &-dot {
      width: 12px;
      height: 12px;
      overflow: hidden;
      border-radius: 50%;
      color: $timeLineColor;
      background-color: $timeLineColor;
      pointer-events: initial;
    }

    &-line {
      height: 1px;
      background-color: $timeLineColor;
      flex: 1;
    }
  }

  .weekView {
    // width: 100%;
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    .dayOfWeek {
      text-align: center;
      font-size: 16px;
      width: fit-content;

      &__heading {
        font-weight: bold;
        display: inline-block;
        text-decoration: underline;
        p {
          line-height: 1.33;
        }

        &:hover {
          color: #4285f4;
        }
      }

      & > .body {
        overflow: initial;
        border-radius: 4px;
        border: 2px solid transparent;
        // cursor: pointer;

        .matrix-time-line {
          display: none;
        }

        .time-container {
          margin-top: 35px;
          .time {
            height: 30px;
          }
        }

        .resources {
          .resource-title {
            width: 30px;
            height: 30px;
            margin-bottom: 5px;

            img {
              width: 100%;
            }

            .tech-text {
              display: none;
            }
          }

          .playground {
            .boxes {
              .box {
                height: 30px;
                width: 30px;
              }
            }
          }
        }
      }

      .event.job {
        background-size: 1px 100%, 100% 1px, 1px 100%, 100% 1px;
        background-image: repeating-linear-gradient(
            0deg,
            #fff,
            #fff 5px,
            #000 5px,
            #000 10px
          ),
          repeating-linear-gradient(90deg, #fff, #fff 5px, #000 5px, #000 10px),
          repeating-linear-gradient(180deg, #fff, #fff 5px, #000 5px, #000 10px),
          repeating-linear-gradient(270deg, #fff, #fff 5px, #000 5px, #000 10px);
      }

      .in_progress {
        background: linear-gradient(
          to top right,
          #fff0 calc(50% - 1px),
          rgb(255, 255, 255),
          #fff0 calc(50% + 1px)
        );
      }
      .in_route {
        background: linear-gradient(
          to top right,
          #fff0 calc(50% - 1px),
          rgb(255, 106, 0),
          #fff0 calc(50% + 1px)
        );
      }
      .completed {
        background: linear-gradient(
          to top right,
          #fff0 calc(50% - 1px),
          rgb(0, 155, 10),
          #fff0 calc(50% + 1px)
        );
      }
      .canceled {
        background: linear-gradient(
            to top right,
            #fff0 calc(50% - 1px),
            #ff0000,
            #fff0 calc(50% + 1px)
          ),
          linear-gradient(
            to top left,
            #fff0 calc(50% - 1px),
            #ff0000,
            #fff0 calc(50% + 1px)
          );
      }
    }

    .event {
      width: 30px;

      .draggable-event {
        font-size: 6px;
        cursor: pointer;
        -webkit-user-drag: none;

        .client-name,
        .event-time,
        .event-tech {
          display: none;
        }
      }

      .first,
      .second {
        display: none;
      }
    }
  }

  @media (max-width: 500px) {
    .header {
      margin-bottom: 5px;

      .d-flex-center {
        margin: 5px 0;
      }

      .calendar {
        order: 4;
      }
    }

    .body {
      .body {
        padding-top: 10px;
        .time-container {
          margin-top: 25px;
          .time {
            height: 20px;
            width: 15px;
            font-size: 8px;
          }
        }

        .matrix-time-line {
          left: 20px;

          .matrix-time-line-dot {
            width: 8px;
            height: 8px;
          }
        }

        .resources {
          .resource-title {
            padding: 3px;
            width: 20px;
            height: 20px;

            .ant-badge-status {
              line-height: 15px;
            }

            .ant-badge-dot {
              width: 4px !important;
              min-width: 4px !important;
              height: 4px !important;
            }

            img {
              width: 15px;
            }

            .tech-text {
              // display: none;

              .tech-title {
                font-size: 4px;
              }
              .tech-role {
                font-size: 6px;
              }
            }
          }
          .box {
            width: 20px;
            height: 20px;
          }
          .event {
            width: 20px;
            min-height: 20px;

            .first,
            .second {
              height: 4px;
              left: calc(50% - 8px);

              .circle {
                width: 4px;
                height: 4px;
              }
            }

            .second {
              bottom: 2px;
            }

            .draggable-event {
              font-size: 3px;

              // .event-time,
              .event-tech {
                display: none;
              }
            }
          }

          .column {
            .tech-text {
              display: none;
            }
          }
        }
      }
      & > .queue {
        // margin-left: 10px;

        .queue-header {
          height: 20px;
          width: 20px;
          font-size: 4px;
          padding: 2px;
        }

        .box {
          width: 20px;
          height: 20px;
        }

        .queue-column {
          width: 20px;
          .queue-event {
            width: 20px;
            height: 20px;

            .draggable-event {
              font-size: 3px;
            }
          }
        }
      }
    }

    .timeoff {
      .timeoff-event {
        font-size: 4px;
      }
    }
  }
}

.date-modal {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    p {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
}

.modal-sure-matrix {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    p {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
}

.select-time-modal {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    p {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
}

.activeColumn {
  width: auto;
}
.hidden_column {
  width: 0;
}
.box-wrapper {
  position: relative;
  button {
    height: 10px;
    position: absolute;
    line-height: 8px;
    border: none;
    background: none;
    color: red;
    background-color: red;
    font-size: 20px;
    letter-spacing: 4px;
    justify-content: center;
    z-index: 990;
    left: calc(50% - 10px);
    display: flex;
    .circle-point {
      height: 8px;
      width: 8px;
      background-color: red;
      opacity: 0;
      z-index: 400;
      border-radius: 90px;
    }
  }
}

.event__popover {
  min-width: 350px;

  & .ant-popover-inner {
    border-radius: 10px;
  }
  .ant-popover-content {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 10px;
  }
}
@keyframes color {
  0% {
    background: #4b94d8;
  }
  20% {
    background: #4b94d8;
  }
  40% {
    background: #ed4747;
  }
  60% {
    // background: #ed4747;
    background: #4b94d8;
  }
  80% {
    background: #4b94d8;
  }
  100% {
    background: #4b94d8;
  }
}

.event-animation {
  animation: color 3s infinite linear;
  border: 2px dashed black;
}

.popover-children__wrapper {
  /*  position: relative;
  display: flex;
  height: calc(100% - 2px);
  padding: 0 2px;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  // z-index: 99999;
  // z-index: 999;

  //
  // position: absolute;
  // top: 0;
  // width: 100%;
  // height: 100%;
}

// .box-overlay-wrapper {
//   z-index: 100;
//   &:hover {
//     z-index: -1;
//   }
// }
