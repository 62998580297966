$svgColor: #4285f4;
$shadowColor: #4c53ef7a;
.shadow {
  path {
    fill: $svgColor;
  }
  filter: drop-shadow(2px 2px 2px $shadowColor);
  @media (max-width: 768px) {
    filter: drop-shadow(2px 2px 2px $shadowColor);
  }
}
