@import '../../../../../Layouts/AuthLayout/layoutConstants';
.formClientWrapper {
  // @extend %settings;
  min-height: 300px;
  .client-phones,
  .client-emails {
    .MuiTextField-root {
      margin-bottom: 0;
    }
  }
  .client-tags-wrapper {
    // margin: 0 0 25px;
    width: 100%;
    max-width: 475px;
    // margin-bottom: 20px;

    @media (max-width: 499px) {
      margin-bottom: 0;
    }
  }
  // display: flex;
  background-color: #fff;
  // flex-wrap: wrap;
}

.formClientWrapper-color {
  margin-top: 0;
  // padding: 0 5px 0;
  .add-or-update-client-form {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
}

.clientNotesTagsWrapper {
  & .form-group-notes {
    padding-top: 10px;
  }
}
.notesTagsWrapper {
  width: 100%;

  @media (max-width: 499px) {
    padding: 0 5px;
  }
}
.form-group-notes {
  // padding-top: 20px;
  max-width: 475px;
  margin-bottom: 20px;
}
.formGroupStyle {
  max-width: 475px;

  .form-control {
    height: 100%;
  }
}
.clientWrapperMain {
  padding-left: 5px;
  min-width: 635px !important;
  @media screen and (min-width: 900px) {
    display: flex;
    gap: 10px;
  }
  @media screen and (max-width: 900px) {
    display: block;
    gap: 10px;
  }
}
.add-or-update-client-form-appointment {
  display: block;
  .add-or-update-client-form {
    padding: 20px;
    .clientWrapperMain {
      padding-left: 5px;
      min-width: 635px !important;

      .buttonCloseMargin {
        margin-right: 10px;
      }
      .formWrapper {
        width: 100%;
      }
    }
  }
}

.add-or-update-client-form {
  flex: 1;
  background-color: white;
  margin: 10px;
  margin-top: 0;
  border-radius: 4px;

  &.new {
    .tabss-list {
      display: none;
    }
  }

  .fa-plus-circle {
    color: #2c2b3c;
    margin-left: 5px;
    cursor: pointer;
  }
  .stars-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    .starsWrapper {
      padding-right: 20px;
    }
    .ant-rate {
      font-size: 20px;
    }
    .ant-rate-star {
      margin-right: 5px;
    }
    .view-btn {
      color: #3b86ff;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .formWrapper {
    width: 100%;
    margin-bottom: 10px;

    h2 {
      margin-bottom: 20px;
    }
    .nameWrapper {
      margin-bottom: 10px;
    }
    .nameWrapper,
    .orgWrapper {
      display: flex;
      justify-content: flex-start;

      // margin: 15px 0 5px;

      & > div {
        flex: 0 1 231px;

        &.rowClientName.rowClientName-first {
          padding-right: 25px;
        }
      }

      .MuiTextField-root {
        margin-bottom: 0;
      }
    }
    .relationWrapper {
      margin: 10px 0 0;
      & > .MuiTextField-root {
        margin-bottom: calc(#{1.5rem} - #{10px});
      }
    }

    .client-form__rating-container {
      position: relative;

      .client-form__rating {
        display: flex;
        cursor: pointer;
        max-width: 120px;

        .rating-count {
          margin-left: 5px;
          font-weight: 500;
          font-size: 14px;
          line-height: 1.4;
        }
      }
    }

    .industryInput,
    .websiteInput {
      flex: 1 0 231px;
    }

    .client-phones {
      // margin-bottom: 20px;

      .btn.add {
        padding: 0;
      }

      .phone-actions {
        button {
          padding: 0 5px;
          &:disabled {
            cursor: initial;
            border: none;
          }
          &.add {
            // padding: 0 7px;
            // height: 40px;
            margin-top: -3px;
            /* svg.shadow {
              path {
                fill: #4285f4;
              }
              filter: drop-shadow(0 7px 2px #4c53ef7a);
            } */
          }
        }
      }

      .phoneWrapper {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          // margin-bottom: 10px;
        }
        white-space: normal;
        margin-bottom: 5px;

        .phone__main-group {
          display: flex;
          flex: 0 1 330px;
          margin-right: 20px;

          .phoneInput {
            position: relative;
            flex: 0 1 170px;
            margin-right: 25px;

            .MuiFormHelperText-root {
              position: absolute;
              bottom: -15px;
              left: 0;
              margin: 0;
              width: 300%;
            }
          }

          .phoneSelect {
            flex: 0 1 170px;

            .MuiInputBase-formControl {
              max-height: 40px;
            }
          }
        }
        .radio-group {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          flex: 0 0 115px;
          justify-content: space-between;

          & > label {
            position: relative;
            span.MuiFormControlLabel-label {
              position: absolute;
              width: 60px;
              top: -20px;
              left: 10px;
              font-size: 10px;
              white-space: initial;
            }
          }
        }

        span {
          margin-top: 0;
        }
        // span {
        //   margin-top: 6px;
        // }
      }
    }

    .client-emails {
      .email-actions {
        // margin-top: 7px;
        margin-bottom: 10px;

        button {
          padding: 0 5px;

          &:disabled {
            cursor: initial;
            border: none;
          }
          &.add {
            // padding: 0 7px;
            // height: 40px;
            margin-top: -3px;
            /* svg.shadow {
              path {
                fill: #4285f4;
              }
              filter: drop-shadow(0 7px 2px #4c53ef7a);
            } */
          }
        }
      }
      & > .email-actions {
        margin-top: 0;
      }
      .emailWrapper {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5px;

        .emailInput {
          flex: 0 1 330px;
          margin-right: 20px;
          position: relative;

          .MuiFormHelperText-root {
            position: absolute;
            bottom: 5px;
            left: 0;
            margin: 0;
            width: 300%;
          }
        }
        .email-preference {
          position: relative;
          span.MuiFormControlLabel-label {
            position: absolute;
            width: 60px;
            top: -25px;
            left: 10px;
            font-size: 10px;
            white-space: normal;
          }
        }

        /* .formForEmailControls {
        } */
      }

      /*  @media (max-width: 499px) {
        .emailWrapper {
          .emailInput {
            flex: 0 1 70%;
            margin-bottom: 10px;
            margin-right: 0;

            .MuiFormHelperText-root {
              bottom: -20px;
            }
          }
          .email-preference {
            order: 3;
            flex-basis: 100%;

            span.MuiFormControlLabel-label {
              width: 200px;
              top: 14px;
              left: 40px;
            }
          }
          .formForEmailControls {
            order: 4;
          }

          // .email-actions {
          //   margin-left: initial;
          // }
        }
      } */
    }
  }
  .tags {
    margin: 20px 0 25px;
    display: flex;
    flex-wrap: wrap;
    max-height: 100px;
    overflow-y: auto;
    .tag {
      padding: 10px;
      height: 35px;
      position: relative;
      background: rgb(56, 164, 248);
      margin-bottom: 10px;
      font-size: 12px;
      align-self: center;
      text-align: center;
      vertical-align: middle;
      border-radius: 5px;
      font-size: 14px;
      line-height: 16px;
      color: white;
      button {
        position: absolute;
        padding: 0;
        background: none;
        border: none;
        top: 0px;
        right: 0px;
        color: white;
        width: 16px;
        height: 16px;
        line-height: 16px;
        border-radius: 20px;
        margin-left: 5px;
      }
      .tagClose {
        margin-left: 3px;
        margin-bottom: 3px;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .client-properties {
    .property-form {
      & > .ant-row {
        .ant-col-xl-12 {
          width: 100%;
        }
      }
      .edit-btn {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 499px) {
  .formClientWrapper {
    background-color: #fff;

    .add-or-update-client-form {
      // padding: 10px;
      .formWrapper {
        margin-bottom: 0px;
        width: 100%;
      }
      .MuiTextField-root {
        .MuiOutlinedInput-root {
          font-size: 8px;
          line-height: 12px;
        }
        .MuiInputLabel-outlined.MuiInputLabel-shrink {
          font-size: 8px;
          top: 4px;
        }
        .MuiFormLabel-root {
          font-size: 8px;
          top: -3px;
        }
      }

      .clientWrapperMain {
        .MuiTextField-root {
          max-width: 140px;
          margin-bottom: 0;

          /*  .MuiOutlinedInput-root {
            height: 20px;
          } */
        }
      }

      .tags-wrapper {
        margin-top: -5px;

        .tags-wrapper-list-item {
          padding: 5px;
          height: 20px;
          font-size: 8px;

          span {
            margin-right: 5px;
          }

          i {
            font-size: 12px;
          }
        }

        .MuiOutlinedInput-root {
          height: 20px;
        }
      }

      .nameWrapper {
        margin: 10px 0 0;
      }

      .client-phones {
        & > div {
          & > span {
            font-size: 10px !important;
          }
        }
        .phoneWrapper {
          margin-top: -10px;
          margin-bottom: 0;

          .phone__main-group {
            // display: block;

            .phoneInput {
              margin-right: 10px;
              // width: 100%;
            }

            .phoneSelect {
              flex: 0 1 130px;
            }
          }
          .radio-group {
            flex: 0 0 60px;
            .MuiSvgIcon-root {
              width: 14px;
              height: 14px;
            }
            label {
              margin-right: 0;

              span.MuiFormControlLabel-label {
                font-size: 6px;
                top: -5px;
                width: 40px;
              }
            }
          }
          .phone-actions {
            // flex: 0 0 64px;
          }
        }
      }

      .client-emails {
        .email-actions {
          & > span {
            font-size: 10px !important;
          }
        }
        .emailWrapper {
          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .email-preference-wrapper {
            flex: 0 0 60px;
            margin-left: 7px;

            .email-preference {
              .MuiSvgIcon-root {
                width: 14px;
                height: 14px;
              }
              span.MuiFormControlLabel-label {
                font-size: 6px;
                top: -10px;
                width: 40px;
              }
            }
          }

          .email-actions {
            margin-top: 0;
          }

          .formForEmailControls {
            margin: 0 0 -10px 0;
            padding-left: 23px;

            span {
              font-size: 8px;
            }

            .MuiSvgIcon-root {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }

    .formClientWrapper-color {
      margin-top: 0;
      padding: 0 5px 0;
      .add-or-update-client-form {
        margin: 0;
        padding: 0;
      }
    }

    .controlButtons {
      margin: 5px 0;
    }

    button {
      svg {
        width: 10px;
      }
    }
  }
}

.suggestionWrapper {
  flex: 1 0 500px;
  // margin-bottom: 50px;
  .clients-suggestions-wrapper {
    padding: 0;
  }
}

.controlButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 40px;
  .deleteBtn {
    position: absolute;
    left: 0;
  }
}
.suggestion {
  height: fit-content;
  border-radius: 5px;
  width: 100%;
}
.redmine {
  min-width: 150px;
}
.formControl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.baseFormControl {
  small {
    color: red;
    margin-top: 5px;
  }
}
.labelFormControl {
  margin-top: 10px;
  margin-bottom: 15px;
}
.promo {
  min-width: 90px;
  max-height: 88px;
}
.phoneInput {
  min-width: 230px;
}
.formForEmailControls {
  display: flex;
  width: 100%;
  padding-left: 30px;
  margin-top: -15px;
  span {
    font-size: 10px;
  }
}
.buttonCloseMargin-2 {
  display: none;
}
.formControlWrapper-1 {
  display: flex;
  justify-content: center;
}
.formControlWrapper-2 {
  max-width: 300px;
  display: flex;
  justify-content: center;
}
.MuiMenuItem-root {
  // font-family: 'Poppins', sans-serif !important;
}
.iconSpan {
  margin-left: 10px;
}
.iconSpan-first {
  margin-left: 15px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: #4285f4;
  }
}
.phoneSelect {
  display: flex;
  width: 145px;
  .MuiFormControl-root {
    width: 100%;
    label {
      font-size: 15px;
    }
  }
  .MuiOutlinedInput-input {
    display: flex;
    align-items: center;
  }
}

.email-preference-wrapper {
  flex: 0 0 68px;
}

.phoneRadioWrapper {
  label {
    margin: 0;
    text-align: start;
    align-items: start;
  }
  span {
    text-align: start;
    font-size: 12px;
    /*  input {
    } */
  }
}

.rowAppointmentSuggestions {
  height: 100% !important;
  max-height: 100% !important;
  .col {
    height: 100% !important;
    max-height: 100% !important;
    #properties-suggestions {
      height: 100% !important;
      max-height: 100% !important;
    }
  }
}

.confirm-client-delete-modal {
  text-align: center;

  & > .modal-content > .modal-body {
    .w-100 > span {
      margin-bottom: 0 !important;
      font-size: 1rem !important;
    }

    .buttons {
      margin: 20px 0;
    }
  }
}
