.content-page {
  margin-left: 80px;
  // overflow: hidden;
  transition: 0.5s;
  min-height: 100vh;
  background: #fff;
}

.matrix__header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
  background-color: white;
  max-height: 75px;
  transition: 0.2s linear;
  transform: translateY(0);
}

.hidden-header {
  // max-height: 0;
  // transition: max-height 0.15s linear;
  // transform: translatY(-200%);
}
.hide-header {
  transform: translateY(-300%);
  transition: all 0.2s linear;
}
.show-header {
  transform: translateY(0);
  transition: all 0.2s linear;
}

.footer {
  //margin-left: 80px;
  overflow: hidden;
  transition: 0.5s;
  background: #ffffff;
  border-top: 1px solid #d6d6d6;
}

.app-fail_subscription_message {
  z-index: 1000;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 870px;
  width: 100%;
  background: rgba(255, 2, 2, 0.8);
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  color: #ffffff;
  padding: 10px 30px;
  min-height: 55px;
  transition: 0.5s;

  &--hide {
    opacity: 0;
    z-index: -1;
  }

  & > div:first-child {
    align-self: center;
    margin-right: 70px;
  }

  & > div:last-of-type {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex: 0 0 100px;
    margin-left: auto;

    & > span:first-child {
      font-size: 34px;
      line-height: 30px;
    }

    & > span:last-child {
      white-space: nowrap;
    }
  }
}

.app-fail_subscription_close_btn {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  background: transparent;
  padding: 0;
}

.app-fail_subscription_button {
  border: none;
  position: fixed;
  width: 40px;
  height: 40px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: rgba(255, 2, 2, 0.58);
  right: 0;
  top: 90px;
  z-index: 1000;
  color: #fff;
  transition: all 1s ease;

  &--hide {
    right: -100%;
  }
}

@media (max-width: 1200px) {
  .app-fail_subscription_message {
    opacity: 0;

    &--show {
      opacity: 1;
    }
  }
}

@media (max-width: 960px) {
  .app-fail_subscription_message {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > div:first-child {
      align-self: center;
      margin-right: 0;
      text-align: center;
    }

    & > div:last-child {
      margin-top: 30px;
      margin-left: 0;
    }
  }
}

.content-page .content {
  //padding: 0 15px 10px 15px;
  margin-top: 80px;
  // margin-bottom: 60px;
  background-repeat: repeat;
  background: #fff;
  // background: #ffffff;
  transition: margin-left 0.5s;

  @media (max-width: 499px) {
    margin-top: 58px;
  }
}

.content-page {
  .sidebar-closed {
    /* padding: 0 15px 10px 15px;
    margin-left: 15px; */

    @media (max-width: 499px) {
      padding: 0;
      margin-left: 0;
    }
  }
}

.enlarged .content-page,
.enlarged .footer {
  margin-left: 0 !important;
}

.menu-title {
  padding: 12px 20px !important;
  letter-spacing: 1px;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #b4bfcc;
}

@media (max-width: 499px) {
  .enlarged .button-menu-mobile {
    border: none;
    display: inline-block;
    /* height: 70px;
    width: 80px;
    background: none;
    color: black; */
    font-size: 24px;
    transition: 0.4s;
    background: rgba(247, 247, 247, 0.15);
    /* opacity: 0.15; */
    border-radius: 4px;
    width: 44px;
    height: 44px;
    color: white;
  }
  .button-menu-mobile {
    border: none;
    //color: #8497ab;
    display: inline-block;
    //height: 70px;
    //width: 80px;
    //width: 80vw;
    //max-width: 280px;
    //transition: 0.5s;
    //background-color: #333547;
    font-size: 24px;
    background: rgba(247, 247, 247, 0.15);
    color: white;
    //opacity: 0.15;
    border-radius: 4px;
    width: 44px;
    height: 44px;
  }
  .button-menu-mobile:hover {
    background: rgba(247, 247, 247, 0.3);
  }
  /* .topbar-left{
    background: none;
    background-color: inherit !important;
    position: absolute;
    top:0;
  } */
}

@media (min-width: 769px) {
  .enlarged .button-menu-mobile {
    border: none;
    display: inline-block;
    //height: 80px;
    //width: 100px;
    //background-color: #4285f4;
    //color: #8497ab;
    font-size: 24px;
    transition: 0.4s;
    background: rgba(247, 247, 247, 0.15);
    color: white;
    //opacity: 0.15;
    border-radius: 4px;
    width: 44px;
    height: 44px;
  }
  .button-menu-mobile {
    border: none;
    //color: #8497ab;
    display: inline-block;
    //height: 80px;
    //width: 100px;
    transition: 0.5s;
    //background-color: #4285f4;
    font-size: 24px;
    background: rgba(247, 247, 247, 0.15);
    color: white;
    //opacity: 0.15;
    border-radius: 4px;
    width: 44px;
    height: 44px;
  }
  .button-menu-mobile:hover {
    background: rgba(247, 247, 247, 0.3);
  }
}

.before-charge-card-modal {
  & .step-1 {
    .text-title,
    .text-main,
    .text-footer {
      color: red;
      text-align: center;
      font-size: 16px;
    }
  }
}

.topbar-left {
  background-color: #ffffff;
  border-right: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
  button {
    //color: black !important;
    background: #6e6e76 !important;
  }
}

@mixin hoverColor {
  color: #4285f4;
  svg {
    fill: #4285f4;
    path {
      fill: #4285f4;
    }
  }
}

.sidebar {
  background: #ffffff;
  border-right: 1px solid #d6d6d6;
  &
    > div
    > .simplebar-wrapper
    > .simplebar-mask
    > .simplebar-offset
    > .simplebar-content-wrapper
    > .simplebar-content {
    color: black;
    & a {
      .item {
        color: black;
        &:hover {
          @include hoverColor;
        }
        &.active {
          background: #ffffff !important;
          color: black;
          @include hoverColor;
        }
      }
    }
  }
}
