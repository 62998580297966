.leaflet-popup-content {
  width: 100%;
  min-width: 150px;
}
.marker-popap {
  padding-top: 10px;
  & > div {
    margin-bottom: 10px;

    span {
      &:first-child {
        margin-right: 5px;
      }
    }
  }
  button {
    font-size: 0.6rem;
    margin-bottom: 5px;
  }
}

.popaps-with-content {
  margin-bottom: 60px !important;
  margin-left: 2px !important;
}

.popaps-without-content {
  margin-bottom: 40px !important;
  margin-left: 2px !important;
}

.leaflet-popup.w-180 {
  .leaflet-popup-content {
    width: 180px;
  }
}
