%commonStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  color: #191919;
  box-sizing: border-box;
  transition: all 0.3s;
  border-radius: 50%;
  font-size: 18px;
}

%toggleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  transition: all 0.3s;
  border-radius: 50%;
  font-size: 18px;
  background-color: #4285f4;
  border: 1px solid #4285f4;
  color: #ffffff;
}
.custom-toggle-btn {
  @extend %toggleBtn;
  &.active,
  &:hover {
    background-color: #ffffff;
    border: 1px solid #d6d6d6;
    color: #191919;
  }

  @media (max-width: 500px) {
    width: 16px;
    height: 16px;
    font-size: 14px;
  }
}

.custom-delete-btn {
  @extend %commonStyles;
  border: 1px solid #cd3535;
  color: #cd3535;
  svg {
    transform: scale(0.9);
  }
  path {
    fill: #cd3535;
  }
  &.active,
  &:hover {
    color: #ffffff;
    background-color: #cd3535;
    border: 1px solid #cd3535;
    path {
      fill: #ffffff;
    }
  }
}
