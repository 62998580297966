.appointment-section {
  .parent-appointment {
    // margin: -5px 0 15px 15px;
    color: #b9b9b9;

    .recurring-id {
      color: initial;
      span {
        font-weight: 600;
      }
    }

    .link {
      color: #4285f4;
      text-decoration: underline;
      font-weight: 600;
      cursor: pointer;
    }
  }

  .reccruing-switcher {
    display: flex;
    margin-bottom: 20px;

    span {
      cursor: pointer;
      margin-left: 10px;
      align-items: center;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-wrap: wrap;
      order: 2;
    }

    .appointment-section__main-info {
      flex: 1 0 70%;
      max-width: 1200px;

      @media (max-width: 767px) {
        order: 2;
      }

      & > .appointment > .notes-wrapper {
        // margin-bottom: 25px;
      }
    }

    .appointment-section__main-status {
      position: relative;
      flex: 0 0 30%;
      margin-left: 15px;

      & > *:not(:last-child) {
        margin-bottom: 1rem;
      }
      @media (max-width: 767px) {
        order: 1;
        flex: 0 0 100%;
        margin-left: 0;
      }
      .canceled-info {
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 1rem;
        letter-spacing: 0.01em;
        color: #a6a6a6;
        & > span {
          color: #191919;
        }
      }
    }
  }

  @media (max-width: 500px) {
    &__body {
      flex-wrap: wrap;

      .appointment-section__main-info {
        flex: 0 0 100%;
        margin-bottom: 20px;
      }

      .appointment-section__main-status {
        flex: 0 0 100%;
      }
    }
  }
}
