@import '../../../../../Layouts/AuthLayout/layoutConstants';
.sure-modal {
  &-title {
    width: 90%;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    color: #191919;
    margin-bottom: 1rem;
  }
  &-tags {
    width: 100%;
    margin: -5px;
    display: flex;
    flex-wrap: wrap;
    &-tag {
      background-color: rgb(242, 243, 254);
      color: rgb(76, 83, 239);
      padding: 5px;
      margin: 5px;
      border-radius: 4px;
      white-space: nowrap;
    }
  }

  .modal-content {
    .modal-header {
      border-bottom: unset;
    }
    .modal-body {
      @extend %settings;
      color: #191919;
      padding-top: 0;
      table {
        max-width: 100%;
        margin-bottom: 1rem;
        tbody {
          tr > td {
            padding: 5px 0;
          }
          tr > td:first-child {
            vertical-align: middle;
            width: 120px;
            white-space: nowrap;
          }
          tr > td:last-child {
            vertical-align: middle;
            width: auto;
          }
        }
      }
      .buttons {
        height: 40px;
        margin-bottom: 1rem;
      }
    }
  }
}
