.main-info {
  display: flex;
  justify-content: space-between;

  & > * {
    flex: 0 0 49%;

    &.general-info {
      & > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  .map {
    display: flex;
    flex-direction: column;

    span {
      color: #ff0000;
      margin-bottom: 5px;
    }

    .leaflet-container {
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;

    & > * {
      flex: 0 0 100%;

      &.map {
        height: 218px;
        margin-top: 20px;
      }
    }
  }
}
