.properties-layout {
  .properties-table {
    table {
      min-width: 700px;
    }
  }
  .card-body {
    padding-top: 0;
  }

  .custom-page-header {
    // .page-actions {
    //   display: flex;
    //   justify-content: flex-end;

    //   & > * {
    //     &:nth-child(1) {
    //       flex: 0 1 160px;
    //       padding: 0 10px;
    //     }
    //     &:nth-child(2) {
    //       flex: 0 1 120px;
    //     }
    //     &:nth-child(3) {
    //       flex: 0 0 150px;
    //     }
    //     &:nth-child(4) {
    //       flex: 0 1 300px;
    //     }
    //   }
    //   .btn-custom-secondary {
    //     background-color: #edeffe;
    //     color: #4285f4;
    //     font-weight: 500;
    //   }
    // }
  }
  .custom-table-layout {
    .custom-table {
      scrollbar-width: none;
      table {
        margin-top: 0;
      }
    }
  }
}

.opened-row {
  td {
    background-color: rgb(241, 241, 241);
    vertical-align: top !important;
  }
  td:nth-child(2) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-overflow: initial !important;
    overflow: auto !important;
    white-space: normal !important;
  }
  td:nth-child(5) {
    text-overflow: initial !important;
    overflow: auto !important;
    white-space: normal !important;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

@media (max-width: 499px) {
  .properties-layout {
    .custom-page-header {
      h4 {
        font-size: 18px;
      }

      .page-actions {
        & > * {
          &:nth-child(1) {
            margin-right: 5px !important;
            // padding: 5px;
          }
          &:nth-child(2) {
            flex: 0 1 120px;

            & > label {
              font-size: 8px;

              &.MuiInputLabel-outlined.MuiInputLabel-marginDense {
                transform: translate(14px, 9px) scale(1);
              }
              &.MuiInputLabel-outlined.MuiInputLabel-shrink {
                transform: translate(14px, -3px) scale(0.75);
              }
            }

            input {
              padding: 7.75px 10px;
              font-size: 8px;
            }
          }
          &:nth-child(3) {
            margin-left: 5px !important;
            flex: 0 1 120px;
            // padding: 5px;
          }
        }
        button {
          font-size: 8px;
          padding: 5px;
          margin-bottom: 0 !important;
        }
      }
    }

    .card-body {
      padding: 5px;
    }
  }
}
