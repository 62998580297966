.custom-date-picker {
  width: 240px;
  height: 30px;
  box-sizing: border-box;
  position: relative;
  // border: 1px solid #dadada;
  border-radius: 5px;
  text-align: center;

  &--custom-date {
    min-width: 220px;
  }

  .ant-calendar-picker {
    max-width: 200px;
    opacity: 0;
    .ant-calendar-range-picker-input,
    .ant-calendar-picker-input {
      cursor: pointer;
    }
  }
  .ant-calendar-picker-icon,
  .ant-calendar-picker-clear {
    display: none;
  }
}

.ant-calendar .ant-tag {
  cursor: pointer;
}

.view-main-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
}

.view-main-title > span:first-child {
  white-space: nowrap;
}

@media screen and (max-width: 499px) {
  .custom-date-picker {
    height: 20px;
    &--custom-date {
      min-width: auto;
      .view-main-title {
        padding-left: 5px;
        span {
          font-size: 11px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .custom-control-bar {
    padding: 0 !important;
  }
  .ant-calendar-range {
    width: 100%;
  }
}
