.collapse-tabs {
  margin-top: 20px;
  position: relative;
  .tab {
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .tab-title {
    //width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .items-quantity {
      display: block;
      padding: 3px;
      background-color: white;
      height: 20px;
      line-height: 15px;
      border-radius: 5px;
      color: black;
      margin-left: 10px;
    }
    .add-button {
      margin-left: 10px;

      /* background-color: transparent;
        border: none;
        padding: 0; */
    }
  }
}
