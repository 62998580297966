.ticket__preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8888;
}

.ticket__header {
  display: flex;
  margin-top: 10px;

  & > .ticket-header__col {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > .ticket-header-col__cell {
      height: 40px;
      margin-bottom: 10px;
      margin-right: 10px;
      display: flex;

      & > .ticket-header-cell__key {
        width: 75px;
        margin-right: 5px;
        font-weight: 600;
        color: #000;
      }
    }
  }
}

.ticket__message-bag {
  display: flex;
  flex-direction: column;

  & > .ticket__message {
    display: flex;
    flex-direction: row;
    margin: 15px 0;
    padding: 15px 15px 20px;
    border-radius: 4px;

    &.ticket-message__self {
      background-color: #f9f9f9;
    }

    &.ticket-message__sa {
      background-color: #fff;
      border: 2px solid #5b61f0;
    }

    .ticket-message__primary-col {
      flex: 1;

      .ticket-message__title {
        font-weight: 600;
        color: #000;
        margin-bottom: 20px;
      }

      .ticket-message__body {
        color: #000;
      }

      .ticket-message__attachments {
        margin-top: 10px;
        display: flex;

        .ticket-message-attachments__title {
          margin-right: 30px;
        }

        .ticket-message-attachments__files {
          flex: 1;

          .ticket-message-attachment__file {
            font-weight: 600;
            color: #5b61f0;
            float: left;
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-right: 20px;
            margin-bottom: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .ticket-message__secondary-col {
      margin-left: 15px;
    }
  }
}

.ticket__reply {
  margin-top: 15px;
  position: relative;

  .ticket-reply__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 7777;
  }

  .ticket-reply__title {
    margin-bottom: 10px;
  }

  .ticket-reply__footer {
    display: flex;
    justify-content: space-between;

    .ticket-reply-submit__wrapper {
      margin-top: 20px;
      flex-shrink: 0;
    }
  }
}

.two-rows-date__date {
  display: block;
}
.two-rows-date__time {
  font-size: 10px;
  display: block;
}

.ticket-conf__backdrop {
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;

  .ticket-conf__preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9002;
  }

  .ticket-conf__modal {
    position: absolute;
    border-radius: 4px;
    max-width: 600px;
    padding: 16px 16px 36px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 9001;
    text-align: center;

    .ticket-conf-modal__title {
      font-size: 22px;
      margin-bottom: 40px;
    }
  }
}
