%customTableConfig {
  border-spacing: unset !important;
  tr > th,
  tr:not(:last-child) > td {
    border-bottom: 1px solid #d6d6d6;
  }
  tr > th,
  tr > td:not(:first-child) {
    background-color: transparent;
    padding-left: 5px;
    padding-right: 5px;
  }
  tr > th {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #191919;
    opacity: 0.5;
    cursor: pointer;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 20px;
      i {
        font-size: 20px !important;
        //font-weight: 200;
      }
    }
  }
  tr > td {
    background-color: transparent;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.01em;
    padding: 11.1px 5px;
    color: #191919;
    font-size: 13px;
  }

  tr > td:last-child {
    font-size: 16px;
    & > i:hover,
    & > div > i:hover {
      background-color: rgb(241, 241, 241);
      border-radius: 3px;
      transition: background-color 0.7s, color 0.2s;
      cursor: pointer;
    }
    & > i:not(.mdi-delete):hover,
    & > div > i:not(.mdi-delete):hover {
      color: #626ed4;
    }
    & > i.mdi-delete:hover,
    & > div > i.mdi-delete:hover {
      color: #f5222d;
    }
  }
}
%customSelectOutlined {
  background: #ffffff;
  height: 40px;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 4px;
  transition: border 0.3s, border-width 0, 3s;
  &:hover {
    border: 1px solid #626ed4;
  }
  & > div {
    height: 100%;
  }
  .MuiInputBase-input {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    padding-left: 6px;
    color: #000000;
  }
  .MuiSelect-select:focus {
    background-color: unset;
  }
}
