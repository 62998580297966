$errorColor: #f5222d;
$cancelButtonColor: #1890ff;

.register-page {
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: $errorColor;
    padding-right: 12px;
    background-image: none;
  }

  .error-message {
    color: $errorColor;
    font-size: 0.875rem;
  }

  .custom-register-form {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] {
      padding: 0px 20px 0px 6px;
    }
    .other-select {
      display: flex;
      flex: none;
      align-items: center;
      width: 100%;
      overflow: hidden;
      button {
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: fit-content;
        i {
          font-size: 24px;
          align-self: center;
        }
      }
    }
    /* .fields {
      width: 100%;
      display: flex;
      overflow-x: hidden;
    } */
    /* .form-group {
      flex: none;
      width: 100%;
      select {
        width: 100%;
      }
    } */
    .MuiAutocomplete-endAdornment {
      display: none;
    }
    .industry {
      width: 100%;
      .select {
        width: 100%;
        transition: 0.4s;
      }
      .other {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        transition: 0.4s;
        .btn {
          height: 40px;
          font-size: 1.5rem;
          padding: 0 5px;
        }
      }
      .select {
        animation: slide-right 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      }
      .other {
        animation: slide-left 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
    /* .fields.select {
      .other {
        width: 100%;
        transform: translateX(100%);
        transition: 0.4s;
        //overflow-x: hidden;      
      }
      .industry-select{
        transform: translateX(0);
        transition: 0.4s;
      }
    }
    .fields.other {
      .other-select {
        animation: slide-left 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      }

      .select {
        animation: slide-right 0.4s cubic-bezier(0.19, 1, 0.22, 1);
      }
    } */
    .main-button {
      margin-top: 1rem;
    }
    .buttons-block {
      display: flex;
      width: 100%;
      margin: 1rem 0;
      padding: 1rem 1.5rem 0 1.5rem;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      button {
        width: 45%;
      }
    }
  }
  .email-text {
    font-size: 17px;
    margin: 0 -15px;
    text-align: center;
    div {
      text-decoration-line: underline;
      color: #4285f4;
    }
  }
  @keyframes slide-right {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes slide-left {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}

/* @media (max-width: 480px){
.register-page{
  max-width: 100%;
  .left {

    width: 100%;
    position: relative;
  }
}
} */
.MuiAutocomplete-listbox {
  font-size: 12px;
}

.industry-field > div {
  padding: 5px 10px !important;
}
