.table-style {
  min-width: 100%;
  //background-color: #f2f3fe;

  @media (max-width: 499px) {
    min-width: 100%;
    background-color: transparent;
  }
}

.appointment-table {
  .add-appointment-button {
    margin: 0 auto 10px;
  }
  tr {
    th:nth-child(1),
    td:nth-child(1) {
      width: 50px;
      max-width: 50px;
      min-width: 50px;
    }
  }
}
