.map-label {
  .map-label-content.map-label-content-home {
    border: unset;
    background-color: #ffffff00;
    font-size: 32px;
    color: #aeabfa;

    i {
      top: -10px;
      left: 0;
      position: absolute;
    }
  }
}
