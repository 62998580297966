.confirm-redirect-modal {
  &-title {
    width: 90%;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 134.5%;
    color: #191919;
    margin-bottom: 1rem;
  }
  .modal-content {
    .modal-header {
      border-bottom: unset;
    }
    .modal-body {
      padding-top: 0;
      .buttons {
        height: 40px;
        margin-bottom: 1rem;

        .btn-cancel {
          margin-right: 10px;
        }
      }
    }
  }
}
