.proposal-form-tabs_content-payment_description {
  @media (max-width: 499px) {
    padding: 0 5px;
  }
  &-title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > button {
      margin-left: 25px;
    }
    & > span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.01em;
      color: #191919;
    }
  }
}

.payment-balances {
  &.radio {
    padding-left: 50px;
  }

  table {
    width: 100%;
    table-layout: auto;
    thead {
      // border-bottom: 1px solid #ebeff2;

      /* tr > th:not(:last-child) {
        padding-right: 15px;
      }
      tr:not(:last-child) > th,
      tr:not(:last-child) > td {
        padding-bottom: 15px;
      } */
      tr > th {
        // font-style: normal;
        // font-weight: bold;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #000000;
        opacity: 0.5;
        white-space: nowrap;
        padding: 10px;
      }
    }
    tbody {
      tr {
        background-color: #c4c4c480;

        &:not(:last-child) {
          border-bottom: 10px solid #fbfdff;
        }

        &.remaining-balance {
          background-color: transparent;
          td {
            // padding-top: 20px;
          }
        }
        & > td {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 19px;
          letter-spacing: 0.01em;
          color: #707683;
          padding: 10px;
          position: relative;

          .payment-radio {
            position: absolute;
            left: -30px;
          }
        }
      }
    }
    @media (max-width: 499px) {
      // min-width: initial;

      tr {
        display: flex;
        padding: 3px 0;

        th,
        td {
          &:nth-child(1) {
            flex: 1 0 88px;
          }
          &:nth-child(2) {
            flex: 1 0 50px;
          }
          &:nth-child(3) {
            flex: 1 0 62px;
          }
          &:nth-child(4) {
            flex: 1 0 43px;
          }
        }
      }

      thead {
        tr {
          th {
            font-size: 8px;

            &:not(:last-child) {
              padding: 0;
            }
          }
        }
      }
      tbody {
        tr {
          &.remaining-balance {
            td {
              padding-top: 10px;
            }
          }

          td {
            font-size: 8px;
            line-height: 8px;
            padding: 0;
          }
        }
      }
    }
  }
}
