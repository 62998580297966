.modal-proposal-payment {
  h4 {
    margin-bottom: 25px;
    line-height: 1;
  }

  .total-to-pay {
    font-size: 22px;
    letter-spacing: 0.01em;
    margin-bottom: 30px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .green {
    color: green;
  }

  .textcenterbadge {
    background: none;
    color: grey;
  }

  .modal-payment-table {
    white-space: nowrap;
    // overflow: hidden;
    //max-height: 450px;
    margin: 0 -8px 20px;
    padding-left: 20px;

    .thead {
      font-size: 12px;
      color: #000;
      opacity: 0.5;

      .modal-payment-table__row {
        margin-bottom: 0;
      }
    }

    .rowWrapper {
      border: 1px solid #d7d7d7;
      background-color: #fff;
      border-radius: 4px;
      padding: 10px 5px;
      position: relative;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &.filled {
        background-color: #e2e2e266;
        border-radius: 4px;
      }

      .payment-balance {
        display: flex;
        gap: 10px;

        &__content {
          flex-basis: calc(100% - 30px);

          .content-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 150px;
            gap: 10px;
            margin-bottom: 5px;

            &__second {
              display: grid;
              gap: 10px;
              grid-template-columns: 1fr 150px;
            }

            .ant-picker {
              width: 100%;

              &.ant-picker-disabled {
                background-color: #fff;
              }
            }

            .orcatec-input input:disabled {
              background-color: initial;
            }

            // .status {
            //   flex: 0 0 150px;
            // }

            .dragIcon {
              position: absolute;
              left: -15px;
              top: 35%;
            }

            .ant-form-item-explain {
              min-height: initial;
            }
          }
        }

        &__actions {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          flex-basis: 30px;
          margin-top: 15px;

          .delete-icon {
            cursor: pointer;

            &.disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }

          .card-icon {
            &.disabled {
              // opacity: 0.5;
              // cursor: not-allowed !important;
              pointer-events: none;
            }
          }
        }
      }

      @media (max-width: 767px) {
        .payment-balance {
          &__content {
            .content-row {
              .ant-form-item-control-input {
                min-height: initial;

                .ant-picker {
                  padding: 4px;
                  input {
                    font-size: 12px;
                    line-height: 1.3;
                  }
                }
              }

              .status {
                flex: 0 0 80px;
              }
            }
          }
        }
      }
    }

    /*     &__row {
      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      position: relative;

      & > div {
        flex: 1 1 130px;
        padding: 8px;

        &:nth-child(1) {
          position: absolute;
          left: -20px;
          top: 4px;
          outline: none;
        }
        &:nth-child(3) {
          flex: 0 1 140px;

          .ant-calendar-picker {
            min-width: 115px;
            max-width: 130px;
            text-align: center;
          }
        }
        &:nth-child(4) {
          flex-grow: 3;
        }
        &:nth-child(6) {
          flex: 0 1 120px;

          & > div {
            width: 100%;
          }
        }
        &:nth-child(7) {
          flex: 0 0 110px;
          white-space: initial;
        }
      }

      .ant-form-explain {
        position: absolute;
      }
      .ant-checkbox-wrapper {
        position: relative;
        top: 2px;

        &:hover {
          .ant-checkbox-inner {
            border-color: #191919;
          }
        }

        .ant-checkbox-checked {
          &:after {
            border-color: #191919;
          }
          .ant-checkbox-inner {
            background-color: #191919;
            border-color: #191919;
          }
        }

        .ant-checkbox-disabled {
          .ant-checkbox-inner {
            background-color: #c2c2c2;

            &:after {
              border-color: #fff;
            }
          }
        }
      }

      .accept_card,
      .remove_transition {
        padding: 0;
        border: 0;
        background-color: transparent;
        margin-left: 25px;

        &:disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
    } */

    .payment-transactions {
      flex-basis: 100%;
      font-size: 12px;
      margin-left: 10px;
      padding-bottom: 10px;
      overflow: auto;

      &__row {
        display: flex;

        p {
          padding: 5px;
          flex: 0 0 130px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &.id {
            flex: 0 0 100px;
          }
          &.acc_holder,
          &.processed_by,
          &.status {
            flex: 0 0 110px;
          }
          &.check_number,
          &.acc_number {
            flex: 0 0 90px;
          }
          &.method {
            flex: 0 0 60px;
          }
        }

        &.heading {
          color: #000000;
          opacity: 0.5;
        }
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-input[disabled] {
      background-color: white;
    }
    .MuiInputBase-root {
      background-color: #fff;
    }
  }

  .balance-controls {
    margin: 0;
    display: 'flex';
    justify-content: 'space-between';
    width: '100%';

    p {
      display: flex;
      justify-content: flex-end;
      span {
        flex: 0 0 100px;
      }
    }
  }

  .payment-information {
    margin: 30px -15px 0;

    label {
      opacity: 0.5;
    }
  }

  .remove {
    font-size: 28px;
    cursor: pointer;
  }

  .footer-buttons {
    justify-content: space-evenly;
    .btn-reset {
      margin-left: 40px;
    }

    @media (max-width: 576px) {
      .btn-reset {
        margin-left: 10px;
      }
    }

    @media (max-width: 330px) {
      .btn {
        padding: 1px 6px;
      }

      .left {
        padding-right: 8px;
      }

      .rigth {
        padding-left: 8px;
      }
    }
  }

  .balance_left {
    font-weight: bold;

    &.red {
      color: #ec4561;
    }
  }
  .error {
    color: #ec4561;
  }
  &.modal-90w {
    width: 90%;
    max-width: 1000px !important;
  }

  @media (max-width: 499px) {
    .modal-body {
      padding: 10px;

      .modal-payment-table {
        margin-bottom: 10px;
        // padding-left: 10px;
        .thead {
          font-size: 6px;

          .modal-payment-table__row {
            & > div {
              white-space: pre-line;
            }
          }
        }

        .rowWrapper {
          &.filled {
            margin-bottom: 5px;
          }
        }

        &__row {
          & > div {
            padding: 2px;
            flex: 1 1 70px;

            input {
              font-size: 8px;
              // height: 25px;
              // padding: 0 5px;
            }

            .ant-form-item-control {
              line-height: 20px;
            }

            &:nth-child(1) {
              top: 2px;
              left: -16px;

              svg {
                width: 7px;
              }
            }

            &:nth-child(3) {
              flex: 1 1 70px;

              .ant-calendar-picker {
                min-width: 60px;
                max-width: 60px;
                text-align: center;

                input {
                  padding: 0 5px;
                  height: 25px;
                  font-size: 8px;
                }
              }
            }

            &:nth-child(5) {
              flex: 1 0 60px;

              .MuiFormControl-root {
                width: 100%;
                .MuiInputBase-root {
                  font-size: 8px;

                  .MuiSelect-root {
                    padding: 8px 5px;
                    padding-right: 15px;
                  }
                }
                svg {
                  right: 0px;
                  top: 3px;
                }
              }
            }

            &:nth-child(7) {
              flex: 0 0 60px;
            }
          }
          .accept_card {
            margin-left: 5px;

            .ant-checkbox-inner {
              width: 14px;
              height: 14px;

              &:after {
                border-width: 1px;
                top: 49%;
                width: 4px;
                height: 8px;
              }
            }
          }
          .remove_transition {
            margin-left: 13px;

            svg {
              width: 14px;
            }
          }
        }

        .payment-transactions {
          margin-left: 5px;
          font-size: 8px;

          &__row {
            p {
              padding: 2px;
              flex: 0 1 75px;

              &.id {
                flex: 0 0 60px;
              }
              &.acc_holder,
              &.processed_by {
                flex: 1 0 60px;
              }
              &.acc_number {
                flex: 0 0 52px;
              }
              &.check_number {
                flex: 0 0 30px;
              }
              &.method {
                flex: 0 0 30px;
              }
              &.status {
                flex: 0 0 40px;
              }
            }
          }
        }
      }

      .payment-information {
        margin: 10px -15px;

        label {
          font-size: 8px;
        }
      }
    }

    .total-to-pay {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .balance-controls {
      .col {
        & > div {
          margin-right: 10px !important;

          p {
            font-size: 8px;

            .balance_left {
              flex: 0 0 60px;
            }
          }
        }
      }
    }

    h4 {
      margin-bottom: 15px;
    }

    &.modal-90w {
      width: 97%;
    }
  }
}
.small:hover {
  cursor: pointer;
}
