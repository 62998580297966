// .property-address {
// .custom-address-autocomplete-wrapper {
//   margin-bottom: 1rem;
// }

// & > * {
//   margin-bottom: 1rem;
// }

.address-fields {
  .address-row {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    .city,
    .zip,
    .unit {
      margin-right: 10px;
    }
  }
}
// }
