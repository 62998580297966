.chat-media-preview {
  &__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 10px;
  }

  &__item {
    position: relative;
    .remove-icon {
      &:hover {
        svg path {
          fill: red;
        }
      }
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
  }

  &__picture {
    width: 100px;
    height: 100px;
    cursor: pointer;
  }
}
