.appointmentStatus {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  .mdi-chart-donut {
    margin-right: 5px;
    font-size: 18px;
    color: #2e5bff;
  }
  .mdi-progress-clock {
    margin-right: 5px;
    font-size: 18px;
    color: #000000;
  }
  .mdi-check-circle {
    margin-right: 5px;
    font-size: 18px;
    color: #30be89;
  }
  .mdi-cancel {
    font-size: 18px;
    margin-right: 5px;
    color: #eb5757;
  }

  .mdi-truck-fast {
    font-size: 18px;
    margin-right: 5px;
    color: #ffad00;
  }

  @media (max-width: 499px) {
    .mdi-cancel,
    .mdi-chart-donut,
    .mdi-check-circle,
    .mdi-progress-clock {
      font-size: 14px;
    }
  }
}
