.clients-suggestions-wrapper {
  height: fit-content;
  width: 100%;
  padding: 10px;

  .suggestions-table {
    font-size: 12px;
    &__heading {
      display: flex;
      color: rgba(0, 0, 0, 0.35);
      margin-bottom: 5px;

      & > * {
        &:not(:first-child) {
          text-align: center;
        }
        &:nth-child(1) {
          flex: 1 0 115px;
          padding-left: 5px;
        }
        &:nth-child(3) {
          flex: 1 0 30px;
        }
        &:nth-child(4) {
          flex: 1 0 30px;
        }
        &:nth-child(5) {
          flex: 1 0 30px;
        }
        &:nth-child(6) {
          flex: 0 0 120px;
        }
        &:nth-child(7) {
          flex: 0 0 30px;
        }
      }
    }
    &__body {
      padding: 5px;
      border-radius: 4px;
      background-color: #d3e0ff;
      .suggestions-table__row {
        background: #fff;
        border-radius: 4px;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        .first,
        .second {
          display: flex;
          align-items: center;
          padding: 5px;

          & > * {
            &:not(:first-child) {
              text-align: center;
            }

            &:nth-child(1) {
              flex: 1 0 115px;
            }
            &:nth-child(3) {
              flex: 1 0 30px;
            }
            &:nth-child(4) {
              flex: 1 0 30px;
            }
            &:nth-child(5) {
              flex: 1 0 30px;
            }
            &:nth-child(6) {
              flex: 0 0 120px;

              button {
                background-color: #4285f4;
                border: 1px solid #4285f4;
                font-weight: 600;
                font-size: 10px;
                padding: 8px 20px;
              }
            }
            &:nth-child(7) {
              flex: 0 0 30px;
            }
          }
        }

        .second {
          display: flex;
          padding: 15px;
          justify-content: space-between;

          /* & > div {
            flex: 0 1 31%;
            text-align: center;
          } */
        }
      }
    }
  }

  @media (max-width: 499px) {
    padding: 0;

    .suggestions-table {
      font-size: 8px;
      margin-bottom: 10px;

      .suggestions-table__heading > *:nth-child(1) {
        flex: 1 0 85px;
        padding-left: 5px;
      }
      .suggestions-table__heading > *:nth-child(5) {
        flex: 0 0 60px;
        padding-left: 5px;
      }
      .suggestions-table__body {
        padding: 2px;

        .suggestions-table__row {
          .first,
          .second {
            & > * {
              &:nth-child(1) {
                flex: 1 0 60px;
              }
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                flex: 1 0 40px;
              }
              &:nth-child(5) {
                flex: 0 0 60px;

                button {
                  font-size: 6px;
                  padding: 5px;
                }
              }
              &:nth-child(6) {
                flex: 0 0 20px;
              }
            }
          }

          .second {
            padding: 5px;

            /* & > div {
              &.properties {
                flex: 1 1 31%;
              }
            } */
          }

          &:not(:last-child) {
            margin-bottom: 2px;
          }
        }
      }
    }
  }
}
