.status_box {
  .active,
  .view_only,
  .overdue,
  .inactive {
    font-weight: 500;
    font-size: 11px;
    border-radius: 4px;
    width: 84px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .active {
    color: #fff;
    background: #2ed47a;
  }
  .pending {
    color: #fff;
    background: #ff00ad;
  }
  .view_only {
    color: #ffb946;
    background: #fff;
  }
  .inactive {
    color: #f7685b;
    background: rgba(247, 104, 91, 0.25);
  }
  .disabled {
    color: #fff;
    background: red;
  }
}
