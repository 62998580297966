.tags {
  ul {
    padding: 0;
    margin: 0;
    margin-top: 10px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      display: flex;
      justify-content: center;
      background-color: rgba(76, 83, 239, 0.07058823529411765);
      padding: 9px;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 0.01em;
      color: #4285f4;
      margin: 0 5px 5px 0;
      border-radius: 4px;
      overflow: hidden;

      span {
        margin-left: 10px;
        cursor: pointer;

        i::before {
          font-weight: 700;
        }
      }
    }
  }
}
