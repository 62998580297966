$errorColor: #f44336;
$mainColor: #4285f4;
.custom-login-form {
  .form-control.is-invalid,
  .was-validated .form-control:invalid {
    border-color: $errorColor;
    padding-right: 12px;
    background-image: none;
  }
  .error-message {
    color: $errorColor;
    font-size: 0.875rem;
  }
}

.custom-login-form {
  width: 100%;
  .remember-me-block {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 25px;
    .forgot-password-text {
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      letter-spacing: 0.01em;
      text-decoration-line: underline;
      color: #2e5bff;
    }
  }
  .resend-message {
    width: 100%;
    margin-top: -1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $errorColor;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.01em;
    span {
      text-align: center;
    }
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      color: #2e5bff !important;
      span {
        cursor: pointer;
        font-weight: 500;
        text-decoration-line: underline;
      }
    }
  }
  .main-button {
    width: 100%;
  }
  .please-text {
    margin: 1.5rem 0 45px !important;
  }
  .sign-up-link {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.01em;
    margin-top: 1.5rem;
    span {
      opacity: 0.35;
      margin-right: 15px;
      color: #000000;
    }
    a {
      font-weight: 500;
      text-decoration-line: underline;
      color: #2e5bff !important;
    }
  }
}
