.legal-page {
  .take-your-time-text {
    font-weight: 600;
    font-size: 18px;
    line-height: 148.5%;
    text-align: center;
    margin-top: 7px;
  }
  .terms-and-cond-block {
    width: 100%;
    margin: 100px 0 72px;
  }
  /* .halp-text {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #191919;
    opacity: 0.5;
    margin-bottom: 30px;
  } */
  a {
    color: #4285f4 !important;
    text-decoration-line: underline !important;
    text-transform: uppercase;
  }
  .buttons-block {
    width: 100%;
    margin-top: 50px;
  }
}
