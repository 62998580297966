.ticket-attachments__container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  &.from-right {
    flex-direction: row-reverse;

    .ticket-attachment__upload {
      margin-right: 0;
      margin-left: 15px;
    }
  }

  .ticket-attachment__upload {
    margin-right: 15px;
    width: 142px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    button {
      .ticket-upload-attachment__icon {
        margin-right: 15px;

        &.spinner-border {
          width: 20px;
          height: 20px;
          color: #fff !important;
        }
      }
    }

    input[type='file'] {
      display: none;
    }

    .ticket-upload-attachment__error {
      font-size: 12px;
      color: #f44336;
    }
  }

  .ticket__attachments {
    flex: 1;
    padding: 5px 0 15px;

    .ticket-attachment__item {
      margin-right: 20px;
      margin-bottom: 5px;
      float: left;
      display: flex;

      .ticket-attachment__name {
        font-weight: 600;
        color: #5b61f0;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 5px;
      }

      .ticket-attachment__remove {
        cursor: pointer;
      }
    }
  }

  .btn-custom-info {
    white-space: nowrap;
  }
}
