.modal-proposal-reminder {
  .timePickerModal {
    i {
      display: none !important;
    }
  }

  .modal_title {
    font-weight: 600;
    font-size: 32px;
    color: #191919;
    text-align: center;

    @media (max-width: 499px) {
      font-size: 24px;
    }
  }

  .box_title {
    font-weight: 600;
    font-size: 21px;
    color: #191919;
    margin: 20px 0 30px 0;
  }

  .reminder_parent {
    .client_container {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .client_name {
        min-width: 40%;
        font-weight: normal;
        font-size: 12px;
        color: #808080;
        margin-right: 10px;
        &-add {
          text-decoration: underline;
          color: #000000;
          font-size: 12px;
          cursor: pointer;
        }
      }
      .client_email {
        &-add {
          display: flex;
        }
      }
      .email_box {
        .email {
          margin-left: 10px;
          font-weight: normal;
          font-size: 12px;
          color: #808080;
        }
      }
    }
    .other_email_container {
      display: flex;
      margin-top: 15px;
      .title {
        font-weight: normal;
        font-size: 12px;
        color: #808080;
        min-width: 40%;
        margin-right: 10px;
      }
      .email_item {
        display: flex;
        align-items: center;
        .other_email {
          margin-left: 10px;
          height: 40px;
          font-size: 12px;
        }
        div.ant-form-explain {
          margin-left: 10px;
          margin-top: 3px;
          font-size: 12px;
        }
        .ant-checkbox-wrapper {
          margin-bottom: 20px;
        }
      }
    }
  }

  .date_time_container {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
    .l_date,
    .l_time {
      font-weight: normal;
      font-size: 12px;
      color: #808080;
    }
    .date_box {
      width: 40%;
      .datePickerModal {
        width: 90%;
      }

      @media (max-width: 499px) {
        width: 50%;
      }
    }
    .time_box {
      width: 30%;
      .timeIcon {
        font-size: 14px;
      }
    }
  }

  .error_editor {
    color: red;
    font-size: 12px;
  }

  .title_editor {
    font-weight: normal;
    font-size: 14px;
    color: #808080;
    margin-bottom: 10px;
  }

  .reminder_list_table {
    table > thead > tr > th {
      font-weight: 500;
      font-size: 13px;
      color: #334d6e;
    }

    table > tbody > tr > td {
      font-weight: normal;
      font-size: 13px;
      color: #707683;
      height: 40px;
      padding: 0 16px 10px !important;
    }
  }

  .error_client {
    background: #ffe0ea;
    border: solid red 1px;
    padding: 10px;
    transition: all 0.3s;
  }

  .modal-icon {
    font-size: 25px;
  }
  .modal-icon:hover {
    cursor: pointer;
  }
  .loading {
    width: 25px;
    height: 25px;
    margin: 0;
    display: flex;
    align-items: center;
  }
  .spinner-border {
    width: 1rem;
    height: 1rem;
  }
}

.clientsTableBody > tr > .messagePreview > div > p {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
