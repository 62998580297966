.property-address {
  // .custom-address-autocomplete-wrapper {
  //   margin-bottom: 1rem;
  // }
  .address-fields {
    margin-top: 1rem;

    .address-row {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      .city,
      .zip,
      .unit {
        margin-right: 10px;
      }
    }
  }
}

.custom_address {
  display: grid;
  grid-template-columns: 92% 6%;
  justify-content: space-between;
}
