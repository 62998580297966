.appointment-statuses-wrapper {
  display: flex;
  .appointment-statuses-container {
    max-width: 300px;
    display: flex;
    gap: 20px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    .status-container {
      position: relative;
      &.inactive {
        .appointmentStatus > .mdi {
          color: grey;
          opacity: 0.8;
        }
      }

      &.blocked {
        .appointmentStatus > .mdi {
          cursor: not-allowed;
          color: grey;
          position: relative;
          opacity: 0.2;

          &::after {
            position: absolute;
            content: '';
            width: 80%;
            height: 65%;
            left: 4px;
            top: 7px;
            background: linear-gradient(
              to top right,
              #fff0 calc(50% - 2px),
              #ff0000,
              #fff0 calc(50% + 2px)
            );
          }
        }
      }
      &.disabled {
        .appointmentStatus > .mdi {
          cursor: not-allowed;
        }
      }

      .appointmentStatus > .mdi {
        cursor: pointer;
        font-size: 36px;
      }

      /* &:not(:first-child) {
        &::before {
          position: absolute;
          content: '➞';
          left: -21px;
          top: 16px;
        }
      } */
    }
  }
}
.statuses-loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: grey;
  opacity: 0.1;
  cursor: not-allowed;
  z-index: 10000;
}
