.tab-info {
  & > * {
    margin-bottom: 10px;
  }

  & > .user-form-profile-picture > .company-drag-wrapper > span > .ant-upload {
    border-radius: 0;

    & > .customer-block__image > div {
      border-radius: 0;
    }
  }

  & .user-form-profile-picture > div > div > div > .reactEasyCrop_Container > div {
    border-radius: 1%;
  }

  & .user-form-profile-picture > div > span > div > span > .ant-upload-drag-container > div > div {
    border-radius: 0 !important;

    & > i  {
      right: -45px !important;
    }

  }


  .MuiTextField-root {
    margin-bottom: 10px;
  }

  .tab-image {
    height: 150px;
    width: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;

    span {
      position: absolute;
      top: -5px;
      right: 5px;
      font-size: 24px;
      color: red;
      cursor: pointer;
    }
  }

  .tab-info-buttons {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;

    & > * {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
