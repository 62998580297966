.information_for_workers {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  position: relative;

  .label {
    position: absolute;
    background-color: #fff;
    color: #19191980;
    letter-spacing: 0.01em;
    padding: 0 5px;
    top: -13px;
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;

    .calendar {
      display: flex;
      cursor: pointer;
      flex: 0 0 auto;

      .dates {
        color: #707683;
        font-size: 12px;
        // margin-left: 10px;
        // margin-right: 20px;
        position: relative;

        span {
          position: absolute;
          width: 20px;
          height: 20px;
          top: -5px;
          right: -20px;
          border-radius: 50%;
          font-size: 9px;
          color: #4285f4;
          background-color: #4c53ef2b;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    //.custom-toggle-btn {
    // flex: 0 0 24px;
    //}

    & > label {
      flex: 1 0 130px;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .attached-files {
      position: relative;
      // margin: 0 50px;
      cursor: pointer;

      .fa-paperclip {
        font-size: 18px;
        color: #00000082;
      }

      // span {
      //   position: absolute;
      //   width: 20px;
      //   height: 20px;
      //   top: -8px;
      //   right: -15px;
      //   border-radius: 50%;
      //   font-size: 9px;
      //   color: #4285f4;
      //   background-color: #4c53ef2b;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      // }
    }

    .notes_for_installers {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__content {
    & > div:first-child {
      margin-top: 10px;
    }
  }

  @media (max-width: 426px) {
    padding: 10px 10px 5px;

    .label {
      font-size: 10px;
      top: -9px;
    }

    &__heading {
      gap: 10px;
      font-size: 8px;

      .calendar {
        .dates {
          font-size: 8px;
          margin-left: 5px;
        }
      }

      label {
        font-size: 8px;
      }

      .notes_for_installers {
        margin-left: 10px;
      }
    }
  }
}
