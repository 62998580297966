.close-button-header {
  position: absolute;
  top: 10px;
  right: 20px;

  .close-button {
    font-weight: bold;
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
}
.accounting-spin__wrapper {
  min-height: 600px;
  & .accounting-spin {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.accounting-header {
  .accounting-title {
    text-align: center;
    font-weight: 600;
    font-size: 2rem;
    color: #191919;
  }

  .accounting-subtitle {
    margin-right: 10px;
  }
}

.total-line {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.01em;
  color: #000000;
  align-items: flex-end;
  display: grid;
  grid-template-columns: 80% 20%;
  margin-bottom: 4px;
  @media (max-width: 767px) {
    grid-template-columns: 75% 25%;
  }

  .total-line-title {
    flex-basis: 80%;
    font-size: 100%;
    text-align: end;
  }

  .total-line-value {
    padding-left: 10px;
  }
  .percent_value {
    color: gray;
    font-size: 13px;
  }

  .percent_value {
    color: gray !important;
    font-size: 13px !important;
  }
}
.total-line_payout {
  display: grid;
  grid-template-columns: 70% 30%;
}

// clear table
.payment-balances {
  .table {
    margin-bottom: 0;
  }

  .table-row {
    margin-bottom: 0 !important;
  }
}

.accounting-footer {
  margin-top: 10px;

  .commission-row {
    width: 100%;
    margin-bottom: 10px;
    .bottom-row {
      width: 100%;
      display: flex;
      .inputs {
        width: 69.5%;
        display: flex;
        flex-direction: row;
        font-size: 1rem;

        .commission-type {
          width: 160px;
          margin-right: 5px;
        }

        .percentage {
          width: 60px;
          & .MuiOutlinedInput-adornedEnd {
            padding-right: 4px;
          }
          & .MuiOutlinedInput-inputMarginDense {
            padding: 5px !important;
          }
        }

        .text {
          display: flex;
          align-self: center;
          margin-left: auto;

          font-style: normal;
          font-weight: 500;
          font-size: 100%;
          letter-spacing: 0.01em;
          color: #000000;
        }
      }

      .value {
        display: flex;
        align-items: center;
        width: 15%;
        padding-left: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.01em;
        color: #000000;
      }
    }
  }
}

.percent_value {
  color: gray !important;
  font-size: 13px !important;
}

.accounting-footer-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;

  .cancelBtn {
    margin-right: 20px;
  }
}
.total-line__payouts {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 100%;
  letter-spacing: 0.01em;
  color: #000000;
  font-size: 16px;
}
