@import '../../../../ServiceContracts/components/Form/index.scss';
%total-row {
  height: 40px;
  background-color: #dcdfe5;
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  h5 {
    align-self: center;
    font-size: 14px;
    margin: 0;
  }
  span {
    align-self: center;
  }
}

.radioWrapper {
  min-width: 100% !important;
  overflow-x: auto;
}

.proposal-form {
  .loading-tab-preloader-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 293px;
  }
  .freeze {
    position: relative;
    pointer-events: none;

    .cover {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(223, 223, 223, 0.603) !important;
    }
  }
  .tabs {
    display: flex;
    margin-top: 10px;
  }
  .tab-content {
    margin: -1px 0 0px 0;
    border: 1px solid #d6d6d6;
    background: #f2f3fe;
    border-radius: 4px;
    padding: 0 10px 10px 10px;
    .description {
      width: 50%;
      max-width: 500px;
      margin: 50px auto;
      border: 1px solid grey;
      padding: 20px;
      position: relative;
      .desc {
        text-align: left;
        font-size: 16px;
      }
      .action {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  .proposal-tab {
    align-self: center;
    border: 1px solid #d6d6d6;
    border-radius: 4px 4px 0 0;
    background: #ffffff;
    height: 40px;
    min-width: 100px;
    max-width: 220px;
    margin: 0 3px;
    opacity: 0.5;
    display: flex;
    flex-wrap: nowrap;
    padding: 0px 15px;

    color: white;
    font-weight: 500;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.01em;
    color: #191919;
    align-content: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    .ant-radio-inner {
      border-color: #000000;
      &::after {
        background-color: #000000;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .ant-radio-inner:hover {
      border-color: #000000;
      &::after {
        background-color: #000000;
      }
    }
    i {
      margin: 0 10px;
    }
    .input-container {
      display: flex;
      input {
        height: 100%;
      }
    }
    &.active {
      background: #f2f3fe;
      opacity: 1;
    }
    .instalation {
    }
  }
  .media-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    .media {
      height: 150px;
      width: 150px;
      margin: 10px;
      position: relative;
      img {
        height: 100%;
        width: 100%;
      }
      button {
        position: absolute;
        top: 5%;
        right: 5%;
      }
    }
  }
  .instalation {
    display: flex;
    justify-content: flex-end;
    padding: {
      top: 20px;
    }
    label {
      display: flex;
    }
    span {
      align-self: center;
      margin-right: 20px;
    }
  }

  .total-row {
    @extend %total-row;
  }

  .form-custom {
    .actions-modals {
      display: flex;
      .action {
        cursor: pointer;
        text-align: center;
        .mdi {
          font-size: 20px;
        }
        .fas {
          font-size: 20px;
          margin-top: 8px;
        }
        .far {
          font-size: 20px;
          margin-top: 8px;
        }
      }
      /* .action{
        text-align: center;
        margin: 0 5px;        
      } */
    }
    .first-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      .proposal-notes {
        width: 50%;
      }
      .actions-modals {
        align-self: flex-end;
        width: 50%;
        display: flex;
        justify-content: space-around;
      }
    }
    .second-row {
      .align-self-center {
        .tags {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;
          max-height: 100px;
          overflow-y: auto;
          .tag {
            padding: 10px;
            height: 35px;
            position: relative;
            background: rgb(98, 110, 212);
            margin: 4px;
            align-self: center;
            text-align: center;
            vertical-align: middle;
            border-radius: 5px;
            font-size: 14px;
            line-height: 16px;
            color: white;
            button {
              margin-left: 2px;
              padding: 0;
              background: none;
              border: none;
              top: 0px;
              right: 0px;
              color: white;
              width: 16px;
              height: 16px;
              line-height: 16px;
              border-radius: 20px;
            }
          }
        }
      }
      .action {
        @extend %action;
      }
      display: flex;
      width: 100%;
      .installer-notes {
        width: 50%;
      }
    }
    .proposal-tags-container {
      display: flex;
      justify-content: space-between;
      width: 40%;
    }
    .proposal-tags {
      align-self: flex-start;
      width: 60%;
      .tags {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        max-height: 100px;
        overflow-y: auto;
        .tag {
          padding: 10px;
          height: 35px;
          position: relative;
          background: rgb(98, 110, 212);
          margin: 4px;
          align-self: center;
          text-align: center;
          vertical-align: middle;
          border-radius: 5px;
          font-size: 14px;
          line-height: 16px;
          color: white;
          button {
            margin-left: 2px;
            padding: 0;
            background: none;
            border: none;
            top: 0px;
            right: 0px;
            color: white;
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 20px;
          }
        }
      }
    }
  }
  .end-of-system-table {
    width: 100%;
    tr > td:nth-child(1) {
      width: 30%;
    }
    tr > td:nth-child(2) {
      width: 10%;
    }
    tr > td:nth-child(3) {
      width: 15%;
      text-align: left;
    }
    tr > td:nth-child(4) {
      width: 25%;
      text-align: center;
    }
    .rebates {
      vertical-align: top;
    }
    .instants {
      height: calc(100% + 60px);
      vertical-align: top;
    }
  }
  .section-header {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    //margin-top: 20px;
    //padding: 8px;
    //color: white !important;
    //box-shadow: 0px 0px 2px black;
    input {
      height: auto;
    }
    i {
      align-self: center;
      margin: 0 20px;
    }
  }
}
