.navbar-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(0px);
  transition: width 0.5s, transform 0.3s;
  height: 80px;

  @media (max-width: 499px) {
    height: 58px;
    min-height: 58px;
  }

  .dropdown-toggle {
    .customer-block__image {
      & > div {
        width: 25px;
        height: 25px;
      }
    }
  }

  .clock-out-btn-top {
    display: flex;
    // width: 100%;
    justify-content: flex-start;
    //button{
    //  font-size: large;
    //  width: 150px;
    //  position: relative;
    //  right: -95%;
    //  top: 50%;
    //  height: 60%;
    //  top: 50%;
    //  transform: translate(-100%, -50%);
    //}
  }

  /* .pay-notification {
    display: flex;
    margin: 0 20px;
    color: #ffffff;
    background: rgba(255, 2, 2, 0.58);
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 15px;
    line-height: 1.3;

    .days_left {
      flex: 0 0 200px;
      text-align: end;

      span {
        font-size: 32px;
        margin-right: 10px;
        line-height: 1.5;
      }
    }
  } */

  & > ul {
    margin-left: auto;
  }
  &--content {
    //padding: 10px 25px;
    display: flex;

    &--left {
      display: flex;
      flex-direction: column;
      margin-right: 30px;
      a {
        margin-bottom: 10px;

        button {
          width: 100%;
        }
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &--time--label {
        height: calc(100% - 35px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
          margin-top: 5px;
          text-align: center;
        }
      }
    }
  }
}

.nav-user {
  display: flex;
  width: max-content;
  align-items: center;
  i {
    font-size: 18px;
    margin-right: 12px;
  }
  .logined-user-name {
    /* min-width: 0; */
    /* text-overflow: ellipsis;
    overflow: hidden; */
    position: relative;

    span.user-unseen-tickets__counter {
      position: absolute;
      bottom: -8px;
      right: -35px;
      font-size: 11px;
      width: 18px;
      height: 18px;
      background-color: orange;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
    }

    span.logined-user__name {
      white-space: nowrap;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      /* identical to box height */
      color: #191919;
    }
  }
}

.dropdown-item__unseen-tickets {
  position: relative;

  span.user-unseen-tickets__counter {
    position: absolute;
    top: 8px;
    right: 5px;
    font-size: 11px;
    width: 18px;
    height: 18px;
    background-color: orange;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
  }
}

.timecards-modal {
  max-width: 1000px;

  .modal-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    padding-right: 36px;
  }
}

@media (max-width: 478px) {
  .nav-user {
    .logined-user-name {
      span.logined-user__name {
        white-space: initial;
        font-size: 10px;
      }
    }
  }
}
@media (max-width: 430px) {
  .nav-user {
    .logined-user-name {
      span {
        display: none;
      }
    }
  }
}

.side-line {
  margin-top: -6px;
  min-height: 50px;
  width: 2px;
  background-color: #e5e5e5;
}

.center-align {
  align-items: center;
}
.topbar {
  position: fixed;
  top: 0;
  transition: top 0.3s;
  width: 100%;

  height: 80px !important;
  .navbar-custom {
    height: 80px !important;
  }
}
.button-menu-mobile:hover {
  background: rgba(247, 247, 247, 0.3);
}

.topbar-hidden {
  top: -80px;
}
.topbar-right {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 888;
  margin-left: 80px;
  width: calc(#{100%} - #{80px});
  transform: translateY(0);
  transition: width 0.5s, transform 0.3s;
}
.topbar-right-sidebar-closed {
  margin-left: 54px;
  width: calc(#{100%} - #{54px});
  transition: width 0.5s, transform 0.3s;
}
.topbar-nav-hidden {
  transform: translateY(-80px);
  transition: width 0.5s, transform 0.3s;
}
.topbar-left {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  transition: width 0.5s, transform 0.3s;
  z-index: 888;

  @media (max-width: 499px) {
    height: 58px;
  }

  .beta {
    position: absolute;
    width: 60px;
    height: 31px;
    left: -24px;
    top: -15px;
    transform: rotate(-14deg);

    @media (max-width: 768px) {
      display: none;
    }
  }

  .gamma {
    position: absolute;
    width: 80px;
    left: -15px;
    bottom: -17px;
  }
}
.topbar-left-sidebar-closed {
  width: 54px;
  transition: width 0.5s, transform 0.3s;
}

.button-menu-mobile {
  width: 44px;
  height: 44px;
  position: relative;
  overflow: initial;
}
@media (max-width: 800px) {
  .button-menu-mobile {
    background: rgba(247, 247, 247, 0.15);
    color: white;
    //opacity: 0.15;
    border-radius: 4px;
    width: 44px;
    height: 44px;
    //background: #4285f4 !important;
    font-size: 24px;
  }
  /* .mdi-menu {
    color: rgb(134, 153, 173);
  } */
}
@media (max-width: 450px) {
  .liWrapper {
    width: 10px !important;
  }
}
.hide-top__bar {
  display: none;
}
