$lineHeight: 40px;
$marginBottom: 1.5rem;
$marginBottomWithButtons: calc(1 * #{$marginBottom});
$marginBetween: 10px;
$statusWidth: 180px;
$buttonsBlockWidth: 270px;
.proposal-form {
  &
    > .proposal-form-header
    > .proposal-form-header-part1
    > .heading__buttons
    > button {
    margin-left: 10px;
  }
  &-header {
    display: flex;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 calc(-#{$marginBetween}) 10px;

    &-part1 {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
    }
    &-part2 {
      display: flex;
      flex-wrap: wrap;
    }
    &-col {
      display: flex;
      align-items: baseline;
      margin: 0 $marginBetween;
      // height: $lineHeight;
      .MuiTextField-root {
        margin-bottom: 0;
      }

      &.caneled-status {
        display: block;
      }

      &.buttons {
        align-items: center;
      }

      &-status-canceled_info {
        margin-top: 5px;
      }
    }
    &-part1 > &-col:nth-child(1) {
      position: relative;
      font-weight: 600;
      font-size: 24px;
      color: #191919;
      white-space: nowrap;
      //margin-bottom: $marginBottom;
      /*  div {
        position: absolute;
        bottom: -15px;
        left: 0;
        transform: translateY(50%);
        button:not(:last-child) {
          margin-right: 17px;
        }
      } */
    }
    &-part2 > &-col:nth-child(1) {
      position: relative;
      .proposal-form-header-col-status-canceled_info {
        // position: absolute;
        // bottom: -115%;
        // left: 0;
        //transform: translateY(50%);
        & > div {
          font-style: normal;
          font-weight: 500;
          font-size: 11px;
          line-height: 16px;
          letter-spacing: 0.01em;
          color: #a6a6a6;
          & > span {
            color: #191919;
          }
        }
        & > div:last-child:not(:first-child) {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
        }
      }
    }
    &-part1 > &-col:nth-child(2) {
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.01em;
      color: #191919;
      white-space: nowrap;
      //margin-bottom: $marginBottom;
    }
    &-part1 > &-col:nth-child(3) {
      flex: 1;
      // min-width: $statusWidth;

      .common-table-actions {
        justify-content: space-between;
        flex: 0 1 240px;
        margin-left: 20px;
      }
    }
    &-part2 > &-col:nth-child(1) {
      width: $statusWidth;
      //min-width: 150px;
    }
    &-part2 > &-col:nth-child(2) {
      //margin-bottom: $marginBottom;
      button {
        width: 80px;
        // height: 40px;
        margin: 0 7.5px;
      }
      button:first-child {
        margin-left: 0;
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        background: #ffffff;
        color: #191919;
        &:active,
        &:focus {
          background-color: #ffffff;
          border: 1px solid #d6d6d6;
          box-shadow: unset;
        }
      }
      // button:nth-child(2):not(:last-child) {
      //   border: 1px solid #4c53ef12;
      //   color: #4285f4;
      //   background: #4c53ef12;
      //   border-radius: 4px;
      //   &:hover {
      //     background: #4c53ef29;
      //     transition: all 0.3s;
      //   }
      // }
      button:last-child {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 1260px) {
    &-header {
      // margin-bottom: $marginBottom;
      &-part1,
      &-part2 {
        width: 100%;
      }
      &-part2 > &-col:nth-child(1).caneled-status {
        margin-bottom: $marginBottom;
      }
      &-part2 > &-col:nth-child(1) {
        .proposal-form-header-col-status-canceled_info {
          top: 100%;
          & > div {
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 16px;
            letter-spacing: 0.01em;
            color: #a6a6a6;
            & > span {
              color: #191919;
            }
          }
          & > div:last-child:not(:first-child) {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
          }
        }
      }
      &-part2 > &-col:nth-child(1) {
        width: auto;
        flex: 1;
      }
      /* &-col:nth-child(4) {
        flex: 1;
      } */
    }
  }
  @media (max-width: 720px) {
    &-header {
      &-part1 {
        //margin-bottom: $marginBottom;
        justify-content: space-between;
      }
      &-part1 > &-col:nth-child(1) {
        //margin-bottom: $marginBottomWithButtons;
        //margin-bottom: $marginBottom;
        div {
          position: absolute;
          bottom: -15px;
          left: 0;
          transform: translateY(50%);
          button:not(:last-child) {
            margin-right: 17px;
          }
        }
      }
      /* &-col:nth-child(4) {
        flex: 1;
      } */
    }
  }
  @media (max-width: 699px) {
    &-header {
      &-part1 {
        justify-content: space-between;
      }
      &-part1 > &-col:nth-child(1) {
        //margin-bottom: $marginBottom;
        div {
          position: absolute;
          bottom: -15px;
          left: 0;
          transform: translateY(50%);
          button:not(:last-child) {
            margin-right: 17px;
          }
        }
      }
      /* &-col:nth-child(4) {
        flex: 1;
      } */
    }
  }
  @media (max-width: 590px) {
    &-header {
      &-part2 > &-col:nth-child(1) {
        width: 100%;
        //min-width: 150px;
        margin-bottom: $marginBottom;
      }
      &-part2 > &-col:nth-child(1).caneled-status {
        margin-bottom: calc(#{$marginBottom} + #{$marginBottom});
      }
      &-part2 > &-col:nth-child(2) {
        //margin-bottom: $marginBottom;
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
          width: 30%;
          min-width: 80px;
        }
      }
    }
  }
  @media (max-width: 520px) {
    &-header {
      &-part1 {
        justify-content: center;
      }
      &-part1 > &-col:nth-child(1) {
        div {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  @media (max-width: 470px) {
    &-header {
      &-part1 {
        justify-content: center;
      }

      &-part1 > &-col:nth-child(2) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        margin-bottom: 1rem;
        margin-top: 5px !important;
      }
    }
  }
  @media (max-width: 420px) {
    &-header {
      &-part2 > &-col:nth-child(2) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: auto;
        button {
          width: 100%;
          margin: 0;
        }
        button:not(:last-child) {
          margin-bottom: $marginBottom;
        }
      }
    }
  }
}
