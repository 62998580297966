.cancel-contract-modal {
  &-title {
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 134.5%;
    color: #191919;
  }
  &-title:not(:first-child) {
    margin-bottom: 1rem;
  }
}
