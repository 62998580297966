.reset-password-form.new-password {
  /* .ant-form-item:not(:last-child) {
        margin-bottom: 10px;
    }    
    .ant-form-item:last-child {
        margin-top: 20px;
        margin-bottom: 0px;
    }    
    .ant-form-item-control {
line-height: initial;
    } */
  .back-to-login-btn {
    color: #979797 !important;
  }
}
