.info_section_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  .info_general {
    //margin-right: 7%;
    margin-right: 15px;
    .subscription_summary {
      //margin-top: 20px;
      max-width: 360px;
      .title {
        color: #191919;
        font-size: 16px;
        margin-bottom: 10px;

        .trial-value__days {
          font-weight: 400;
          margin: 0 0 8px 0;
          color: grey;
          font-size: 14px;
        }

        span {
          font-weight: 700;
        }
      }

      .summary_info {
        display: flex;
        width: 95%;

        .summary_items {
          width: 100%;

          &__title {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #d6d6d6;
            padding: 5px;
          }

          p {
            color: #191919;
            font-weight: normal;
            font-size: 12px;
          }

          .total_amount {
            margin-top: 15px;
            font-weight: 600;
            font-size: 15px;
            color: #191919;
          }
        }

        .summary_items:first-child {
          // margin-right: 60px;
        }
      }
    }

    .due-info {
      margin: 10px 0;

      &__price {
        display: flex;

        p {
          font-weight: 600;
          color: #ff00ad;
          font-size: 15px;

          &:nth-child(1) {
            font-size: 17px;
            margin-right: 60px;
          }

          &:nth-child(3) {
            margin: 0 15px 0 5px;
          }
        }

        button {
          height: 25px;
        }
      }

      &__date {
        display: flex;
        align-items: baseline;
        font-size: 16px;
        color: #191919;

        p {
          &:nth-child(2) {
            text-decoration: underline;
            font-weight: 600;
            margin-left: 10px;
            font-size: 15px;
          }
        }
      }
    }

    .status_container {
      margin: 5px 0;
      //max-width: 622px;
      //min-width: 360px;
      //width: 100%;
      max-width: 360px;

      .parent {
        display: flex;

        p {
          color: #191919;
          font-weight: normal;
          font-size: 14px;
          margin-right: 30px;
        }
      }

      .message-cancel-subscription {
        margin: 5px 0;
      }
    }

    .charge_date_container {
      display: flex;
      align-items: center;
      width: 100%;

      p {
        font-weight: normal;
        font-size: 14px;
        color: #191919;
      }

      .charge_date {
        margin-left: 5px;
        font-weight: bold;
        font-size: 16px;
        color: #191919;
      }

      &.overdue {
        p {
          span {
            font-weight: 700;
          }
        }
      }
    }

    .subscription-buttons {
      display: flex;
      margin-top: 30px;

      button {
        &:nth-child(1) {
          margin-right: 10px;
          background-color: white;
        }
      }
    }

    /* .btn_cancel_subscription {
      margin-top: 30px;
      color: #000000;
      width: 149px;
      height: 40px;
      font-weight: 500;
      font-size: 12px;
      background: #fff;
      border: 1px solid #d6d6d6;
      box-sizing: border-box;
      border-radius: 4px;
    } */
  }

  .info_credit_card {
    .btn_edit_box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      p {
        color: #191919;
        font-weight: normal;
        font-size: 14px;
      }

      .edit_card {
        background: #edeffe;
        border-radius: 4px;
        border: none;
        width: 63px;
        height: 36px;
        color: #4285f4;
        font-size: 12px;
        font-weight: 500;
      }

      .edit_card:hover {
        opacity: 0.7;
      }
    }

    .credit_card_data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 330px;
      height: 113px;
      background: #ffffff;
      border: 1px solid #d6d6d6;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 15px;
      font-size: 13px;

      p {
        display: flex;
        justify-content: space-between;
        font-weight: normal;
        // font-size: 13px;
        color: #000000;
      }
    }
  }

  .pay-subscription__btn {
    margin-left: 10px;
    height: 36px;
    font-size: 12px;
    font-weight: 500;
  }

  @media (max-width: 499px) {
    .info_general {
      .subscription_summary {
        margin-top: 0;
      }

      .charge_date_container {
        flex-wrap: wrap;

        .charge_date {
          margin-left: 0;
        }
      }
    }
  }
}

.confirm_modal_container {
  width: 498px;
  max-width: 498px;
  @media (max-width: 498px) {
    width: 100%;
    max-width: 100%;
  }

  .confirm_title {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #000000;
    text-align: center;
    margin-bottom: 20px;
  }

  .confirm_description {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: rgba(25, 25, 25, 0.5);
    margin-bottom: 20px;
  }
}
