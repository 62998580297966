.matrix-settings {
  .resource-column {
    position: relative;
    margin: 0 2.5px;
    max-width: 60px;

    .mdi-menu {
      display: flex;
      justify-content: center;
      font-size: 30px;
      line-height: 30px;
    }
  }
}
