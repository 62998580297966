$primaryColor: #4285f4;
$infoColor: rgba(52, 159, 166, 0.75);
$infoColorDarker: #357cf3;
$infoColorGreen: #0ac50a;
// $infoColorDarker: rgba(52, 159, 166, 1);

.my-button-class {
  // font-weight: 500;
  font-size: 14px;
  line-height: 1;
  height: 30px;

  &.small {
    height: 20px;
    font-size: 12px;
  }

  &--spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-right: 5px;
    }
  }

  &:focus {
    box-shadow: unset;
  }

  &:disabled {
    pointer-events: none;
    cursor: not-allowed;
    // background-color: #fff;
    opacity: 0.65;
  }

  @media (max-width: 499px) {
    height: 25px;
    font-size: 0.8rem;
  }
}

.btn-primary {
  background-color: $primaryColor;
  border-color: $primaryColor;
  border-radius: 2px;
}

.btn-cancel {
  border-color: #bfc5d2;
  border-radius: 2px;
}
.btn-completed {
  background-color: $infoColorGreen;
  color: #edeffe;
  &:hover {
    color: #edeffe;
  }
}
.btn-delete {
  border: 1px solid #ed6566;
  // font-weight: 500;
}

.btn-custom-secondary {
  background-color: #edeffe;
  color: #4285f4;
  font-weight: 500;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $primaryColor;
  border-color: $primaryColor;
  cursor: unset;
}

.btn-set-default {
  height: 30px;
  display: flex;
  margin-left: 10px;
  align-items: center;
}

.btn-info {
  background-color: $primaryColor;
  border-color: $primaryColor;
  border-radius: 2px;
}
.btn-info:hover .btn-info.disabled,
.btn-info:disabled {
  background-color: $primaryColor;
  border-color: $primaryColor;
  cursor: unset;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:active,
.btn-info:focus,
.btn-info:hover,
.open > .dropdown-toggle.btn-info,
.btn-outline-info.active,
.btn-outline-info:active,
.show > .btn-outline-info.dropdown-toggle,
.btn-outline-info:hover,
.btn-info.active,
.btn-info:active,
.show > .btn-info.dropdown-toggle,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle,
.btn-outline-info:not([disabled]):not(.disabled).active,
.btn-outline-info:not([disabled]):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  background-color: $infoColorDarker;
  border: 1px solid $infoColorDarker;
}
