.message-status {
  display: flex;
  justify-content: center;
  padding: 2px 10px;
  color: #fff;
  border-radius: 4px;
  text-transform: capitalize;

  &.queued {
    background-color: #4285f4;
  }
  &.failed {
    background-color: #e53655;
  }
  &.sent {
    background-color: #2ebda3;
  }
  &.delivered {
    background-color: #2ebda3;
  }
  &.undelivered {
    background-color: grey;
  }
  &.received {
    background-color: #2ebda3;
  }
}
