.auto-comlete-address {
  input {
    padding-top: 0;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    position: relative;
    top: 15px;
    font-size: 15px;
  }
  label {
    margin: 0;
  }
}
.addressTextField {
  label {
    position: relative;
    top: 17px;
  }
  input {
    padding-top: 3px !important;
    padding-left: 8px !important;
  }
}
.textDropDown {
  white-space: nowrap;
}
