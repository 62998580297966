.public-appointment-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  & .public-appointment__inputs-block .MuiTextField-root {
    min-width: 150px;
    width: unset;
  }
  @media all and (min-width: 1024px) {
    width: 700px;
  }

  @media all and (min-width: 768px) and (max-width: 1024px) {
    width: 750px;
  }

  @media all and (min-width: 480px) and (max-width: 768px) {
    width: 450px;
  }

  @media all and (max-width: 480px) {
    width: 300px;
  }

  .title {
    text-align: center;
    margin-bottom: 0;
  }

  .company-sub-title {
    text-align: center;
    margin-bottom: 0;
  }

  .company-logo {
    object-fit: contain;
    width: 200px;
    height: 200px;
    border-radius: 8px;
  }

  .public-appointment-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    & .public-appointment__inputs-block {
      display: flex;
      gap: 20px;
      align-items: center;
      // justify-content: start;
      flex-wrap: wrap;
      & .custom_time-picker {
        min-width: 150px;
      }
      & div {
        & .label {
          font-size: 12px;
        }
        & .MuiFormHelperText-contained.Mui-error {
          font-size: 10px;
          margin: 0;
        }
      }
    }

    @media all and (min-width: 1024px) {
      width: 700px;
      & .property-search__table {
        max-width: 700px;
      }
    }

    @media all and (min-width: 768px) and (max-width: 1024px) {
      width: 700px;
      & .property-search__table {
        max-width: 700px;
      }
    }

    @media all and (min-width: 480px) and (max-width: 768px) {
      width: 450px;
      & .property-search__table {
        max-width: 450px;
      }
    }

    @media all and (max-width: 480px) {
      width: 300px;
      & .property-search__table {
        max-width: 300px;
      }
    }

    form {
      width: inherit;
      .contact-block {
        margin-bottom: 20px;

        .personal-information {
          .full-name-input {
            width: 100%;

            & > div {
              width: 100%;
            }
          }

          .phone-row {
            width: 100%;

            & > div {
              width: 100%;
            }
          }

          .email-row {
            width: 100%;

            & > div {
              width: 100% !important;
            }
          }
        }
      }

      .address-block {
        .property-address {
          .custom-address-autocomplete-wrapper {
            margin-top: 0;
            margin-bottom: 1rem;
          }

          .address-fields > .address-row > .unit {
            margin-right: 0;
          }
        }
      }

      .btn-block {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;

        .btn {
          margin-top: 20px;
        }
      }
    }
  }
}

.success-letter {
  text-align: center;
  margin-bottom: 0;
}
.block {
  & .block-input_label {
    color: rgba(0, 0, 0, 0.54);
  }
}

.address-error > .address-fields > {
  .address-row:first-child {
    div {
      div {
        label {
          color: red;
        }
        div {
          fieldset {
            border-color: red;
          }
        }
      }
    }
  }
}
