%cropBlockSettings {
  .cropp-buttons-block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;

    button:first-child {
      color: #979797;
    }
    button:last-child {
      color: #4285f4;
    }
  }

  .company-drag-wrapper {
    width: 100%;
    height: 153px;
    margin-bottom: 1rem;
    position: relative;
  }

  .company-drag-logo {
    height: 228px;
    width: 228px;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .company-drag-logo-empty {
    display: block;
    padding: 0;
  }

  .drag-label-container {
    background: white;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(9px, -50%);
    z-index: 1;
    padding: 0 5px;
    height: 5px;
  }
  .drag-label {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 2px;
    letter-spacing: 0.00938em;
    color: #000000;
    opacity: 0.5;
    font-size: 12px;
    transition: color 0.3s, opacity 0.3;
  }

  .company-drag-wrapper:hover:not(.error) {
    .drag-label {
      opacity: 1;
      color: #4285f4;
    }
  }

  .ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }

  .ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #ffffff;
    background-color: #ffffff;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
  }

  .ant-upload.ant-upload-drag:hover,
  .ant-upload.ant-upload-drag-hover {
    border: 1px solid #4285f4;
    border-color: #4285f4;
    background-color: #4c53ef0d;
    background: #4c53ef0d;
  }

  .error {
    .ant-upload.ant-upload-drag,
    .ant-upload.ant-upload-drag:hover {
      border: 1px solid #f44336;
    }
    .drag-label {
      color: #f44336;
      opacity: 1;
    }
  }

  .ant-upload-drag-container {
    span {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;

      color: #dadada;
    }
  }
}
