.tickets-first-column__container {
  position: relative;

  .updates-ticket__flag {
    position: absolute;
    top: -20px;
    right: 0;
    font-size: 10px;
    background-color: #05c705;
    color: #fff;
    padding: 1px 8px;
    border-radius: 150px;
  }
}
