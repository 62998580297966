/* CREATE USER MODAL */
.firstRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.field {
  width: 32%;
  margin-bottom: 23px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  flex-wrap: wrap;
}
.fieldFull {
  width: 100%;
  margin-bottom: 23px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  flex-wrap: wrap;
}

.secondRow {
  width: 64%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fieldSecond {
  width: 50%;
  margin-bottom: 23px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.thirdRow {
  width: 66%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.fieldThird {
  width: 48.6%;
  margin-bottom: 23px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.fourthRow {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.buttonsRow {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 27px;
}

.cancelBtn {
  color: #000000;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 11px 22px;
  background-color: #ffffff;
}

.submitBtn {
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 11px 22px;
  background-color: #4285f4;
  margin-left: 38px;
}

.placeholder {
  padding-bottom: 11px;
}

.CheckBox {
  margin-bottom: 23px;
}

.CheckBoxPermission {
  margin-bottom: 16px;
}

.CheckBoxText {
  color: #000000;
  padding-left: 16px;
}

/* EDIT MODAL */

.resendBtn {
  position: relative;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  padding: 11px 22px;
  background-color: #4285f4;
}

.resendBtn[disabled] {
  cursor: default;
  background-color: #858af1;
}

.CheckBoxblock {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Files {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
