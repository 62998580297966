.table-time-list {
  thead {
    tr > th {
      border: none;
    }

    tr > th:nth-child(5) {
      width: 100px;
      min-width: 100px;
      max-width: 100px;
    }
  }
  tbody {
    tr {
      td {
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 200px;
        border-bottom: 1px solid rgb(215, 215, 215);
        & > div {
          position: relative;
        }
      }

      &.error {
        td {
          .ant-time-picker {
            input {
              border-color: red;
            }
          }
        }
      }
    }
    tr:nth-child(1) > td {
      // border: none;
    }
    tr:nth-child(even) > td {
      // border-bottom: none;
    }
    tr:nth-child(odd) > td {
      // border-top: none;
    }
    tr > td:nth-child(4) {
      position: relative;
    }
    tr > td:nth-child(5) {
      position: relative;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
      tr > td:nth-child(5) {
        border: none;
      }
      tr:nth-last-child(n + 2) > td:nth-child(4) {
        span {
          display: none;
        }
      }
    }
  }
}

.timeChanger {
  padding: 0;
  border-bottom: none;
  & > div {
    width: 450px;
    height: 100px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  }
}
@media (max-width: 1200px) {
  .table-time-list {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    min-width: 90%;
    overflow-x: auto;
    padding-bottom: 20px;
    td {
      min-width: 200px;
    }
    th {
      min-width: 200px;
    }
  }
}

@media (max-width: 499px) {
  .time-card-table-wrapper {
    padding: 5px !important;

    table.table-time-list {
      min-width: 100%;
      font-size: 8px;
      margin-bottom: 0;

      & > thead > tr > th,
      & > tbody > tr > td {
        width: 90px;
        min-width: 50px;
        max-width: 90px;
      }

      & > tbody > tr {
        &:nth-last-of-type(2) {
          & > td:nth-child(4) {
            span {
              display: none;
            }
          }
        }
      }

      .timeChanger {
        & > div {
          width: initial;
          height: initial;
          flex-wrap: wrap;
          justify-content: center !important;
          padding: 10px;

          .pickers {
            .timepicker-wrapper {
              width: 40%;
              margin: 0;
            }
          }
          .control-buttons {
            width: 200px;
            flex-direction: row !important;
            justify-content: space-between !important;
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
