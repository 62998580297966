.queue-row {
  & .popover-children__wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 10;
  }
  &__heading {
    margin-bottom: 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    .icon {
      width: 26px;
      height: 26px;

      svg {
        width: 100%;
        height: auto;
      }
    }

    .title {
      font-weight: 700;
    }

    .counter {
      position: relative;
      width: 15px;
      height: 15px;
      background: orange;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      top: -6px;
      margin-left: 3px;
      font-size: 8px;
      line-height: 1;
    }

    i {
      font-size: 1.2rem;
    }
  }

  &__event_list {
    display: flex;
    gap: 5px;

    .queue-row__box {
      flex: 0 0 60px;
      height: 40px;
      border-radius: 4px;
      background-color: white;
      box-shadow: 3px 0 5px 0px rgba(236, 236, 241, 0.84);
      overflow: hidden;
    }
  }

  @media (max-width: 500px) {
    &__heading {
      .icon {
        width: 15px;
      }

      .title {
        font-size: 0.8rem;
      }
    }
  }
}
