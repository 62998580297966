.merge-title {
  font-size: 1.2rem;
  font-weight: bold;
}
.merge-icon {
  font-size: 1rem;
  font-weight: bold;
}
.from,
.to {
  display: flex;
  justify-content: center;
  font-size: 20px;
  .choosen-item {
    margin-right: 5px;
    font-weight: 500;
    color: #4285f4;
  }
}

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // box-shadow: 3px 3px 6px 1px rgba(140, 140, 140, 0.79), -3px -3px 6px 1px rgba(140, 140, 140, 0.79);
  //background-color: rgb(197, 197, 197);
  max-width: 255px;
  margin: 0 auto;
  font-size: 20px;
  margin-top: 20px;
  padding: 10px 20px;
  //
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 4px;
  background: #f2f3fe;
  div {
    text-align: center;
  }
  .choosen-item {
    font-weight: 500;
    color: #4285f4;
  }
}

.reverse-btn {
  display: flex;
  justify-content: center;
  i {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
    border: 1px solid #4285f4;
    background-color: #4285f4;
    color: white;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 4px;
  }
  i:active {
    background-color: #2930d3;
  }
}

.merge-modal {
  max-width: 650px;
  color: #191919;

  .modal-body {
    font-weight: 500;
  }
}

.merge-modal {
  .modal-header {
    border: none;
    padding-bottom: 0;

    & > :not(button) {
      display: none;
    }
  }
}

.modal-footer {
  border: none;
  justify-content: center;
  margin: 10px 0 40px;
}
