.proposal-modules {
  display: flex;
  gap: 10px;
  // justify-content: space-between;
  // width: 250px;

  &__module {
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    text-align: center;

    & > p {
      line-height: 1;
      margin-top: 5px;
    }

    &.pending,
    &.overdue {
      path {
        fill: #e53655;
      }
    }
    &.completed {
      path {
        fill: #2ebda3;
      }
    }
    &.default {
      path {
        fill: #191919;
      }
    }
    &.ordered,
    &.due {
      path {
        fill: #4285f4;
      }
    }
    &.partially {
      path {
        fill: #ffc000;
      }
    }

    &.disabled {
      opacity: 0.5;
      // pointer-events: none;
    }
  }
}
