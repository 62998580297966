.modal-pdf {
  width: 100vw;
  height: 100vh;
  min-width: 30cm;
}

.pdf-logo {
  width: 700px;
  display: block;
  margin: 0 auto;
}

.pdf-wrapper-print {
  // position: fixed;
  // top: 0;
  // transform: scale(1);

  // left: 300%;
  margin-left: 300%;
  padding: 20px 10px;
  width: 30cm;
  height: 100%;

  textarea {
    overflow: hidden;
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & #phone {
    border: 2px solid transparent;
    border-radius: 3px;
    padding: 4px !important;
    transition: all 0.3s;
    font-size: 30px;
    height: 40px;
    font-weight: bold;

    &:focus {
      border-color: rgba(64, 169, 255, 0.2);
      border-width: 2px !important;
      outline: 0;
      transition: all 0.3s;
    }
  }
  & .pdf-image__wrapper {
    margin: 0 0 10px 0;
  }
  & .company-contact {
    font-weight: bold;
    text-align: center;
    font-size: 18px;
  }

  & .company-contact__series {
    text-align: right;
    font-weight: bold;
    margin-right: 280px;
  }

  & table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 6px;
    border: 2px solid black;
    font-size: 0;
  }
  & table,
  td,
  th {
    border: 1px solid #595959;
    font-size: 18px;
  }
  td {
    // font-weight: bold;
  }
  & td,
  th {
    padding: 3px;
    font-size: 18px;
    height: 26px;
  }
  & th {
    text-align: center;
    vertical-align: center;
  }

  .heading-table tr {
    height: 74px !important;
  }

  .heading-table td:first-child {
    width: 70%;
  }

  & .description {
    font-weight: bold;
    text-align: center;
    font-size: 20px;
  }

  & .bottom-table {
    display: grid;
    grid-template-columns: 500px 500px;
    justify-content: space-between;
  }

  & .table-head__text {
    font-weight: bold;
    text-align: center;
    font-size: 40px;
    padding-bottom: 30px;
  }

  .bigest__size {
    font-size: 28px !important;
  }

  .semi_bigest__size {
    font-size: 26px !important;
  }
  // .bigest__height {
  //   height: 42px !important;
  // }

  & .table-head {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: -40px;
  }

  & .table-head tr {
    // height: 40px;
    & td {
      border: 2px solid black;
    }
  }
  & table th {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
  }

  & .bottom-table table {
    margin-bottom: 0;
  }
  & table {
    border: 2px solid black;
  }

  & .textarea_pdf {
    // height: 33px;
    resize: none;
    color: black;
    font-weight: bold;
    border: none;
    outline: none;
    font-size: 16px;
  }
  & .pdf-field {
    // height: 30px;
    // max-height: 30px;
    display: flex;
  }

  & .center {
    text-align: center;
  }

  & .bold {
    font-weight: bold;
  }

  & .customWidth {
    width: 38%;
  }
  & .widthFull {
    width: 100%;
  }

  & .left-table table tr td:last-child {
    width: 20%;
  }
  & .right-table table tr td:last-child {
    width: 20%;
  }

  & .intable td:first-child {
    width: 100px;
  }
  & .intable td:last-child {
    width: 300px;
  }
  .work_break_wraper tr {
    height: 50px !important;
  }

  & .intable .textarea_pdf {
    border: none !important;
  }
  & .date-table {
    // width: 300px;
    // margin-left: auto;
  }
  & .error_field {
    border: 1px solid red !important;
    outline: 1px solid red !important;
  }
}

.work_break_wraper {
  display: flex;
  & .lastTable {
    & tr,
    td {
      width: 100%;
      height: 21px;
      line-height: 0;
    }
  }
}
.work_break_wraper tr {
  height: 50px;
}

.customHeight {
  height: 50px !important;
}

.size16 {
  font-size: 14px !important;
}

.borderRightNone {
  border-right: none !important;
}
.borderBottomNone {
  border-bottom: none !important;
}
