.emails-list-wrapper {
  .email-box {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 1rem;
    border-bottom: 0.3px solid #19191921;
    padding: 15px 0;

    @media (max-width: 749px) {
      flex-wrap: wrap;
    }
    .left-box {
      display: flex;
      flex-direction: column;
      width: 175px;

      .user-name {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-align: left;
      }

      .user-relation {
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0.01em;
        text-align: left;
      }
    }

    .right-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      .top-box {
        .top-row {
          margin-bottom: 5px;
          .user-email {
            color: #3f57ac;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.01em;
            text-align: left;
          }
        }
      }

      .add-client {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        & > .add-client-input {
          height: 25px;
          width: 300px;

          & > div {
            height: 25px;
          }
        }

        .add-client-btn {
          width: 120px;
          font-size: 12px;
          height: 25px;
        }

        .cancel-btn {
          font-size: 12px;
          height: 25px;
        }
      }

      .add-email-text {
        color: #000000;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0.01em;
        text-align: left;
        text-decoration-line: underline;
        cursor: pointer;

        &.deleted {
          text-decoration-line: none;
          cursor: not-allowed;
          color: red;
        }
      }
    }

    &:first-child {
      padding-top: 0;
    }
  }
}
