.orcatec-input {
  .MuiInputBase-root {
    border-radius: 2px;
  }

  input {
    &:disabled {
      background-color: #e0dcdc;
      opacity: 0.5;
    }
  }

  fieldset {
    border-color: #d9d9d9;
  }
}
