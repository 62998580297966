.modal-proposal-order-equipment {
  fieldset {
    margin-bottom: 20px;

    label {
      margin: 0;

      .input-check {
        padding: 4px;
      }
    }
  }
}

.order-equipment-modal {
  .distributors {
    display: flex;
    flex-direction: column;
  }
  .ant-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .anticon:hover {
      cursor: pointer;
    }
  }
  .email-history {
    height: 400px;
    overflow-y: auto;

    .card-wrapper {
      background: #ececec;
      margin-bottom: 10px;
      padding: 10px;
      .ant-card-body {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .ant-card-head-title {
        padding: 5px 0 5px 0;
      }
    }
  }

  & .ant-checkbox-checked .ant-checkbox-inner::after {
    display: block;
  }
}

.email-content__open {
  display: block;
  padding: 10px;
}
.email-content__close {
  display: none;
}

.input-email_wrapper {
  display: flex;
  margin-top: 20px;
  gap: 10px;
  align-items: center;
}

.notes-btn__customStyle {
  position: absolute;
  left: 127px;
  bottom: -34px;
}
