.module-statuses {
  padding: 0;
  list-style-type: none;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 40px;

  .status {
    cursor: pointer;
    padding: 2px 8px;

    &:nth-child(1) {
      color: #2c2c2c;
      &.active,
      &:hover {
        background-color: #2c2c2c26;
      }
    }
    &:nth-child(2) {
      color: #f5222d;
      &.active,
      &:hover {
        background-color: #f5222d26;
      }
    }
    &:nth-child(3) {
      color: #4285f4;
      &.active,
      &:hover {
        background-color: #4c53ef26;
      }
    }
    &:nth-child(4) {
      color: #ffc000;
      &.active,
      &:hover {
        background-color: #ffc00026;
      }
    }
    &:nth-child(5) {
      color: #2ebda3;
      &.active,
      &:hover {
        background-color: #2ebda326;
      }
    }

    &:not(:last-child) {
      margin-right: 20px;
      position: relative;

      &::after {
        content: '>';
        position: absolute;
        color: #000;
        pointer-events: none;
        right: -15px;
        top: 3px;
        font-size: 20px;
      }
    }
  }

  &.read-only,
  &.module-payment {
    .status {
      pointer-events: none;
    }
  }

  &.module-payment {
    .status {
      cursor: initial;
      // pointer-events: none;

      &:nth-child(2) {
        color: #4285f4;
        &.active,
        &:hover {
          background-color: #4c53ef26;
        }
      }
      &:nth-child(3) {
        color: #f5222d;
        &.active,
        &:hover {
          background-color: #f5222d26;
        }
      }
      &:nth-child(4) {
        color: #2ebda3;
        &.active,
        &:hover {
          background-color: #2ebda326;
        }
      }
    }
  }

  @media (max-width: 499px) {
    font-size: 7px;
    line-height: 10px;
    margin-bottom: 20px;

    .status {
      padding: 1px 5px;

      &:not(:last-child) {
        margin-right: 10px;
        &::after {
          right: -8px;
          top: 1px;
          font-size: 12px;
        }
      }
    }
  }
}
