.timezone-picker {
  .ant-select-selection {
    height: 30px;
    &__rendered {
      line-height: 29px;
    }
  }
}

@media (max-width: 499px) {
  .timezone-picker {
    .ant-select-selection {
      height: 20px;
      font-size: 11px;
    }
    .ant-select-selection__rendered {
      line-height: 18px;
    }
  }
}
