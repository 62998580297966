.proposal-form-tabs_content-info_block-notes_and_modules {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;

  @media (min-width: 320px) and (max-width: 425px) {
    flex-direction: column;
  }
  & > div:first-child {
    flex: 1;
    min-width: 200px;
    @media (min-width: 320px) and (max-width: 425px) {
      width: 100%;
    }
  }
  .proposal-form-tabs_content-tab_action_block {
    margin: 0;
    width: 320px;
  }

  @media (max-width: 499px) {
    align-items: center;

    //& > div:first-child {
    //min-width: auto;
    //}

    .MuiTextField-root {
      & > label {
        font-size: 8px;
      }
      .MuiInputBase-root {
        font-size: 8px;
        line-height: 10px;
        padding: 10px;
      }

      .MuiSelect-iconOutlined {
        top: 0;
      }
    }

    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      transform: translate(14px, 7px) scale(1);
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      transform: translate(14px, -3px) scale(0.75);
    }

    .proposal-form-tabs_content-tab_action_block {
      width: auto;

      &-button {
        font-size: 6px;
        height: 25px;
        margin-left: 4px;
      }

      & > div {
        svg {
          width: 20px;
          height: 18px;
        }
      }
    }
  }
}
