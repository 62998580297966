.tags-wrapper {
  width: 100%;
  /* .MuiTextField-root {
    margin-bottom: 12px !important;
  } */
  &-list {
    display: flex;
    flex-wrap: wrap;
    max-height: 100px;
    overflow-y: auto;
    // margin: -5px -5px 11px -5px;
    &-item {
      background-color: #4c53ef12;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      padding: 10px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #4285f4;
      height: 30px;
      margin: 0 5px 5px 0;
      border-radius: 4px;
      span {
        margin-right: 10px;
      }
      i {
        cursor: pointer;
        font-size: 14px;
      }
      i:before {
        font-weight: 600;
      }
    }
  }

  @media (max-width: 499px) {
    .tags-wrapper-list {
      margin-bottom: 5px;

      .tags-wrapper-list-item {
        padding: 5px;
        height: 20px;
        font-size: 8px;
        margin: 0 5px 5px 5px;
        line-height: 1;

        span {
          margin-right: 5px;
        }

        i {
          font-size: 12px;
        }
      }
    }
  }
}
