.timeChangerDay > div {
  margin-top: 10px;
}
.timeChangerDay {
  border-radius: 5px;
  box-shadow: 0 0 0 2px white, 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  width: 450px;
  height: 100px;
  .buttonDiv {
    justify-content: space-around;
    padding-right: 10px;
    margin: 0;
  }
}
.timepicker-wrapper {
  margin-top: 22px;
  width: 125px;
  margin-left: 20px;
  height: 54px;
}

.modal-wrapper {
  & > button {
    margin: 50px 0 0 auto;
  }
  .common-table-actions {
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .modal-wrapper {
    h4 {
      margin-left: 20px;
      font-size: 14px;
    }
  }
}

@media (max-width: 499px) {
  .add-new-day-table {
    display: none !important;
  }
  .timeChangerDay {
    width: 100%;
    height: 75%;
    flex-direction: column;
    align-items: center;
    .add-day-inputs {
      flex-direction: column;
      .align-self-center {
        width: 100%;
        margin-top: 7px;
      }
    }
    .buttonDiv {
      width: 100%;
      padding: 0;
      display: flex !important;
      flex-direction: row !important;
      height: 32px;
      margin-bottom: 7px;
      .btn-danger {
        width: 35%;
      }
    }
  }
  .modal-wrapper {
    & > button {
      margin: 10px auto;
    }
    h4 {
      margin-left: 20px;
      font-size: 10px;
    }
  }
}
