.modal-container {
    display: none;
}


.modal-container__active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1001;
    margin: 0 !important;

    & .modal-container__inner {
        width: 100%;
        height: 100%;

        & .wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            background-color: rgb(82, 86, 89);
        }
    }

    & .has-text-danger {
        display: none !important;
    }

    & .modal-header {
        align-items: center;
        background-color: rgb(50, 54, 57);
        color: white;
        display: flex;
        height: 40px;
        padding: 0 16px;

        & .title-wrapper {
            display: flex;
            align-items: center;
            gap: 20px;
        }

        & .page-controls__wrapper {
            display: flex;
            align-items: center;
        }

        & .pageNum__wrapper {
            display: flex;
            align-items: center;
            gap: 20;
        }

        & .page-controls__center {
            display: flex;
            align-items: center;
        }


        & .page-controls__wrapper input {
            background: rgba(0, 0, 0, 0.5);
            border: none;
            color: white;
            font-family: inherit;
            line-height: inherit;
            outline: none;
            text-align: center;
            width: calc(max(2, 5) * 1ch + 1px)
        }

        & button {
            position: relative;
            background-color: transparent;
            border: none;
            outline: none;
        }
    }

    & .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: scroll;
        width: 100%;
        height: 100%;
        padding: 0 !important;


    }

}





.modal-sidebar {
    height: 100vh;
    transition: all 0.4s;
    overflow: scroll;
    background-color: rgba(0, 0, 0, 0.9);
    & .modal-sidebar__inner{
        padding:  20px 0 20px 10px;
    }
    & .sidebar-image_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        padding: 20px;
        margin-bottom: 20px;
        border: 2px solid transparent;
    }
}


.modal-sidebar__active {
    max-width: 160px;
    width: 100%;
    transition: all 0.4s;

}

.modal-sidebar__noactive {
    width: 100%;
    max-width: 0;
    transition: all 0.4s;
    & .modal-sidebar__inner{
        display: none;
    }

}
.doc__active {
 border: 2px solid var(--primary) !important;
}

.image-created__by{
    color: white;
    font-size: 10px;
    font-family: inherit;
    opacity: 0.7;
    text-align: center;
    word-break: break-word;
}

.image-name{
    color: white;
    font-size: 10px;
    font-family: inherit;
    opacity: 0.7;
    text-align: center;
}



.image-backgound{
    cursor: pointer;
    margin-bottom: 4px;
}

.iframe-sidebar-controls{
    width: 50px;
    background-color: #323639;
    height: 57px;
    align-self: baseline;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    position: absolute;
    top: 0;
    width: 100%;
    & button {
        background-color: transparent;
        border: none;
        outline: none;
        color: white;
    }

  }

