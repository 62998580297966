/* .matrix-settings {
  margin-bottom: 200px;
  @media (min-width: 986px) {
    width: calc(100vw - 80px);
  }
  @media (max-width: 986px) {
    width: 100vw;
  }
  transition: 0.5s;
  .body {
    display: flex;
    flex-direction: row;
    margin: 20px 0px;
  }
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 100;
    border-radius: 20px;
    padding: 0 0.2rem;
    i {
      font-size: 14px;
    }
  }
}
.enlarged .matrix {
  width: 100vw;
} */
.settings-matrix-color-picker-close-icon {
  position: relative;
  i {
    color: red;
    position: absolute;
    bottom: -15px;
    right: -20px;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
.calendar-color__picker {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.matrix-settings {
  padding-top: 50px;
  margin-bottom: 40px;
  position: relative;
  // @media (min-width: 986px) {
  //   width: calc(100vw - 80px);
  // }
  // @media (max-width: 986px) {
  //   width: 100vw;
  // }
  transition: 0.5s;
  .body {
    display: flex;
    overflow-y: auto;
  }
  .preloader-matrix {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.6);
    user-select: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    max-width: fit-content;
    margin-bottom: 22px;
    @media (max-width: 800px) {
      justify-content: center;
      .d-flex-center {
        margin: 10px 0;
      }
    }
    .d-flex-center {
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;
    }

    .btn {
      height: 41px;
      margin: 0 15.5px;
    }
    .btn-arrow {
      width: 34px;
      height: 34px;
      color: black;
      background-color: #f7f7f7;
      border-radius: 20px;
      border: none;
      margin: 0 4px;
      &:hover {
        background-color: #eeeefc;
        color: #4d53ef;
      }
    }
    .counter {
      width: 15px;
      height: 15px;
      position: absolute;
      background: orange;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      right: -6px;
      top: -6px;
      font-size: 8px;
    }
    .icons {
      display: flex;
      justify-content: space-around;
      .icon {
        transform: none;
        height: 40px;
      }
      i {
        font-size: 14px;
      }
      p {
        font-size: 10px;
      }
      .active {
        i {
          color: #38a4f8;
        }
      }
      div {
        text-align: center;
        cursor: pointer;
        &:hover {
          i {
            color: #4d53ef;
          }
        }
      }
    }
    .calendar-header {
      display: flex;
      justify-content: center;
      min-width: 350px;
      margin: 10px 10px;
      position: relative;
    }
    .mdi-calendar-multiselect {
      font-size: 25px;
      top: 10px;
    }
    .group {
      min-width: 74px;
      button {
        margin: 0px 2px;
      }
    }

    .today {
      margin-left: 15px;
      background-color: #f7f7f7;
      color: black;
      &:hover {
        background-color: #eeeefc;
        color: #4d53ef;
      }
    }
    .ant-calendar-picker {
      input {
        border: none;
        box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
      }
    }
  }
  /* .resource-title {
    height: 41px;
    text-align: center;
    box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
    width: 125px;
    padding: 5px;
    margin-bottom: 13.5px;
    display: flex;
    img {
      height: auto;
      width: 32px;
      flex: 0;
      object-fit: cover;
      border-radius: 14px;
    }
    .tech-text {
      flex: 1;
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .tech-title {
      font-size: 10px;
      width: 100%;
      font-weight: 900;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
    .tech-role {
      clear: both;
      font-size: 10px;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
  } */
  .time-container {
    margin: 78px 0px 0px;
    display: flex;
    flex-direction: column;
    .time {
      height: 40px;
      width: 30px;
      box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
      border-radius: 4px;
      margin: 2.5px;
      font-size: 10px;
      display: flex;
      font-weight: 700;
      // justify-content: center;
      flex-direction: column;
      text-align: center;
    }
  }
  .column-container {
    display: flex;
    .column {
      display: flex;
      flex-direction: column;
      margin: 0 2.5px;
      .playground {
        position: relative;
      }
    }
  }

  .box {
    height: 90px;
    width: 120px;
    margin: 2.5px 0;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.84);
  }
  .boxes {
    display: flex;
    flex-direction: column;
  }
  .prefered {
    border: 1px solid red;
  }
  /* .event {
    position: absolute;
    width: 120px;
    margin: 2.5px 0;
    min-height: 90px;
    border-radius: 4px;
    font-size: 10px;
    cursor: grab;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    pointer-events: visible;
    .draggable-event {
      height: 100%;
      display: flex;
      color: white;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    a {
      text-decoration: none;
      color: white;
    }
    button {
      height: 15px;
      position: absolute;
      line-height: 8px;
      border: none;
      background: none;
      color: white;
      font-size: 20px;
      letter-spacing: 4px;
      justify-content: center;
      z-index: 990;
      left: calc(50% - 10px);
      display: flex;
      .circle {
        height: 10px;
        width: 10px;
        background-color: white;
        opacity: 0;
        z-index: 400;
        border-radius: 90px;
      }
    }
    .fisrt {
      top: 0;
      z-index: 400;
    }
    .second {
      bottom: 0;
      z-index: 400;
    }
    &:hover {
      .circle {
        opacity: 1;
      }
    }
  } */
  .queue {
    margin-left: 37px;
    .queue-header {
      width: 120px;
      height: 52px;
      background-color: #2c1e80;
      color: white;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      border-radius: 22px;
      margin-bottom: 2.5px;
    }
    .matrix-popover {
      box-shadow: 0px 0px 1px rgba(255, 255, 255, 0.5);
    }

    .queue-column {
      width: 120px;
      display: flex;
      flex-direction: column;
      overflow-x: auto;
      overflow-x: hidden;
      .events {
        top: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
      }
      .queue-event {
        position: relative;
        width: 120px;
        overflow: hidden;
        margin: 2.5px 0;
        border-radius: 4px;
        height: 90px;
        .draggable-event {
          height: 100%;
          display: flex;
          color: white;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          display: flex;
          justify-content: center;
          font-size: 10px;
        }
      }
    }
  }
  .misc-column {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    .events {
      top: 0;
      position: absolute;
      display: flex;
      flex-direction: column;
      .queue-event {
        position: relative;
        overflow: hidden;
        width: 120px;
        margin: 2.5px 0;
        border-radius: 4px;
        height: 90px;
        .draggable-event {
          height: 100%;
          color: white;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          display: flex;
          font-size: 10px;
        }
      }
    }
  }
  .timeoff {
    .timeoff-event {
      width: 120px;
      margin: 2.5px 0;
      border-radius: 4px;
      font-size: 12px;
      padding-top: 10%;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      display: flex;
    }
  }
  .in_process {
    width: 100%;
    border-bottom: 2px solid white;
    transform: rotateZ(35deg);
    top: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .completed {
    width: 100%;
    border-bottom: 2px solid green;
    transform: rotateZ(35deg);
    top: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .denied_1 {
    width: 100%;
    border-bottom: 2px solid red;
    transform: rotateZ(35deg);
    top: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .denied_2 {
    width: 100%;
    border-bottom: 2px solid red;
    transform: rotateZ(-35deg);
    top: 50%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.date-modal {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    p {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
}

.modal-sure-matrix {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    p {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
}

.select-time-modal {
  .modal-header {
    border-bottom: none;
  }
  .modal-body {
    p {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
    }
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
}
