.appointment-section__body_toPrint {
  display: none;
  & .job_article__name {
    font-size: 20px;
  }
  & .job_article__value {
    font-size: 22px;
    font-weight: bold;
    gap: 10px;
  }
  & .job-time_wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
}
.visible_for-print {
  display: none;
}
.print_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
// #printWindow {
//   top: 0 !important;
//   left: 0 !important;
//   width: 100%;
//   height: 100%;
//   z-index: 9999999;
// }
