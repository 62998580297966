.remainder-module-modal {
  /* width: 840px;
      max-width: 840px;
      @media (max-width: 860px) {
        width: 100%;
        max-width: 100%;
      } */
  max-width: 650px;
  .modal-body {
    .remainder-module-wrapper {
      width: 100%;
      & > div {
        margin-bottom: 1rem;
      }
      &-title {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        text-align: center;
        color: #191919;
      }
      &-comments {
        margin: 26px 0;
      }
    }
  }

  @media (max-width: 499px) {
    .modal-body {
      padding-left: 10px;
      padding-right: 10px;

      .remainder-module-wrapper {
        &-title {
          font-size: 16px;
        }
      }
    }
  }
}
