.proposal-contact-info {
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 5px;
  margin-bottom: 10px;
  position: relative;

  &__title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 15px;

    &-wrap {
      display: flex;
    }

    &-title {
      position: absolute;
      top: -9px;
      background-color: #fff;
      font-size: 1rem;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      transform: scale(0.75);
      padding: 0 12px;
      // font-family: Poppins;
      // font-style: normal;
      // font-weight: 600;
      // font-size: 22px;
      // line-height: 33px;
      // letter-spacing: 0.01em;
      color: #191919;
      margin: 0;
    }

    &-button {
      padding-left: 18px;
      margin-right: 35px;

      &--disabled {
        opacity: 0.65;
        pointer-events: none;
      }
    }
    &-qty {
      display: flex;
      align-items: center;
      margin-left: 35px;
      &:first-child {
        margin-left: 0;
      }
      &-container {
        display: flex;
        align-items: center;
        padding-left: 15px;
        &--small {
          display: none;
          .proposal-contact-info__title-qty-count {
            width: 20px;
            height: 20px;
            font-size: 8px;
            margin-left: 5px;
          }
        }
      }
      &-count {
        margin-left: 10px;
        display: flex;
        align-items: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 30px;
        font-weight: 500;
        font-size: 12px;
        color: #191919;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.5);
      }
    }
    &-add-btn {
      display: flex;
      margin-left: 42px;

      button {
        &.disabled {
          pointer-events: none;
        }
      }
    }
  }
  &__select {
    .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input {
      padding-top: 5.5px;
      padding-bottom: 5.5px;
    }
    &-text {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.01em;
      color: rgba(0, 0, 0, 0.5);
      margin-right: 9px;
    }
    padding-left: 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 1520px) {
  .proposal-contact-info {
    &__title {
      &-button {
        margin-right: 15px;
      }
      &-qty {
        margin-left: 15px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 1380px) {
  .proposal-contact-info {
    &__title {
      &-add-btn {
        margin-left: 10px;
        button {
          font-size: 11px;
        }
      }
    }
    &__select {
      button {
        font-size: 11px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .proposal-contact-info {
    &__title {
      &-title {
        font-size: 18px;
      }
      &-add-btn {
        margin-left: 10px;
      }
      &-qty {
        margin-left: 15px;
      }
    }
  }
}

@media (max-width: 890px) {
  .proposal-contact-info {
    &__title {
      &-title {
        font-size: 14px;
      }
      &-button {
        padding-left: 10px;
        margin-right: 0;
        .custom-toggle-btn {
          width: 24px;
          height: 24px;
          min-height: 24px;
          max-height: 24px;
          min-width: 24px;
          max-width: 24px;
          font-size: 18px;
        }
      }
    }
  }
  .proposal-contact-info__title-qty-container {
    display: none;
    &--small {
      display: flex;
    }
  }
}

// @media (max-width: 768px) {
//   .proposal-contact-info {
//     margin-bottom: 0;
//   }
// }

@media (max-width: 596px) {
  .proposal-contact-info {
    padding-left: 3px;
    padding-right: 3px;
    &__title {
      padding-left: 0;
      &-qty {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 7px;
      }
    }
  }
}

@media (max-width: 435px) {
  .proposal-contact-info {
    &__title {
      &-add-btn {
        margin-left: 5px;

        button.my-button-class {
          font-size: 0.6rem;
        }
      }
      &-title {
        font-size: 11px;
      }
    }
  }

  .proposal-contact-info__title-qty-container {
    &--small {
      padding-left: 5px;

      .proposal-contact-info__title-qty-count {
        width: 14px;
        height: 14px;
      }

      svg {
        width: 12px;
      }
    }
  }
}

@media (max-width: 375px) {
  .proposal-contact-info {
    &__title {
      &-button {
        margin-right: 0;
      }
      &-add-btn {
        margin-left: 0;
      }
    }
  }
}

.proposal-contact-section {
  background-color: #fff;
  padding: 5px;
  // margin-top: 10px;
  &__title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    &-title {
      padding-left: 12px;
      // font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      letter-spacing: 0.01em;
      color: #191919;
      margin-right: 24px;
    }
    &-qty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      padding: 0 20px;
      font-weight: 500;
      font-size: 12px;
      color: #4285f4;
      background: #4c53ef14;
      border-radius: 4px;
      margin-right: 12.5px;
    }
    &-btn {
      margin: 0 24px;
      font-size: 26px;
      font-weight: 500;
      cursor: pointer;
      padding: 0 12.5px;
      &:hover {
        color: #4285f4;
      }
    }
  }
  &__tabs {
    margin-top: 18px;
    &-buttons {
      display: flex;
    }
    &-button {
      display: flex;
      background: #f2f3fe;
      border: 1px solid transparent;
      padding: 6px 12px;
      border-radius: 4px 4px 0px 0px;
      margin-right: 5px;
      cursor: pointer;
      &-title {
        text-transform: capitalize;
        // font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #191919;
      }
      &-counter {
        width: 30px;
        height: 20px;
        background-color: #fff;
        margin-left: 8px;
        font-weight: 500;
        font-size: 12px;
        text-align: center;
        line-height: 18px;
        color: #191919;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 4px;
      }
      &--active {
        background: #ffffff;
        border: 1px solid #cccccc;
        border-bottom: none;
        margin-bottom: -1px;
      }
      &--disabled {
        cursor: not-allowed;
      }
    }
    &-content {
      border: 1px solid #dadada;
      padding: 32px 27px 47px 27px;
    }
  }
  &__buttons {
    margin: 20px 0;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .proposal-contact-section {
    padding-right: 0;
    padding-left: 0;
    padding-top: 0;
    &__title-title {
      font-size: 18px;
    }
  }
}

@media (max-width: 768px) {
  .proposal-contact-section {
    &__title-title {
      font-size: 14px;
      margin-right: 4px;
      padding-left: 2px;
    }
  }
}
