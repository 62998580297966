%eventLine {
  position: absolute;
  width: 146px;
  height: 0;
  top: 50%;
  left: 0%;
  border-bottom: 1px solid #ffffff;
  transform: translate(-50%, -50%) rotateZ(36.87deg);
}

.matrix-settings {
  .event {
    border-radius: 4px;
    position: relative;
    &-content {
      // width: 100%;
      // height: 100%;
      padding: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      user-select: none;
      font-style: normal;
      font-weight: 500;
      font-size: 6px;
      // line-height: 15px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #ffffff;
      // span:first-child {
      //   font-size: 12px;
      //   line-height: 18px;
      // }
      // span:last-child {
      //   margin-top: auto;
      // }
    }

    /* input {
      width: 100px;
      padding: 2px;
      height: 100%;
      border: none;
    } */
  }
  .in_process {
    @extend %eventLine;
  }
  .completed {
    @extend %eventLine;
    border-bottom: 1px solid #34a70b;
  }
  .denied_1 {
    @extend %eventLine;
    border-bottom: 1px solid #ff7070;
  }
  .denied_2 {
    @extend %eventLine;
    border-bottom: 1px solid #ff7070;
    transform: translate(-50%, -50%) rotateZ(-36.87deg);
  }
}
