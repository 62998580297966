.Header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  z-index: 9;
}

/* .UserModal {
  width: 840px;
  max-width: 840px;
} */

.UserBlock {
  width: 100%;
  border-bottom: 1px solid #d6d6d6;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #ffffff;
  padding-right: 25px;
}

.UserTools {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Name {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-left: 75px;
}

.Button {
  border: none;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.DropDownBlock {
  position: relative;
}

.DropDown {
  box-shadow: 0px 0px 17px -9px rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 35px;
  z-index: 10;
}

.DropDownBtn {
  cursor: pointer;
  border: none;
  padding: 8px 16px;
}

.DropDownBtn:hover {
  color: #4285f4;
}
