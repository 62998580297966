$borderRadius: 4px;
$wrapperPadding: 10px;
%rowHoverStyle {
  background-color: rgba(196, 196, 196, 0.15);
  transition: all 0.3s;
  cursor: pointer;
}
.custom-address-autocomplete-dropdown-table-wrapper {
  background: #ffffff;
  border-radius: $borderRadius $borderRadius 0px 0px;
  padding: $wrapperPadding;
  //max-height: 260px;
  //overflow-y: auto;
  //overflow-x: hidden;
  &-no_table-row {
    padding: 5px 2.5px;
    width: 100%;
    &:hover {
      @extend %rowHoverStyle;
    }
  }

  table.custom-address-autocomplete-dropdown-table-wrapper-table {
    width: 100%;
    min-width: 500px;
    font-style: normal;
    letter-spacing: 0.01em;
    color: #191919;
    thead {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      tr > th {
        padding: 0 2.5px;
        white-space: nowrap;
      }
      tr > th:nth-child(3) {
        width: 60px;
      }
      tr > th:nth-child(4) {
        text-align: center;
        width: 50px;
      }
      tr > th:nth-child(5) {
        text-align: center;
        width: 140px;
        max-width: 140px;
        min-width: 140px;
      }
    }
    tbody {
      tr:hover {
        @extend %rowHoverStyle;
      }
      tr.no-postcode {
        background-color: rgba(196, 196, 196, 0.15);
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
      }
      tr > td {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        padding: 5px 2.5px;
      }

      tr > td:nth-child(2) {
        max-width: max-content;
      }
      tr > td:nth-child(3) {
        width: 60px;
      }

      tr > td:nth-child(4) {
        text-align: center;
        width: 50px;
      }
      tr > td:nth-child(5),
      tr > td:nth-child(6),
      tr > td:nth-child(7) {
        vertical-align: middle;
        color: rgba(0, 0, 0, 0.5);
        width: 40px;
        max-width: 45px;
        min-width: 40px;
        padding-right: 0;
        padding-left: 0;
        i {
          font-size: 24px;

          @media (max-width: 499px) {
            &:before {
              color: #4285f4;
            }
          }
        }
      }
    }
  }

  @media (max-width: 499px) {
    padding: 5px;
    table.custom-address-autocomplete-dropdown-table-wrapper-table {
      min-width: 100%;

      & > thead > tr > th {
        font-size: 6px;
        &:nth-child(1) {
          min-width: 80px;
          max-width: 80px;
          width: 80px;
        }
      }
      & > tbody > tr > td {
        font-size: 8px;
        line-height: 10px;

        &:nth-child(1) {
          min-width: 60px;
          max-width: 60px;
          width: 60px;
        }
      }
    }
  }
}

.custom-address-autocomplete-dropdown-no_table-wrapper {
  padding: 5px 0;
  .custom-address-autocomplete-dropdown-table-wrapper-no_table-row {
    padding: 5px 14.5px;
  }
}
