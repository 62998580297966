.notification-modal-wrapper {
  .ant-modal {
    .ant-modal-content {
      position: relative;

      .ant-modal-header {
        .ant-modal-title {
          text-align: center;
          font-size: 24px;
        }
      }

      .ant-modal-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .loading {
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
        }

        .checkbox-rows {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .left-block {
            .checkbox-row {
              .checkbox {
                .checkbox-text {
                  &.uppercase {
                    text-transform: uppercase;
                  }
                }
              }
            }
          }
          .right-block {
          }
        }

        .ant-collapse
          > .ant-collapse-item
          > .ant-collapse-content
          > .ant-collapse-content-box {
          .email-inputs-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }

          .sms-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
          }

          .automatic-follow-up-block {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            & > .ant-calendar-picker,
            .ant-calendar-picker-large {
              width: 49% !important;
            }
            & > .time-picker {
              width: 49% !important;
            }
          }
        }

        .other-email-row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          //padding: 15px 0;
          gap: 5px;
          margin-bottom: 20px;

          .other-email-text {
            padding-top: 4px;
            width: 225px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.01em;
            text-align: left;
          }

          & > .checkbox {
            width: 16px;
            height: 16px;
          }

          .other-email-input {
            width: 100%;
            & > div {
              height: 25px;
            }
          }
        }

        .subject-input {
          & > div {
            height: 25px;
          }
        }

        .automatic-follow-up-block {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          & > .ant-calendar-picker,
          .ant-calendar-picker-large {
            width: 49% !important;
          }
          & > .time-picker {
            width: 49% !important;
          }
        }

        .buttons-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }

  .round {
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 4px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 10px;
  }

  .round input[type='checkbox'] {
    visibility: hidden;
  }

  .round input[type='checkbox']:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  .round input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
}

.confirm-modal-wrapper {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-body {
        .buttons-row {
          margin-top: 1rem;
          display: flex;
          flex-direction: row;
          gap: 1rem;
          justify-content: space-between;
        }
      }
    }
  }
}
