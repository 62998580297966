.proposalStatus {
  vertical-align: middle;
  display: flex;
  align-items: center;
  white-space: nowrap;
  & > .spinner-border-sm {
    margin-right: 5px;
  }
  i {
    margin-right: 5px;
    font-size: 18px;

    @media (max-width: 499px) {
      font-size: 12px;
    }
  }
  .mdi-chart-donut {
    color: #2e5bff;
  }
  .mdi-progress-clock {
    color: #000000;
  }
  .mdi-check-circle {
    color: #30be89;
  }
  .mdi-cancel {
    color: #eb5757;
  }
  .mdi-snowflake {
    color: #dadada;
  }
}
