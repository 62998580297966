.appt-location__radio-group {
  display: flex !important;
  flex-direction: column;
  padding: 10px !important;
  & .menu-item {
    padding: 0;
    display: flex;
    align-items: center;
    padding: 4px;

    & p {
      margin-left: 6px;
    }
  }
}

.location-title {
  margin: 0 !important;
}

.empty-block {
  padding: 20px;
}
