.auth-layout-content {
  .card-page {
    display: flex;
    flex-direction: column;
    .buttons-block {
      margin-top: 50px;
      max-width: 258px;
      width: 258px;
      align-self: center;
    }
    .charged-text {
      margin-top: -9px;
      font-weight: 500;
      font-size: 17px;
      text-align: center;
      color: #000000;
      margin-bottom: 50px;
    }
  }
}
