
.proposal-form-tabs_content-tab_section-wrapper {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  background-color: #ffffff;
  margin-bottom: 10px;
}






