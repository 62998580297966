$backgroundGray: #dcdfe5;

.proposal-financing-tabs {
  display: flex;
  overflow-x: auto;

  .proposal-financing-tab {
    padding: 5px 10px;
    margin-right: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #fff;
    border: 1px solid #dcdfe5;
    cursor: pointer;
    flex: 0 1 240px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .tab-info {
      margin-bottom: auto;
      min-height: 32px;

      .tab-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        /* overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; */
      }

      .tab-description {
        margin: 5px 0 10px;
        font-size: 11px;

        p {
          line-height: 1.33;
          margin-bottom: 3px;
        }
      }

      .tab-image {
        max-height: 215px;
        max-width: 215px;
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
      }
    }

    .financing-calculations {
      flex: 1;
      margin: 5px 0 10px;

      .calculation_row {
        display: flex;
        justify-content: space-between;
        font-size: 10px;

        p {
          flex: 0 0 48%;
          line-height: 1.5;

          &:nth-child(1) {
            text-align: right;
          }
          &:nth-child(2) {
            font-weight: 600;
            align-self: flex-end;
          }
        }
      }
    }

    .financing-total {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      margin-top: auto;

      p {
        flex: 0 0 48%;
        line-height: 1.5;

        &:nth-child(1) {
          text-align: right;
        }
        &:nth-child(2) {
          font-weight: 600;
          align-self: flex-end;
        }
      }
    }

    .financing-conditions {
      text-align: center;
      margin-top: 10px;

      p {
        font-size: 10px;
        font-weight: 500;
        margin-bottom: 3px;
      }

      button {
        visibility: hidden;
        // display: none;
        margin: 0 auto;
        height: 20px;
        font-size: 12px;
      }
    }

    .tabs-buttons {
      position: relative;

      button {
        margin: 10px auto 5px;
        height: 20px;
        font-size: 12px;

        &.hide {
          visibility: hidden;
        }
      }

      label {
        position: absolute;
        right: -9px;
        top: 4px;
      }
    }

    & > *:not(.tabs-buttons) {
      opacity: 0.4;
    }

    &.active {
      opacity: 1;
      border-bottom: none;
      cursor: initial;

      .financing-conditions {
        button {
          visibility: initial;
        }
      }

      & > * {
        opacity: 1;
      }
    }

    &.all-active {
      opacity: 1;

      .financing-conditions {
        button {
          visibility: initial;
        }
      }

      & > * {
        opacity: 1;
      }
    }
  }

  .add-new-tab {
    align-self: flex-end;
    margin-bottom: 10px;
    min-width: 90px;
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #000;
  }

  .ant-radio {
    &::after {
      border: 1px solid #000;
    }

    .ant-radio-inner {
      border-color: #000;
      &::after {
        background-color: #000;
      }
    }
  }

  @media (max-width: 500px) {
    .proposal-financing-tab {
      flex: 0 0 130px;
      padding: 2px;
      margin-right: 3px;

      .tab-description {
        font-size: 8px;
      }

      .tab-info {
        min-height: 20.2px;

        .tab-title {
          font-size: 12px;
          line-height: 1.33;
        }

        .tab-image {
          width: 122px;
          height: 122px;
        }
      }

      .financing-calculations {
        height: 65px;

        .calculation_row {
          font-size: 8px;
        }
      }

      .financing-total {
        font-size: 8px;
      }

      .tabs-buttons {
        button {
          font-size: 10px;
        }
        label {
          right: -6px;
        }
      }

      .add-new-tab {
        margin-bottom: 5px;
      }

      .financing-conditions {
        p {
          font-size: 6px;
          line-height: 1.5;
        }

        button {
          font-size: 10px;
        }
      }

      .custom-toggle-btn {
        margin-top: 5px;
      }
    }
  }
}
