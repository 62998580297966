.proposal-tab-info {
  .tab-status {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  .section {
    margin-bottom: 10px;

    .section-name {
      font-size: 16px;
      font-weight: 600;
      background: #f7f7f7;
      border-radius: 4px;
      padding: 5px;
      margin-left: -5px;
    }
  }
}

.ant-divider.ant-divider-horizontal.ant-divider-with-text-center {
  margin: 0;
}
