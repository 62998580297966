.DrawerBlock {
  text-align: center;
}

.Button {
  margin: 0;
  padding: 0;
  width: 80px;
  border: none;
  background-color: #4285f4;
  cursor: pointer;
  height: 80px;
  transition: all 0.5s ease-in-out;
}

.ButtonHidden {
  height: 80px;
  margin: 0;
  padding: 0;
  width: 40px;
  border: none;
  background-color: #4285f4;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.Drawer {
  height: calc(100vh - 80px);
  transition: all 0.5s ease-in-out;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #4285f4;
  opacity: 1;
}

.DrawerHidden {
  height: calc(100vh - 80px);
  width: 0;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.Link {
  width: 100%;
  padding: 10px 0;
  color: #ffffff;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.LinkCounter {
  position: absolute;
  bottom: -2px;
  right: 4px;
  font-size: 11px;
  width: 18px;
  height: 18px;
  background-color: orange;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
