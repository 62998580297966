.clients-tab-table {
  // padding: 10px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.clientsTabShow {
  display: block;
  //overflow-x: auto;
  // border-top: 8px solid #f2f3fe;
  .add-client-button {
    margin: 0 auto 10px;
  }
  .ant-table-thead {
    background-color: rgb(236, 236, 236);
  }
  /* .ant-empty {
    padding-top: 30px;
    padding-bottom: 15px;
  } */
  .new-client-wrapper {
    &-title {
      font-weight: 500;
      font-size: 19px;
      letter-spacing: 0.01em;
      color: #191919;
      padding: 0 5px;
      margin: 10px 0;
    }
  }

  @media (max-width: 499px) {
    .ant-empty {
      padding-top: 5px;
      padding-bottom: 5px;

      // .ant-empty-image {
      //   height: 50px;
      // }

      // .ant-empty-description {
      //   font-size: 10px;
      // }
    }
    .new-client-wrapper {
      border: 4px solid #f2f3fe;
      &-title {
        font-size: 10px;
        padding: 0 5px;
        margin: 5px 0;
      }
      .formClientWrapper {
        margin: 5px;
      }
    }
  }
}

.clientsTabHide {
  display: none;
}
