@import '../../../ServiceContracts/components/Form/index.scss';
@import '../Form/Tabs/style.scss';
$backgroundGray: #dcdfe5;

.customer-btns-wrap {
  .MuiFormControl-root {
    margin-bottom: 15px;
    width: 400px;
  }
}
.proposal-preview.proposal-preview__print-view {
  width: 1100px;
  max-width: 1100px;
}

.proposal-preview {
  max-width: 816px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 20px 1px;
  margin-top: 50px;

  .proposal-financing-tabs {
    // justify-content: center;

    &.single {
      justify-content: flex-start;
      // display: none;
    }

    .proposal-financing-tab .tabs-buttons button.hide {
      visibility: visible;
    }
  }

  .Warranty,
  .Instants,
  .Rebates {
    .dnd-item:not(.item-option) {
      .diffPrice {
        @media (max-width: 499px) {
          display: none;
        }
      }
    }
  }

  /*.dnd-item:not(.item-option) {
     .diffPrice {
      @media (max-width: 499px) {
        width: 118px !important;
      }
    }
    .price {
      @media (max-width: 499px) {
        width: 35px !important;
      }
    }

    .term,
    .date {
      @media (max-width: 499px) {
        flex: 1 1 65px !important;
      }
    } 
  }*/

  .dnd-items-options__wrap {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin-top: 4px;
    padding: 0 5px;
    position: relative;
    left: -35px;
    width: calc(100% + 35px);

    @media (max-width: 499px) {
      width: 100%;
    }

    & + .dnd-items-options__wrap {
      margin-top: 15px;
    }

    &:first-of-type {
      margin-top: 12px;
    }

    &--loading {
      opacity: 0.5;
      cursor: wait;

      .dnd-item.item-option {
        pointer-events: none;
      }
    }

    /* .price {
      width: 85px !important;

      @media (max-width: 960px) {
        width: 60px !important;
      }

      @media (max-width: 499px) {
        width: 30px !important;
      }
    } */

    // .description {
    //   @media (max-width: 499px) {
    //     flex: 1 0 60px !important;
    //   }
    // }

    /*     .term,
    .date {
      margin-right: 23px !important;

      @media (max-width: 499px) {
        margin-right: 5px !important;
      }
    } */

    @media (max-width: 499px) {
      left: -20px;
    }
  }

  .proposal-financing-tabs {
    // justify-content: center;
  }

  .card-body {
    padding: 10px 5px;
    @media (max-width: 499px) {
      padding: 5px;
    }
  }
  .header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 10px;
    position: absolute;
    top: -40px;
    left: -10px;

    .preview-header {
      display: inline-block;
      button {
        display: inline-block;
        &:not(:nth-child(1)) {
          margin-left: 10px;
        }
      }
    }

    .email-modal {
      margin-left: auto;
    }

    button {
      white-space: nowrap;
    }
  }

  @media (max-width: 767px) {
    .header {
      display: flex;
      .preview-header {
        display: inline-block;
        button {
          display: inline-block;
          &:not(:nth-child(1)) {
            margin-left: 10px;
          }
        }
      }
      .email-modal {
        margin-top: 5px;
        font-size: 10px;
        margin-left: 0;
      }
    }
  }

  .contacts {
    // background-color: $backgroundGray;
    // padding: 20px;
    .ant-divider-vertical {
      height: 70px;
      align-self: center;
      margin: 0 20px 0 20px;
      background: #b9b9b9a6;
    }
    table {
      background-color: transparent !important;
      tr > td:nth-child(1) {
        white-space: nowrap;
        padding: 0;
      }

      tr > td:nth-child(2) {
        white-space: nowrap;
        padding: 0;
        padding-left: 5px;
      }
    }
  }

  .total-row {
    @extend %total-row;
  }

  .clients-tab-proposal {
    @extend %clients-tab-service-contract;
    width: 100%;
  }

  .tabs {
    display: flex;
    margin-top: 10px;
    overflow: auto;

    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
  .tab-content {
    margin: -1px 0 0px 0;
    border: 1px solid $backgroundGray;
    padding: 0 20px 20px 20px;

    @media (max-width: 499px) {
      padding: 0 5px 20px 5px;
    }
  }

  .media-section {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    .media {
      height: 150px;
      width: 150px;
      margin: 10px;
      position: relative;
      img {
        height: 100%;
        width: 100%;
      }
      button {
        position: absolute;
        top: 5%;
        right: 5%;
      }
    }
  }
  .instalation {
    display: flex;
    justify-content: flex-end;
    margin: {
      top: 20px;
      bottom: 20px;
    }
    label {
      display: flex;
    }
    span {
      align-self: center;
      margin-right: 20px;
    }
  }

  .investment-total {
    height: 40px;
    background-color: #dcdfe5;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    h5 {
      align-self: center;
      font-size: 14px;
      margin: 0;
    }
    span {
      align-self: center;
    }
  }
  .table-items {
    margin: 10px 0 10px 10px;

    // &.Rebates,
    // &.Instants {
    //   .diffPrice {
    //     display: none;
    //   }
    // }

    // &.Rebates,
    // &.Instants {
    //   .price {
    //     // width: 68px !important;
    //   }
    // }

    &.Equipments,
    &.Parts,
    &.Supplies,
    &.ScopeOfWork {
      .date,
      .term {
        display: none;
      }
    }
  }
  .form-custom {
    .actions-modals {
      display: flex;
      .action {
        cursor: pointer;
        text-align: center;
        .mdi {
          font-size: 20px;
        }
        .fas {
          font-size: 20px;
          margin-top: 8px;
        }
        .far {
          font-size: 20px;
          margin-top: 8px;
        }
      }
      /* .action{
        text-align: center;
        margin: 0 5px;        
      } */
    }
    .first-row {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 5px;
      .proposal-notes {
        width: 50%;
      }
      .actions-modals {
        align-self: flex-end;
        width: 50%;
        display: flex;
        justify-content: space-around;
      }
    }
    .second-row {
      .align-self-center {
        .tags {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 30px;
          max-height: 100px;
          overflow-y: auto;
          .tag {
            padding: 10px;
            height: 35px;
            position: relative;
            background: cornflowerblue;
            margin: 4px;
            align-self: center;
            text-align: center;
            vertical-align: middle;
            border-radius: 5px;
            font-size: 14px;
            line-height: 16px;
            color: white;
            button {
              position: absolute;
              padding: 0;
              background: none;
              border: none;
              top: 0px;
              right: 0px;
              color: white;
              width: 16px;
              height: 16px;
              line-height: 16px;
              border-radius: 20px;
            }
          }
        }
      }
      .action {
        @extend %action;
      }
      display: flex;
      width: 100%;
      .installer-notes {
        width: 50%;
      }
    }
    .proposal-tags-container {
      display: flex;
      justify-content: space-between;
      width: 40%;
      /* .upload {
        align-self: flex-start;
        margin: {
          top: 30px;
          left: 30px;
          right: 30px;
        }
      } */
    }
    .proposal-tags {
      align-self: flex-start;
      width: 60%;
      .tags {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        max-height: 100px;
        overflow-y: auto;
        .tag {
          padding: 10px;
          height: 35px;
          position: relative;
          background: cornflowerblue;
          margin: 4px;
          align-self: center;
          text-align: center;
          vertical-align: middle;
          border-radius: 5px;
          font-size: 14px;
          line-height: 16px;
          color: white;
          button {
            position: absolute;
            padding: 0;
            background: none;
            border: none;
            top: 0px;
            right: 0px;
            color: white;
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 20px;
          }
        }
      }
    }
  }
  .end-of-system-table {
    width: 100%;
    tr > td:nth-child(1) {
      width: 30%;
    }
    tr > td:nth-child(2) {
      width: 10%;
    }
    tr > td:nth-child(3) {
      width: 15%;
      text-align: left;
    }
    tr > td:nth-child(4) {
      width: 25%;
      text-align: center;
    }
    .rebates {
      vertical-align: top;
    }
    .instants {
      height: calc(100% + 60px);
      vertical-align: top;
    }
  }
  .customer-block {
    margin-top: 40px;

    &__info {
      white-space: pre-wrap;
      // padding: 10px;
      // max-width: 50%;
      // min-width: 320px;
      // min-height: 100px;
    }

    &__images {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;

      .customer-block__image {
        margin-right: 20px;
      }
    }
  }

  .proposal-form-tabs_content-end_of_system-subtotal_table,
  .proposal-form-tabs_content-end_of_system-total_table {
    margin: 5px 0;
  }

  &.compact-view {
    .proposal-contacts-information .columns__left {
      margin-bottom: 0;
    }

    .proposal-contacts-information {
      & > * {
        &:nth-child(1) {
          margin-bottom: 0;
        }
      }

      .columns__left {
        .properties-info {
          &.clients-info--not-empty {
            & > div {
              margin: 5px 10px 0px 0;
            }
          }
        }

        .clients-info {
          margin-bottom: 0;

          & > div {
            margin: 15px 10px 0px 0;
          }
        }
      }
    }

    .proposal-contacts-information {
      padding: 5px 15px 5px 60px;

      @media (max-width: 499px) {
        padding: 20px 10px 10px;
      }
    }

    .MuiTextField-root
      .MuiOutlinedInput-root
      .MuiOutlinedInput-inputMarginDense,
    .MuiFormControl-root
      .MuiOutlinedInput-root
      .MuiOutlinedInput-inputMarginDense {
      padding: 0 15px;
    }

    .my-button-class {
      height: 25px;
      font-size: 12px;
    }

    .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
      padding-top: 5.5px;
      padding-bottom: 5.5px;
      font-size: 12px;
    }

    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      transform: translate(14px, 7px) scale(1);
      font-size: 12px;

      @media (max-width: 499px) {
        font-size: 8px;
      }
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      font-size: 12px;
      transform: translate(16px, -6px) scale(0.75);

      @media (max-width: 499px) {
        font-size: 8px;
      }
    }

    .dnd-items-options__wrap {
      border: 1px solid rgba(0, 0, 0, 0.3);
      margin-top: 4px;
      border-radius: 4px;

      & + .dnd-items-options__wrap {
        margin-top: 15px;
      }

      &:first-of-type {
        margin-top: 12px;
      }

      /*  .price {
        width: 85px !important;

        @media (max-width: 960px) {
          width: 60px !important;
        }

        @media (max-width: 499px) {
          width: 30px !important;
        }
      } */
    }

    .customer-block {
      margin-top: 0;

      &__images {
        margin-top: 10px;
      }
    }

    .proposal-form-information_for_customer {
      margin-bottom: 5px;
    }

    // .signature-block {
    //   .btn {
    //     margin-top: 5px !important;
    //   }
    // }

    .proposal-form-tabs_content-end_of_system__discounts-title,
    .proposal-form-tabs_content-end_of_system__discounts-subtitle {
      font-size: 12px;
    }

    .proposal-form-tabs_content-end_of_system__discounts-underline--first {
      border: none;
      height: 15px;

      td {
        border: none !important;
      }
    }

    .proposal-form-tabs_content-tab_section-wrapper-content-total_table
      tbody
      tr:not(:last-child),
    .proposal-form-tabs_content-end_of_system-subtotal_table
      tbody
      tr:not(:last-child),
    .proposal-form-tabs_content-end_of_system-total_table
      tbody
      tr:not(:last-child) {
      & > td {
        padding-bottom: 5px;
      }
    }

    .proposal-form-tabs_content-end_of_system-discounts_table {
      padding: 5px 10px;
    }

    .proposal-form-tabs_content-end_of_system__discounts-underline--second {
      display: none;
    }

    .proposal-form-tabs_content-end_of_system-subtotal_table,
    .proposal-form-tabs_content-end_of_system-total_table {
      margin: 5px 0;

      &.proposal-form-tabs__labor {
        td {
          &:last-of-type {
            width: 160px;
            padding-right: 10px;
          }
        }
      }

      tbody tr > td {
        font-size: 14px;

        @media (max-width: 670px) {
          font-size: 12px;
        }
      }
    }

    .MuiIconButton-root {
      padding: 0 9px 0;

      &:hover {
        background-color: unset;
      }
    }

    .form-custom {
      .first-row {
        margin: 8px 0;

        .text-center {
          font-size: 18px;
          margin-bottom: 0;
        }
      }
    }

    .dnd-items-container .dnd-item {
      padding: 5px;

      &.item-option {
        padding-top: 10px;

        &:first-of-type {
          &:before {
            font-size: 10px;
            top: -9px;
            background-color: #fbfdff;
            padding: 0 5px;
          }
        }

        & ~ .item-option {
          padding-top: 5px;
        }
      }
    }

    .proposal-body {
      .tab-content {
        .section {
          .section-header {
            font-size: 16px;
            padding: 5px;
          }

          .dnd-items-header {
            font-size: 14px;
          }

          .dnd-items-container {
            .dnd-item {
              .description {
                font-size: 12px;

                @media (max-width: 499px) {
                  font-size: 8px;
                }
              }

              .diffPrice {
                font-size: 12px;

                @media (max-width: 499px) {
                  font-size: 8px;
                }
              }

              .actions {
                width: 105px;

                & > div {
                  max-width: 20px;
                  max-height: 20px;
                }
                i {
                  div {
                    font-size: 17px !important;
                    max-width: 20px;
                    max-height: 20px;
                    width: 20px !important;
                    height: 20px !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
$printFontSize: 16px;

@media print {
  p,
  span,
  div {
    font-size: $printFontSize;
  }
  // @page {
  //   margin: 1cm 1cm 1cm 1cm;
  // }
  .proposal-preview {
    .header {
      display: none;
    }
  }
  .fa-phone {
    display: none;
  }

  .settings-header {
    .proposal-header {
      margin: 15px 15px 0 15px;
      & > table > tbody > tr > td {
        font-size: $printFontSize;
        & > p {
          font-size: $printFontSize;
        }
      }
    }
    .proposal-logos {
      display: flex;
      align-items: baseline;

      .print-logo {
        flex: 1 1 100px;
        height: 100px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .proposal-body {
    padding: 10px;
    border-radius: 4px;
    overflow: hidden;
    .proposal-contacts-information {
      padding: 5px 20px 20px 80px;
      margin-bottom: 20px;
      .columns {
        margin-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        .columns__left {
          flex: 1 0 auto;
          max-width: 80%;

          &.clients {
            .clients-info--single {
              &:before {
                content: 'To:';
                position: absolute;
                font-weight: 700;
                left: -28px;
                top: 25px;
                color: #000;
              }
              & > div {
                .clients-info__name {
                  span {
                    &:after {
                      font-size: $printFontSize - 4;
                    }
                  }
                }
              }
            }
            .properties-info--single {
              & > div {
                &:before {
                  content: 'Property';
                  position: absolute;
                  font-size: $printFontSize - 4;
                  opacity: 0.5;
                  top: 0;
                  left: 0;
                  font-weight: bold;
                  color: #000;
                }
              }
            }
          }

          .tech-info {
            display: flex;
            position: relative;
            &:before {
              content: 'From:';
              position: absolute;
              font-weight: 700;
              left: -55px;
              top: 2px;
            }
          }
        }
      }
    }

    .proposal-financing-tabs {
      display: flex;
      // justify-content: center;
      overflow-x: auto;

      &.single {
        justify-content: flex-start;
      }

      &.active {
        border-bottom: none;
      }

      .proposal-financing-tab {
        &.active {
          border-bottom: none;
        }

        display: flex;
        flex: 0 1 240px;
        flex-direction: column;
        padding: 5px 10px;
        margin-right: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: #fff;
        border: 1px solid #dcdfe5;
        cursor: pointer;
        overflow: hidden;

        .tab-info {
          .tab-title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 5px;
            text-align: center;
            display: -webkit-box;
            overflow: hidden;
          }
          .tab-image {
            max-height: 215px;
            max-width: 215px;
            width: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            position: relative;
            margin: 0 auto;
          }
          .tab-description {
            margin: 5px 0 10px;
            font-size: 11px;
            & > p {
              line-height: 1.33;
              margin-bottom: 3px;
            }
          }
        }

        .financing-total {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          & > p {
            align-self: center;
          }
        }

        .financing-conditions {
          p {
            font-size: $printFontSize;
          }
        }
      }
    }
    .single {
      .active {
        flex: 0 1 280px;
        padding: 15px;
        .tab-info {
          .tab-image {
            margin: 0 auto;
          }
        }
        .financing-conditions {
          font-size: $printFontSize;
        }
      }
    }

    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe5;
      padding: 10px;

      .table-items {
        .dnd-item {
          margin-bottom: 5px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          .description {
            flex: 0 0 100%;
          }

          .actions {
            display: flex;
            width: 105px;
          }

          .price-section {
            display: flex;

            & > * {
              width: 80px;
            }
          }
        }
      }

      .proposal-form-information_for_customer {
        .files {
          & > * {
            margin-bottom: 10px;
          }
        }
      }

      .proposal-form-information_from_customer {
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      }

      .end-of-tab {
        & > .print-hidden {
          display: none;
        }
        .proposal-form-tabs_content-end_of_system,
        .print-element-nowrap {
          .proposal-form-tabs_content-end_of_system-subtotal_table {
            & > tbody > tr {
              td {
                font-size: $printFontSize;
              }
              td:first-child {
                //width: 80%;
                text-align: right;
              }
              td:nth-child(2) {
                width: 150px;
              }
            }
          }
          .proposal-form-tabs_content-end_of_system-discounts_table {
            table {
              tbody {
                td {
                  font-size: $printFontSize;
                  & > div {
                    font-size: $printFontSize;
                    div {
                      font-size: $printFontSize;
                    }
                  }
                }
              }
            }
          }
        }

        .proposal-form-tabs_content-end_of_system-total_table {
          width: 100%;
          border-collapse: collapse;
          tbody > tr {
            td {
              &:first-child {
                //width: 80%;
                text-align: right;
              }
              &:nth-child(2) {
                width: 150px;
              }
              font-size: $printFontSize;
            }
          }
        }
        .proposal-form-tabs_content-end_of_system-tax-table {
          width: 100%;
          border-collapse: collapse;
          tbody > tr {
            td {
              &:first-child {
                //width: 80%;
                text-align: right;
                margin-right: 10px;
              }
              &:nth-child(2) {
                width: 180px;
              }
              font-size: $printFontSize;
            }
          }
        }

        .signature-block {
          margin-bottom: 10px;
          page-break-inside: avoid;
          .proposal-form-signature_wrapper {
            page-break-inside: avoid;
            .proposal-form-signature_wrapper-signature {
              margin-bottom: 25px;
              page-break-inside: avoid;
              & > img {
                page-break-inside: avoid;
              }
            }
          }
        }
      }
    }

    button {
      display: none;
    }
  }

  .dnd-item-parent,
  .dnd-item {
    & > .description,
    .actions {
      page-break-inside: avoid;
    }
  }
}
// @media print {
//   $backgroundGray: #dcdfe5;
//   html,
//   body {
//     height: initial !important;
//     overflow: initial !important;
//   }

//   #content,
//   #page {
//     width: 100%;
//     margin: 0;
//     float: none;
//   }

//   @page {
//     margin: 1cm 1cm 1cm 1cm;
//     padding: 0cm;
//   }
//   //*,*:before,*:after,*:first-letter,p:first-line,div:first-line,blockquote:first-line,li:first-line { background: transparent !important; color: #000 !important; box-shadow: none !important; text-shadow: none !important;}

//   body {
//     //line-height: 1.3;
//     background: #fff !important;
//     //color: #000;
//   }

//   h1 {
//     font-size: 24pt;
//   }

//   h2,
//   h3,
//   h4 {
//     font-size: 14pt;
//     margin-top: 25px;
//   }

//   a {
//     page-break-inside: avoid;
//   }
//   blockquote {
//     page-break-inside: avoid;
//   }
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6 {
//     page-break-after: avoid;
//     page-break-inside: avoid;
//   }
//   img {
//     page-break-inside: avoid;
//     page-break-after: avoid;
//   }
//   table,
//   pre {
//     page-break-inside: avoid;
//   }
//   ul,
//   ol,
//   dl {
//     page-break-before: avoid;
//   }

//   a:link,
//   a:visited,
//   a {
//     background: transparent;
//     font-weight: bold;
//     text-decoration: underline;
//     text-align: left;
//   }

//   a {
//     page-break-inside: avoid;
//   }

//   a[href^='http']:after {
//     content: ' <' attr(href) '> ';
//   }

//   a:after > img {
//     content: '';
//   }

//   article a[href^='#']:after {
//     content: '';
//   }

//   a:not(:local-link):after {
//     content: ' <' attr(href) '> ';
//   }

//   .entry iframe,
//   ins {
//     display: none;
//     width: 0 !important;
//     height: 0 !important;
//     overflow: hidden !important;
//     line-height: 0pt !important;
//     white-space: nowrap;
//   }
//   .embed-youtube,
//   .embed-responsive {
//     position: absolute;
//     height: 0;
//     overflow: hidden;
//   }

//   #header-widgets,
//   nav,
//   aside.mashsb-container,
//   .sidebar,
//   .mashshare-top,
//   .mashshare-bottom,
//   .content-ads,
//   .make-comment,
//   .author-bio,
//   .heading,
//   .related-posts,
//   #decomments-form-add-comment,
//   #breadcrumbs,
//   #footer,
//   .post-byline,
//   .meta-single,
//   .site-title img,
//   .post-tags,
//   .readability {
//     display: none;
//   }

//   ul,
//   ol {
//     list-style: square;
//     margin-left: 18pt;
//     margin-bottom: 20pt;
//   }

//   li {
//     line-height: 1.6em;
//   }

//   .card {
//     width: 100%;
//     padding: 0;
//     margin: 0;
//   }
//   .card-body {
//     width: 100%;
//     padding: 0;
//     margin: 0;
//   }

//   %totall-row {
//     height: 40px;
//     background-color: #dcdfe5;
//     display: flex;
//     justify-content: space-between;
//     padding: 0 24px;
//     h5 {
//       align-self: center;
//       font-size: 14px;
//       margin: 0;
//     }
//     span {
//       align-self: center;
//     }
//   }

//   .proposal-to-print {
//     background: white;
//     width: 100%;
//     .print-element-nowrap {
//       // page-break-inside: avoid;
//     }
//     .print-error,
//     button {
//       display: none !important;
//     }
//     .logos {
//       display: flex;
//       justify-content: space-around;
//       align-items: center;
//       padding: 10px;
//       div:first-child {
//         padding-left: 0;
//       }
//       div:last-child {
//         padding-right: 0;
//       }
//     }
//     .contacts {
//       display: flex;
//       //flex-wrap: wrap;
//       justify-content: space-between;
//       align-items: top;
//       //padding: 10px;
//       background-color: $backgroundGray;
//       //padding: 20px;
//       .ant-divider-vertical {
//         height: 70px;
//         align-self: center;
//         margin: 0 20px 0 20px;
//         background: #b9b9b9a6;
//       }
//       .table td,
//       .table th {
//         background-color: transparent !important;
//         tr > td:nth-child(1) {
//           white-space: nowrap;
//           padding: 0;
//         }

//         tr > td:nth-child(2) {
//           white-space: nowrap;
//           padding: 0;
//           padding-left: 5px;
//         }
//       }
//     }

//     .total-row {
//       @extend %totall-row;
//     }
//   }
// }

.pay_transaction_modal {
  max-width: 1000px;
  .modal_title {
    text-align: center;
    color: #191919;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 10px;
  }
  .note {
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    color: #191919;
    margin-bottom: 15px;
  }
  .transaction_box {
    .transaction {
      display: flex;
      .transaction_info {
        display: flex;
        font-weight: normal;
        font-size: 14px;
        color: #191919;
        margin-bottom: 25px;
      }
      .disabled_transaction {
        margin-left: 21px;
        // color: #707683;
        opacity: 0.5;
      }
    }
  }
  .card-container {
    margin-bottom: 20px;
    .total_amount {
      color: #191919;
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 499px) {
    .modal-body {
      padding: 0 10px;

      .card-container {
        overflow: auto;
      }

      .transaction_modal_container {
        .modal_title {
          font-size: 24px;
          margin-bottom: 5px;
          line-height: 1;
        }

        .note {
          font-size: 12px;
          margin-bottom: 5px;
        }

        .transaction_box {
          .payment-balances {
            &.radio {
              padding-left: 15px;
            }
          }
        }
      }
    }
  }
}

.tips-wrapper {
  .tips-header {
    display: flex;
    justify-content: center;
    align-items: center;

    .step-title {
      font-size: 1.5rem;
    }

    .total-with-tips {
      color: grey;
    }
  }

  .tip-buttons-container {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

    .tip-button {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
      padding: 5px;
      cursor: pointer;
      text-align: center;
      border: 1px solid grey;
      border-radius: 12px;
      width: 300px;
      height: 65px;

      &.active {
        border: 1px solid #4285f4;
        background-color: aliceblue;

        .MuiFormControl-root {
          margin-bottom: 0;
        }
      }
    }
  }
}

.field-work-order__preview {
  @media screen and (max-width: 500px) {
    transform: scale(0.4);
    width: 23cm;
    position: absolute;
    top: -344px;
    left: 50%;
    margin-left: -10.7cm;
    height: 34cm;
  }
  @media screen and (min-width: 501px) and (max-width: 959px) {
    transform: scale(0.6);
    width: 23cm;
    position: absolute;
    top: -200px;
    left: 50%;
    margin-left: -11cm;
    height: 34cm;
  }
  @media screen and (min-width: 960px) {
    transform: scale(1);
    position: relative;
    top: unset;
  }
}
