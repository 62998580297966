@import '../../../../Layouts/AuthLayout/layoutConstants';

$mainColor: #191919;

%blackCheckbox {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $mainColor;
    border-color: $mainColor;
  }
  .ant-checkbox-checked::after {
    border: 1px solid $mainColor;
  }
  .ant-checkbox-wrapper > span:not(.ant-checkbox) {
    color: #000000;
    opacity: 0.5;
  }

  .ant-checkbox-wrapper:hover,
  .ant-checkbox:hover,
  .ant-checkbox-input:focus {
    .ant-checkbox-inner {
      border: 1px solid $mainColor;
      border-color: $mainColor;
    }
    .ant-checkbox-checked::after {
      border: 1px solid $mainColor;
    }
  }
}
.proposal-form {
  &-modal_wrapper {
    &-title {
      width: 90%;
      text-align: center;
      font-weight: 600;
      font-size: 32px;
      line-height: 134.5%;
      color: #191919;
      margin-bottom: 1rem;
    }
    .modal-content {
      .modal-header {
        border-bottom: unset;
        font-weight: 600;
        font-size: 32px;
        color: #191919;
      }
      .modal-body {
        @extend %settings;
        //@extend %blackCheckbox;
        padding-top: 0;
        & > .buttons {
          display: flex;
          justify-content: center;
          height: 40px;
          margin-bottom: 1rem;
          button:not(:last-child) {
            margin-right: 10px;
            padding: 6px 12px;
          }
        }
      }
    }
    &-cancel_btn {
      color: #191919;
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      background-color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.01em;
      color: #191919;
      height: 40px;
      &:hover:not(:disabled) {
        background-color: #e9e9e9;
        transition: all 0.3s;
      }
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
      }
    }
    &-helperBtn {
      background-color: rgba(52, 159, 166, 0.75);
      border-color: rgba(52, 159, 166, 0.75);
    }

    .btn-primary.disabled.proposal-form-modal_wrapper-helperBtn,
    .btn-primary:disabled.proposal-form-modal_wrapper-helperBtn {
      background-color: rgba(52, 159, 166, 0.9);
      border-color: rgba(52, 159, 166, 0.9);
      cursor: unset;
    }
  }
}
