.proposal-form-tabs_content-info_block {
  background: #ffffff;
  border-radius: 4px;
  padding: 10px 0;

  /*   .information_for_workers {
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    padding: 15px 15px 10px;
    margin-top: calc(35px - 1.5rem);
    position: relative;

    .label {
      position: absolute;
      background-color: #fff;
      color: #19191980;
      letter-spacing: 0.01em;
      padding: 0 5px;
      top: -13px;
    }

    &__heading {
      display: flex;

      .calendar {
        display: flex;
        cursor: pointer;
        margin-right: 50px;

        .dates {
          color: #707683;
          font-size: 12px;
          margin-left: 10px;
          margin-right: 20px;
          position: relative;

          span {
            position: absolute;
            width: 20px;
            height: 20px;
            top: -5px;
            right: -20px;
            border-radius: 50%;
            font-size: 9px;
            color: #4285f4;
            background-color: #4c53ef2b;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .custom-toggle-btn {
        margin-left: auto;
      }

      .attached-files {
        position: relative;
        margin: 0 50px;
        cursor: pointer;

        .fa-paperclip {
          font-size: 18px;
          color: #00000082;
        }

        span {
          position: absolute;
          width: 20px;
          height: 20px;
          top: -8px;
          right: -15px;
          border-radius: 50%;
          font-size: 9px;
          color: #4285f4;
          background-color: #4c53ef2b;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .notes_for_installers {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &__content {
      .installation_dates {
        margin: 10px 0 30px;
      }
    }
  } */

  .tags-wrapper {
    margin-top: 20px;

    .MuiTextField-root {
      max-width: 280px;
      min-width: 200px;
    }
  }

  @media (max-width: 499px) {
    padding: 10px;
    position: relative;

    .tags-wrapper {
      margin-top: 10px;

      .tags-wrapper-list-item {
        padding: 5px;
        height: 20px;
        font-size: 8px;

        i {
          font-size: 12px;
        }

        span {
          margin-right: 5px;
        }
      }
    }
  }
}
