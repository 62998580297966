.termOfUse {
  cursor: pointer;
  font-weight: 500;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  white-space: nowrap;
}
