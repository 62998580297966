.proposal-form-tabs_content {
  margin: -1px 0 10px 0;
  border: 1px solid #d6d6d6;
  // background: #f2f3fe;
  border-radius: 0 4px 4px 4px;
  padding: 0 10px 10px;

  & > .proposal-form-tabs_content-tab_action_block {
    font-size: 10px;
    .proposal-form-tabs_content-tab_action_block-button {
      height: 30px;
    }
  }

  &.view-only {
    .proposal-form-tabs_content-info_block-installers_info > div:first-child .placeholder,
    // .proposal-form-tabs_content,
    .proposal-form-tabs_content-info_block,
    .proposal-form-tabs_content-tab_section-wrapper,
    .proposal-input-cell input,
    .ant-form-item-control .MuiOutlinedInput-root {
      background-color: #f2f3fe;
    }
    .proposal-form-tabs_content-tab_section-wrapper-tab {
      background-color: #e6e8fd;
    }
  }

  .proposal-form-tabs_content-info_block {
    .proposal-form-tabs_content-info_block-installers_info {
      /*       display: flex;
      flex-wrap: wrap; */

      & > div {
        &:first-child {
          flex: 0 1 630px;
          margin-right: 15px;
        }
      }
    }
  }

  .status-with-button {
    display: flex;
    align-items: center;

    // button {
    //   width: 165px;
    //   font-size: 12px;
    // }
  }
  .status-with-button .btn {
    margin: 0 10px;
  }

  @media (max-width: 499px) {
    padding: 10px 0;

    .status-with-button {
      button {
        font-size: 9px;
      }
    }
  }
}
