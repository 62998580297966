label[for='rating-0-5'],
label[for='rating-1-5'],
label[for='rating-2-5'],
label[for='rating-3-5'],
label[for='rating-4-5'] {
  top: 5%;
}

label[for='rating-0-5'] {
  width: 100% !important;
}
