.new-chat-header {
  padding: 10px;

  .header-inputs {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}

.chat-header {
  .header-container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 18px;
    gap: 0.1rem;
    margin-bottom: 5px;
    //margin-right: 35px;

    .settings-block {
      &.closed {
        justify-content: flex-end;
      }
    }
  }
}

.header-divider {
  margin: 10px 0 !important;
}
