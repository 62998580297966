.sinature-modal {
  &-title {
    font-weight: 600;
    font-size: 32px;
    color: #191919;
  }
  .modal-body {
    & > div > div {
      margin-top: 50px;
    }
  }
  @media (max-width: 499px) {
    .modal-body {
      padding: 10px;

      .signature-canvas-container {
        width: 300px !important;
      }
    }
  }
}
