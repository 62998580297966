.finish-page {
  .finish-text {
    margin: 0 -15px;
    font-weight: 600;
    font-size: 24px;
    line-height: 137%;
    text-align: center;
  }
  .finish-please-text {
    margin: 2rem -15px 100px;
    font-size: 17px;
    line-height: 200%;
    text-align: center;
    color: #191919;
  }
}
