.matrix-settings {
  .boxes {
    .box {
      width: 60px;
      height: 40px;
      background: #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 6px 30px rgba(35, 39, 117, 0.08);
      border-radius: 4px;
    }
  }
}
