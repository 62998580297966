.button-load-wrapper {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  height: 100px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.7) 70%,
    rgba(255, 255, 255, 0) 100%
  );
}
// .proposal-contact-info {
//   padding-bottom: 20px;
// }
.proposal-contact-section__buttons {
  padding-bottom: 10px;
}

.proposal-contact-info__edit {
  padding-bottom: 62px;
}

.button-load-wrapper__active {
  background: none;
  height: 32px;
}

.proposal-table.proposal-table__active {
  height: auto;
}

.proposal-table.proposal-table__noactive {
  height: 550px;
}

.proposal-table.proposal-table__isNotNeeded {
  height: auto;
}

.proposal-table {
  height: auto;
  position: relative;
  margin-bottom: 40px;
  overflow: hidden;
  padding-left: 10px;
  &__row {
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 1;
    font-size: 12px;
    color: #191919;
    &-title {
      color: rgba(25, 25, 25, 0.5);
      line-height: 19px;
      letter-spacing: 0.01em;
    }
    &--first {
      // margin: 9px 0 7px 0;
      margin: 5px 0;
      font-weight: 500;
    }
    &--second {
      margin: 3px 0;
      position: relative;
      &-row {
        padding-left: 20px;
        @media (max-width: 768px) {
          padding-left: 10px;
        }
      }
      & > div:first-child {
        padding-left: 30px;
        margin: 4px 0;
        display: flex;
        align-items: center;

        button {
          font-size: 10px;
          height: 20px;
          margin-left: 20px;
        }
        @media (max-width: 768px) {
          padding-left: 10px;
        }
      }
      &-title {
        color: rgba(25, 25, 25, 0.5);
        letter-spacing: 0.01em;
        margin: -3px 0;
        &:after {
          display: none;
        }
      }
    }
    &--third {
      margin: 6px 0;
      position: relative;
      & > div:first-child {
        padding-left: 60px;
        display: flex;
        align-items: center;

        button {
          font-size: 10px;
          height: 20px;
          margin-left: 20px;
        }
        @media (max-width: 768px) {
          padding-left: 20px;
        }
      }
      &-row {
        padding-left: 40px;
        @media (max-width: 768px) {
          padding-left: 20px;
        }
      }
      &-title {
        margin: 6px 0;
        color: rgba(25, 25, 25, 0.5);
        letter-spacing: 0.01em;
        &:after {
          display: none;
        }
      }
    }
  }
  &--no-icon {
    .proposal-table__column {
      &:nth-child(1) {
        flex-basis: 33%;
        width: 31%;
      }
      &:nth-child(2) {
        flex-basis: 11%;
        width: 11%;
      }
      &:nth-child(3) {
        flex-basis: 10%;
        width: 10%;
      }
      &:nth-child(4) {
        flex-basis: 8%;
        width: 8%;
      }
      &:nth-child(5) {
        flex-basis: 21%;
        width: 21%;
      }
      &:nth-child(6) {
        flex-basis: 15%;
        width: 15%;
        padding: 1px 7px;
      }
      &:nth-child(7) {
        flex-basis: 4%;
        width: 4%;
      }
      &:nth-child(8) {
        flex-basis: 4%;
        width: 4%;
        display: none;
      }
    }
  }

  &__column {
    &-underline {
      width: 100%;
      height: 0.5px;
      background-color: rgba(214, 214, 214, 0.5);
    }
    &-check {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-checkbox {
        margin-right: 5px;
      }
      &-link {
        cursor: pointer;
        text-decoration: underline;
      }
      .proposal-table__rating-container {
        flex-basis: 100%;
        margin-left: 22px;
      }
    }
    &-qty {
      max-width: 100%;
      background: rgba(76, 83, 239, 0.08);
      border-radius: 4px;
      width: 41px;
      height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #4285f4;
    }
    &-delete {
      cursor: pointer;
      display: flex;
      justify-content: center;
    }
    &-input {
      padding-right: 20px;
      input {
        height: 30px;
      }
    }
    .MuiFormControl-root.MuiTextField-root {
      margin-bottom: 0;
    }

    &:nth-child(1) {
      flex-basis: 32%;
      width: 32%;
    }
    &:nth-child(2) {
      flex-basis: 5%;
      width: 5%;
    }
    &:nth-child(3) {
      flex-basis: 10%;
      width: 10%;
    }
    &:nth-child(4) {
      flex-basis: 8%;
      width: 8%;
    }
    &:nth-child(5) {
      flex-basis: 21%;
      width: 21%;
    }
    &:nth-child(6) {
      flex-basis: 15%;
      width: 15%;
      padding: 1px 7px;
      //flex-grow: 1;
    }
    &:nth-child(7) {
      flex-basis: 4%;
      width: 4%;
    }
    &:nth-child(8) {
      flex-basis: 30px;
      // width: 4%;
    }
  }

  &__rating {
    &-container {
      display: inline-block;
      cursor: pointer;

      .proposal-table__rating {
        margin: 0;
      }
    }
    display: flex;
    margin: 5px 0;
    label[for='rating-0-5'],
    label[for='rating-1-5'],
    label[for='rating-2-5'],
    label[for='rating-3-5'],
    label[for='rating-4-5'] {
      top: 5%;
    }
    label[for='rating-0-5'] {
      width: 100% !important;
    }
  }
}

@media (max-width: 1024px) {
  .proposal-table {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    &__row {
      font-size: 10px;
    }
    &__column {
      padding: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(1) {
        white-space: normal;
      }
      &:nth-child(5) {
        white-space: normal;
      }
    }
  }
}

@media (max-width: 767px) {
  .button-load-wrapper {
    height: 90px;
  }
  .proposal-table {
    &__row {
      font-size: 8px;
    }
    &--no-icon {
      .proposal-table__column {
        &:nth-child(1) {
          flex-basis: 32%;
          width: 32%;
        }
        &:nth-child(2) {
          flex-basis: 11%;
          width: 11%;
        }
        &:nth-child(3) {
          flex-basis: 13%;
          width: 13%;
        }
        &:nth-child(4) {
          flex-basis: 8%;
          width: 8%;
          display: none;
        }
        &:nth-child(5) {
          flex-basis: 18%;
          width: 18%;
        }
        &:nth-child(6) {
          flex-basis: 24%;
          width: 24%;
          padding: 1px 3px;
        }
        &:nth-child(7) {
          flex-basis: 4%;
          width: 4%;
          display: none;
        }
        &:nth-child(8) {
          flex-basis: 6%;
          width: 6%;
          display: none;
        }
      }
    }
    &__column {
      &:nth-child(1) {
        flex-basis: 32%;
        width: 32%;
      }
      &:nth-child(2) {
        flex-basis: 11%;
        width: 11%;
      }
      &:nth-child(3) {
        flex-basis: 10%;
        width: 10%;
      }
      &:nth-child(4) {
        flex-basis: 8%;
        width: 8%;
        display: none;
      }
      &:nth-child(5) {
        flex-basis: 17%;
        width: 17%;
      }
      &:nth-child(6) {
        flex-basis: 21%;
        width: 21%;
        padding: 1px 3px;
      }
      &:nth-child(7) {
        flex-basis: 4%;
        width: 4%;
        display: none;
      }
      &:nth-child(8) {
        flex-basis: 6%;
        width: 6%;
      }
    }
  }
}
