$mainColor: #4285f4;
$mainColorLighter: #2e5bff;
$transitionTime: 0.2s;

.feedback-modal {
  strong {
    font-size: 14px;
  }
  .card {
    box-shadow: 0px 0px 1px black;
    color: #191919;
    padding: 10px;
    position: relative;

    &.new {
      .card-body {
        border: none;
      }
    }

    &.editing {
      .card-rate {
        border: 1px solid #d6d6d6;
        border-radius: 4px;
        padding: 2px;
        max-height: 27px;
      }
      .card-body {
        border-bottom: none;
      }
    }
    .card-title {
      margin-bottom: 0 !important;
      display: grid;
      grid-template-columns: 20% 20% 45% 15%;
      align-items: center;
      .MuiRating-iconActive {
        transform: unset;
      }
      .created-by {
        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .time-stamp {
        display: flex;
        .date {
          padding-right: 10px;
        }
      }
      .feedback-actions {
        justify-content: flex-end;
        &.disabled {
          button {
            pointer-events: none;
            svg {
              path {
                fill: #dadada;
              }
            }
          }
        }
      }
    }
    .card-rate {
      align-self: flex-start;
      margin: 5px 0;

      .MuiRating-iconActive {
        transform: unset;
      }
    }
    .card-body {
      padding: 0;
      border-bottom: 1px solid #d6d6d6;
      padding-bottom: 20px;
      margin-top: 8px;

      .MuiTextField-root {
        .MuiOutlinedInput-root:hover:not(.Mui-error):not(.Mui-disabled) fieldset {
          border-color: $mainColorLighter;
        }
        .Mui-focused:not(.Mui-error) {
          fieldset {
            border-color: $mainColorLighter;
            border-width: 1.5px;
          }
        }
      }

      .edited-by {
        margin-top: 10px;
        opacity: 0.5;
      }

      .buttons {
        text-align: end;
        margin-top: 20px;

        .btn-cancel {
          margin-right: 10px;
        }
      }
    }
    .card-actions {
      display: flex;
      align-self: flex-end;

      button:nth-child(1) {
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 499px) {
    .modal-body {
      padding: 10px;

      .card {
        padding: 0;

        .card-title {
          font-size: 8px;

          strong {
            font-size: 8px;
          }

          .feedback-actions {
            svg {
              width: 12px;
              height: 12px;
            }
          }
        }

        .card-body {
          & > span {
            font-size: 10px;
          }
          .edited-by {
            margin-top: 0;
          }
          .buttons {
            .btn-primary {
              margin-left: 10px;
            }
          }
        }

        &.new {
          .card-title {
            .MuiRating-sizeLarge {
              font-size: 14px;
            }
          }
        }
      }

      .feedback-modal--buttons {
        width: 180px !important;
      }
    }
  }
}
.rate-stars {
  li {
    div {
      width: 10px;
    }
  }
}
.closeButtonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    margin-bottom: 0;
  }
}
