/* .clock-out-btn{    
    width: 100%;    
    button{
      font-size: large;
      width: 150px;
      position: relative;
      right: -95%;
      top: 50%;
      height: 60%;
      top: 50%;
      transform: translate(-100%, -50%);
    }
} */
$danger: #cd3535;
$primary: #4285f4;
.wrapperHeader {
  display: flex;
  justify-content: flex-end;
}

.headerWrapperButton {
  display: flex;
  flex-direction: column;
  background: red;
  width: 200px;
  height: 70px;
}
.headerSmall {
  //position: relative;
  //top: 60%;
  //right: -63%;
  white-space: nowrap;
}

.blueSmall {
  color: blue;
}
.redSmall {
  color: #cd3535;
  //right: -63.2% !important;
}
.small {
}
.btn-clock {
  width: 150px;
}
.divWrapper {
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  button {
    margin-bottom: 5px;
  }
  //margin-right: 30px;
  .btn-outline-danger {
    border-color: $danger;
    color: $danger;
  }
  .btn-outline-danger:hover {
    border-color: $danger;
    color: #ffffff;
    background-color: $danger;
  }
}

@media (max-width: 520px) {
  .btn-clock {
    white-space: nowrap;
    width: 100px !important;
    font-size: 14px !important;
  }
  small {
    font-size: 6px !important;
  }
  .divWrapper {
    width: 140px;
    //margin-right: 10px;
  }
}
