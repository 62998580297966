.messages {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message-item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.message {
  max-width: 80%;
  //border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;

  //.description {
  //  font-size: 12px;
  //  line-height: 14px;
  //}

  .message-attachments {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    //.ant-skeleton,
    //.ant-skeleton-element > .ant-skeleton-image {
    //  width: 100%;
    //  height: 200px;
    //}


    .message-image {
      max-width: 300px;
      max-height: 300px;
    }
  }
}

.yours {
  align-items: flex-start;
}

.yours .message {
  background-color: #eee;
  position: relative;
  border-radius: 0 15px 15px 15px;
}

.yours .message.last:before {
  //content: '';
  //position: absolute;
  //z-index: 0;
  //bottom: 0;
  //left: -7px;
  //height: 20px;
  //width: 20px;
  //background: #eee;
  border-bottom-right-radius: 15px;
}

.yours .message.last:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  //margin-left: 25%;
  //background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%) fixed;
  background: #00d0ea;
  border-radius: 15px 15px 0 15px;
  position: relative;
}

.mine .message.last:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  //background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%) fixed;
  //background: #00d0ea;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}
