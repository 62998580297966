.admin-page-wrapper {
  width: 100%;
  //padding-right: 110px;

  &__inner-content {
    padding: 95px 10px 50px 0;
    //max-width: 1920px;
    //min-width: 1080px;
    margin-left: 115px;
    transition: all 0.5s ease-in-out;

    &--response {
      margin-left: 40px;
      transition: all 0.5s ease-in-out;
    }
  }
  & .admin-page-wrapper__companies {
    padding: 58px 0 0 0 !important;
    margin-left: 80px !important;
  }
  .admin-page-wrapper__tickets {
    padding: 80px 10px 0 0 !important;
    margin-left: 80px !important;
  }
}
