.proposal-form-tabs_content-tab_status {
  width: 100%;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #191919;
  margin: 10px 0;

  @media (max-width: 499px) {
    margin: 0;
    text-align-last: left;
    margin-left: 10px;
  }
}
