.settings-header {
  // border: 1px solid #000000;
  // margin-bottom: 10px;
  padding: 0 1s0px;

  .proposal-logos {
    display: flex;
    justify-content: center;
    align-items: baseline;

    .print-logo {
      flex: 1 1 100px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &:not(:last-child) {
        margin-right: 5px;
      }

      &.main {
        zoom: 150%;
      }
    }
  }

  .proposal-header {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    table,
    pre,
    tr,
    td,
    th {
      margin: 0;
      padding: 0;
      line-height: 1;
    }

    & * {
      overflow: hidden;
    }

    & table td span {
      white-space: nowrap;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 20px;

    .proposal-logos {
      .print-logo {
        flex: 1 1 100px;
        height: 100px;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0;
    margin-bottom: 20px;

    .proposal-header {
      font-size: 1rem;

      h1 {
        font-size: 18px;
      }
      h2 {
        font-size: 16px;
      }
      h3 {
        font-size: 14px;
      }
      h4 {
        font-size: 12px;
      }
      h5 {
        font-size: 10px;
      }
    }

    .proposal-logos {
      .print-logo {
        flex: 1 1 50px;
        height: 40px;
      }
    }
  }
}
