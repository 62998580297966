.no_card-modal {
  width: 685px;
  max-width: 685px;
  min-width: 685px;
  /* @media (max-width: 710px) {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  } */
  .modal-header {
    & > button.close {
      display: none;
    }
  }
  .modal-body {
    .no_card-modal-content {
      width: 100%;
      & > .card-page {
        & > .card-main-text {
          font-weight: 600;
          font-size: 32px;
          line-height: 36px;
          text-align: center;
          color: #000000;
          margin-bottom: 1rem;
        }
        & > .charged-text {
          font-weight: 500;
          font-size: 17px;
          text-align: center;
          color: #000000;
          margin-bottom: 1rem;
        }
        & > .buttons-block {
          width: 100px;
          display: flex;
          justify-content: center;
          margin: 30px auto 0 auto;
        }
      }
    }
  }
}
