.proposal-contact-summary {
  &__section {
    position: relative;
    margin-bottom: 15px;
    &-primary {
      position: absolute;
      top: 5px;
      left: 13px;
      width: 280px;
      height: 116px;
      border: 1px solid #ccc;
    }
    &-title {
      position: absolute;
      top: -4px;
      left: 30px;
      padding: 0 9px;
      margin-bottom: 11px;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #191919;
      font-weight: bold;
      background-color: #ffffff;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
  }
  &__contacts {
    display: flex;

    &-value {
      padding-left: 5px;
      width: 180px;

      p {
        white-space: nowrap;
        width: calc(100% - 5px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-type {
      width: 50px;
      text-transform: capitalize;
    }
  }
  &__item {
    width: 280px;
    padding-left: 50px;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #191919;
    margin: 10px 0 0;
    position: relative;
    &-title {
      font-weight: bold;
    }
    &-icon {
      position: absolute;
      cursor: pointer;
      left: 15px;
      width: 20px;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .proposal-contact-summary {
    &__section {
      left: -20px;
      margin-top: 12px;
      &:first-child {
        margin-bottom: 10px;
      }
      &:last-child {
        margin-bottom: 11px;
      }
      &-primary {
        width: 180px;
      }
    }
    &__item {
      width: 167px;
      font-size: 10px;
      padding-left: 34px;
      margin-right: 10px;
    }
    &__contacts {
      &-type {
        width: 40px;
      }
      &-value {
        width: 110px;
        padding-top: 2px;
      }
    }
  }
}
