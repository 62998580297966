.history-block {
  position: relative;
  border-bottom: 0.3px solid #19191966;
  padding: 1rem 0;
  .history-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    //gap: 5px;
    justify-content: flex-start;
    align-items: flex-start;
    & > :first-child {
      width: 70px;
    }
  }
}
