$activeColor: #4285f4;
$inactiveColor: #2c2c2c;
$pariallyColor: #ffc000;
$inactiveDangerColor: #f5222d;
$inactiveSuccessColor: #2ebda3;
.proposal-form-tabs_content-tab_action_block {
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  //align-items: center;
  // align-items: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: $inactiveColor;
  margin: 10px 0 5px;
  align-items: baseline;

  & > .proposal-form-tabs_content-tab_action_block-button > .spinner-border,
  .spinner-border-sm {
    width: 15px;
    height: 16px;
  }

  & > div[title='Move Left'],
  div[title='Move Right'],
  div[title='Edit Name'] {
    .spinner-border,
    .spinner-border-sm {
      width: 19px;
      height: 25px;
    }
  }

  @media (max-width: 499px) {
    & > .proposal-form-tabs_content-tab_action_block-button > .spinner-border,
    .spinner-border-sm {
      width: 15px;
      height: 25px;
    }
  }

  &-button {
    cursor: pointer;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // margin-left: 15px;
    max-width: 20%;

    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    path {
      fill: $inactiveColor;
    }

    &.disabled {
      pointer-events: none;
    }
  }
  @media (max-width: 499px) {
    margin: 10px 0;
    padding: 0 10px;
    justify-content: space-between;

    &-button {
      font-size: 8px;
      height: 30px;
      margin-left: 0;
    }
  }

  &-button:not(.disabled):not(.equipment):not(.payment):not(.moduleOne):not(.moduleTwo):not(.moduleThree):not(.delete):hover {
    color: $activeColor;
    path {
      fill: $activeColor;
    }
  }
  &-button.pending,
  &-button.overdue {
    color: $inactiveDangerColor;
    path {
      fill: $inactiveDangerColor;
    }
  }
  &-button.ordered,
  &-button.due {
    color: $activeColor;
    path {
      fill: $activeColor;
    }
  }
  &-button.partially,
  &-button.credit {
    color: $pariallyColor;
    path {
      fill: $pariallyColor;
    }
  }
  &-button.completed {
    color: $inactiveSuccessColor;
    path {
      fill: $inactiveSuccessColor;
    }
  }
  &-button.default {
    color: $inactiveColor;
    path {
      fill: $inactiveColor;
    }
  }
  .delete:not(.disabled):hover {
    color: $inactiveDangerColor;
    path {
      fill: $inactiveDangerColor;
    }
  }
  &-button.disabled {
    opacity: 0.25;
    cursor: default;
    // pointer-events: none;
  }
}
