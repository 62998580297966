.block-title {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 15px;
}
.item-title__wrapper {
  font-weight: bold;
}
.item-info {
  display: grid;
  grid-template-columns: 1.1fr 0.7fr 1fr 0.5fr 0.7fr;
  width: 100%;
  align-items: center;
  & .link {
    color: #1890ff !important;
    &:hover {
      text-decoration: underline;
    }
  }
}
.input-disabled {
  background-color: #e0dcdc;
  opacity: 0.5;
}

.block-cost-wrapper {
  width: 100%;
}
.item-panel {
  width: 100%;
  background-color: transparent !important;
  border: none !important;
  & .item-panel__item {
    background-color: transparent !important;
    border: none !important;
    & .ant-collapse-header {
      padding: 6px 16px !important;
    }
  }
}
// .vertical-timeline {
//   padding: 0;
// }

.cost-block {
  width: 100%;
  & .MuiSvgIcon-root {
    width: 0.8em;
  }
}
.first-timeline-element {
  padding-bottom: 0;
}

.block-date-container {
  // margin: 0 0 0 20px;
  & .vertical-timeline-element {
    position: relative;
    margin: 0 0 2em 0 !important;
  }
  & .vertical-timeline {
    padding-top: 0 !important;
  }

  .date-header {
    display: grid;
    grid-template-columns: auto 80%;
    font-size: 0.85rem;
    font-weight: 600;
  }

  .date-item {
    display: grid;
    grid-template-columns: auto 80%;
    font-size: 1rem;
    font-weight: bold;
  }

  .date-sub-item {
    .sub-item-container {
      display: grid;
      grid-template-columns: 19% 75%;
      font-size: 1rem;

      & > :nth-child(1) {
        margin-left: 60px;
      }
    }
  }
  .block-date-line__secondary {
    font-size: 14px;
    font-weight: inherit;
    text-align: end;
    margin-top: 0;
    font-weight: 500;
  }
  .block-date__wrapper {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid black;
    padding-top: 4px;
  }

  .block-date-line {
    text-align: end;
    border-top: 1px solid black;
    font-size: 16px;
    & span {
      font-size: 14px;
      font-weight: inherit;
      text-align: end;
    }

    // display: grid;
    // grid-template-columns: 15% 35%;
    // font-size: 1rem;
    // font-weight: bold;

    .line-date {
      margin-left: 40px;
    }
  }
}

.block-cost-wrapper {
  .block-cost-container {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto auto;
    align-items: center;
    justify-content: space-between;

    .grid-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin: 5px 0;
      font-size: 1rem;

      .delete-action {
        cursor: pointer;
      }

      &:nth-child(3n) {
        svg {
          margin-left: 10px;
        }
      }

      input[type='number']::-webkit-inner-spin-button,
      input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      & > .MuiFormControl-root,
      .MuiTextField-root,
      .MuiFormControl-fullWidth {
        width: 100px;
        margin-bottom: 0rem !important;
      }

      .item-title {
        margin-right: 10px;
      }

      & > .error {
        .MuiInputBase-root,
        .MuiOutlinedInput-root,
        .MuiInputBase-formControl,
        .MuiInputBase-marginDense,
        .MuiOutlinedInput-marginDense {
          fieldset {
            border-color: red !important;
          }
        }
      }
    }

    .time-block {
      .time-inputs {
        display: flex;
        & .MuiSvgIcon-root {
          width: 0.8em;
        }
        & .MuiOutlinedInput-adornedEnd {
          padding-right: 4px;
        }
        .MuiFormControl-root .MuiTextField-root,
        .MuiFormControl-fullWidth {
          margin-right: 5px;
        }
      }
    }

    .total-block {
      display: grid;
      grid-template-columns: auto 125px auto;

      & > :nth-child(1) {
        text-align: right;
      }
    }
  }

  .notes-block {
    width: 100%;
    margin: 5px 0;
  }

  .separator {
    margin: 10px 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #80808052;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .block-date-container {
    .date-header {
      grid-template-columns: auto 60%;
    }

    .date-item {
      grid-template-columns: auto 60%;
    }

    .date-sub-item {
      .sub-item-container {
        grid-template-columns: 30% 70%;

        & > :nth-child(1) {
          margin-left: 60px;
        }
      }
    }
  }
}
.separator {
  padding: 10px;
}
@media (min-width: 320px) and (max-width: 768px) {
  .block-date-container {
    margin: 0;

    .block-date-line {
      grid-template-columns: 50% 50%;
    }

    .date-header {
      grid-template-columns: 1fr 1fr;
    }

    .date-body {
      grid-template-columns: 1fr;

      .date-item {
        grid-template-columns: 1fr 1fr;
      }

      .date-sub-item {
        .sub-item-container {
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
  .block-cost-wrapper {
    .block-cost-container {
      display: grid;
      grid-template-columns: 100%;

      .grid-item {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
      }

      .time-block {
        width: 100%;

        & > :nth-child(2) {
          text-align: right;

          div {
            margin-right: 0 !important;
          }
        }
      }

      .cost-block {
        width: 100%;
        & .MuiSvgIcon-root {
          width: 0.8em;
        }
        & > :nth-child(2) {
          text-align: right;
          margin-left: auto;
        }
      }

      .total-block {
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr;

        .item-title {
          grid-column: 2/2;

          .time-inputs > div {
            & > :nth-child(1) {
              margin-right: 5px !important;
            }
          }
        }

        .item-total {
          grid-column: 3/3;
        }

        .delete-action {
          grid-column: 1/1;
          grid-row: 1;
        }
      }
    }
  }
}
