$mainColor: #4285f4;
$mainColorLighter: #2e5bff;
$transitionTime: 0.2s;

%positionAbsolute {
  position: absolute;
}

%mainButton {
  .main-button {
    min-height: 25px;
  }
}

%settings {
  .MuiTextField-root {
    margin-bottom: 1rem;
    input {
      height: 19px;
    }
    .MuiSelect-select:focus {
      background-color: unset;
    }
    .MuiChip-root {
      color: $mainColor;
      height: 30px;
      font-size: 12px;
      border-radius: 4px;
      background-color: #e6e8fd;
    }
    .MuiChip-deleteIcon {
      color: $mainColor;
      width: 15px;
      height: 15px;
      margin: 0 5px 0 -6px;
    }
    .MuiInputAdornment-positionEnd {
      margin-left: -46px;
    }

    /* .MuiInputBase-adornedEnd {
      .MuiOutlinedInput-input[type='password'] {
        padding: 10.5px 46px 10.5px 14px;
      }
    } */

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] {
      padding: 6.75px;
    }
    /*  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding: 10px 39px 2px 6px;
     } */

    .MuiChip-deleteIcon:hover {
      color: #f44336;
    }

    /* .MuiOutlinedInput-inputMarginDense {
      padding-top: 12.5px;
      padding-bottom: 8.5px;
    } */

    .MuiAutocomplete-tag {
      margin: 0 3px 5px 3px;
    }

    .MuiOutlinedInput-root {
      // font-family: Poppins;
      // font-style: normal;
      // font-weight: normal;
      // font-size: 14px;
      // line-height: 21px;
      // letter-spacing: 0.01em;
      // color: #191919;
      input {
        background: transparent;
      }
      fieldset {
        background: transparent;
        border-color: #d6d6d6;
        border-width: 1px;
        box-sizing: border-box;
        border-radius: 4px;
        transition: border-color $transitionTime, border-width $transitionTime;
      }
    }
    .MuiOutlinedInput-root:hover:not(.Mui-error):not(.Mui-disabled) fieldset {
      border-color: $mainColorLighter;
    }

    label {
      color: #000000;
      opacity: 0.5;
    }
    &:hover label.Mui-focused {
      color: $mainColorLighter;
      transition: all $transitionTime;
      opacity: 1;
    }

    label.Mui-focused {
      color: $mainColorLighter;
      opacity: 1;
    }
    label.Mui-focused.Mui-error {
      color: #f44336;
      opacity: 1;
    }
    .Mui-focused:not(.Mui-error) {
      fieldset {
        border-color: $mainColorLighter;
        border-width: 1.5px;
      }
    }

    /*    .MuiSelect-outlined.MuiSelect-outlined {
      padding: 9.6px 32px 9.6px 14px;
    } */
    .MuiInputBase-input.Mui-disabled {
      opacity: 0.5;
    }

    .Mui-error {
      fieldset {
        border: 1px solid #f44336;
      }
    }
    .Mui-disabled {
      input {
        // opacity: 0.5;
      }
      /* fieldset {
        background: #0000000d;
      } */
    }
    .MuiFormHelperText-root {
      font-size: 12px;
      margin-top: 3px;
      text-align: left;
      font-family: inherit;
      font-weight: 400;
      line-height: 1.66;
      letter-spacing: inherit;

      @media (max-width: 768px) {
        font-size: 6px;
        line-height: 1;
      }
    }
    .MuiOutlinedInput-inputMarginDense {
      text-overflow: ellipsis;
    }

    /*    @media (max-width: 499px) {
      margin-bottom: 1rem;
    } */
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $mainColor;
    border-color: $mainColor;
  }
  .ant-checkbox-checked::after {
    border: 1px solid $mainColor;
    border-radius: 4px;
  }

  .ant-checkbox-wrapper:hover,
  .ant-checkbox:hover,
  .ant-checkbox-input:focus {
    .ant-checkbox-inner {
      border: 1px solid $mainColor;
      border-color: $mainColor;
      border-radius: 4px;
    }
    .ant-checkbox-checked::after {
      border: 1px solid $mainColor;
      border-radius: 4px;
    }
  }

  .ant-checkbox-wrapper {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #707070;
    & > span:first-of-type {
      margin-right: 5px;
    }
  }
  /* .ant-checkbox-wrapper:hover,
  .ant-checkbox-wrapper-checked:hover {
    .ant-checkbox-inner {
      border-radius: 4px;
      border-color: $mainColor;
    }
    .ant-checkbox-checked::after {
      border: 1px solid $mainColor;
      border-radius: 4px;
    }
  } */
  .ant-checkbox-inner {
    border-radius: 4px;
  }
  .ant-checkbox + span {
    padding: 0;
  }

  .ant-carousel {
    .slick-slide {
      overflow: hidden;
    }
  }
  .ant-carousel .slick-dots {
    text-align: start;
  }

  .ant-carousel .slick-dots li button {
    width: 20px;

    background: #4285f4;
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    opacity: 0.35;
  }
  .ant-carousel .slick-dots li.slick-active button {
    background: #4285f4;
    width: 20px;
    opacity: 1;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled),
  .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: $mainColor;
    box-shadow: unset;
  }
}
@import './layoutConstants';

.auth-layout-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
  max-width: 100vw;
  min-width: 320px;
  flex-wrap: nowrap;

  .auth-layout-content {
    margin: 10px 0px;
    border: none;
    background: rgba(255, 255, 255, 0.92);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    color: #191919;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.01em;
    padding: 30px 50px 10px;
    min-width: 360px;
    min-height: calc(#{100vh} - #{20px});
    min-width: 462px;
    max-width: min-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .auth-beta {
      position: absolute;
      top: 0;
      left: 0;
    }

    .auth-layout-form {
      flex: 1;
      min-width: 300px;
      width: 100%;
      .back-to-login-btn {
        cursor: pointer;
        display: block;
        width: 100%;
        text-align: center;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        line-height: 40px;
        letter-spacing: 0.01em;
        color: #2e5bff;
        margin: 1rem 0;
      }
      .main-text {
        font-weight: 600;
        font-size: 32px;
        line-height: 36px;
        text-align: center;
        margin-bottom: 25px;
      }
      .please-text {
        font-weight: normal;
        font-size: 17px;
        line-height: 160%;
        text-align: center;
        margin: 1.5rem 0;
      }
      .impekable {
        letter-spacing: 0.24em;
      }

      @extend %settings;

      @extend %mainButton;

      .MuiTextField-root
        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense'] {
        padding: 1px 10px;
      }
    }
    .auth-layout-term-and-conditions {
      display: block;
      padding: 10px 0;
      font-weight: 500;
      white-space: nowrap;
      text-align: center;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.01em;
      span {
        color: #000000;
        opacity: 0.35;
      }
      a {
        color: #000000;
        opacity: 0.35;
        transition: all $transitionTime;
      }
      a:hover {
        color: $mainColor;
        opacity: 1;
      }
    }

    @media (max-width: 499px) {
      min-width: 320px;
      padding: 30px 20px 10px;
    }
  }
}
