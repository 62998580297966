.map-label {
  .map-label-content-clocked {
    width: 20px;
    height: 20px;
    border: 1px solid #000;

    .clocked {
      font-size: 14px;
      width: 16px;
      height: 16px;
      color: #fff;
      background: #000;
      font-weight: 900;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      top: -2px;
      position: relative;
      left: -2px;
    }

    .clocked.clocked-in {
      background: #3d85fa;
    }

    .clocked.clocked-out {
      background: #ee7c7b;
    }
  }
}
