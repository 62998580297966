.timeChanger > div {
  padding-right: 10px;
  margin-top: 10px;
}
.borderedTd {
  border-bottom: 1px solid rgb(215, 215, 215);
}
.breakBorder {
  height: 0;
  width: 50%;
  border-bottom: 1px solid rgb(215, 215, 215);
  position: relative;
  top: 1px;
  right: 12px;
}
.noteWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bordBot {
  border-bottom: 1px solid rgb(215, 215, 215);
}
.toolTip {
  margin-left: 5px;
}
.deleted {
  flex-direction: row-reverse;
  justify-content: end;
}
.deletedWrapper {
  position: relative;
  left: 7px;
  top: 4px;
}
.timepicker-wrapper {
  margin-top: 22px;
  width: 125px;
  margin-left: 20px;
  height: 54px;
}
.pickerWrapper {
  // align-items: flex-end;
}

.overlapppingMessage {
  position: absolute;
  bottom: -10px;
  color: red;
  font-size: 10px;
}
