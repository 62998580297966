.customer-block__image {
  position: relative;
  // cursor: pointer;

  .image-actions {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 10px;
    top: 0;
    left: 0;
  }

  .image {
    cursor: zoom-in;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #00000080;
      border-radius: 8px;
      opacity: 0;
      z-index: 1;
      transition: all 0.3s;
      top: 0;
      left: 0;
    }

    &:hover {
      .image-actions {
        opacity: 1;
      }

      &::before {
        opacity: 1;
      }
    }
  }
}
