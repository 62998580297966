.create-appointment-form {
  max-width: 1400px;
  position: relative;
  .appt-routing-modal {
    z-index: 1500;
  }
  &__appoitment-section {
    margin-bottom: 10px;

    // .appointment-section + div {
    //   margin-top: 10px;
    //   max-width: 200px;
    // }

    @media (max-width: 768px) {
      width: 100%;
    }
  }
  & .office-location__wrapper {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    & .office-location__icon {
      cursor: pointer;
      margin-left: 20px;
    }
  }

  &__buttons_fix {
    display: flex;
    margin: 40px 0 50px;
    gap: 1rem;

    @media (max-width: 767px) {
      margin: 40px 0 0 0;
      flex-wrap: wrap;
      flex-direction: column;
      align-content: stretch;
      justify-content: space-around;
      align-items: stretch;
      & > button {
        margin: 5px 0;
      }
    }

    .rfr-button {
      height: 30px;
      padding: 4px 12px;
      margin: 0 10px;
      @media (min-width: 320px) and (max-width: 767px) {
        height: 25px;
        font-size: 0.8rem;
        padding: 0 12px;
      }
    }

    & > *:nth-child(2) {
      margin: 0 10px;

      @media (max-width: 767px) {
        margin: 0px;
      }
    }

    &.new {
      justify-content: flex-end;
    }

    .update {
      margin-left: auto;

      @media (max-width: 767px) {
        margin: 0;
      }
    }
  }
}

.appt-property__title {
  font-weight: 800;
  margin-bottom: 10px;
}
.appointment-controls {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}
