.sidebar {
  overflow-y: auto;
  overflow-x: hidden;
  width: 80px;
  z-index: 100;
  background: #4285f4;
  bottom: 0;
  margin-top: 0;
  padding-bottom: 30px;
  position: fixed;
  top: 80px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  scrollbar-width: none;

  @media (max-width: 499px) {
    top: 58px;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 2px;
    min-height: 50px;
    max-height: 60px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #ffffff;
    transition: all 0.3s;
    margin-bottom: 5px;
    i {
      margin-top: 4px;
      font-size: 18px;
      width: 20px;
      text-align: center;
      padding: 0;
    }

    &.active {
      g,
      path {
        opacity: 1;
        transition: all 0.3s;
      }
      color: #ffffff;
      //border-left: 2px solid #626ed4;
      background: #6870f1;
    }
    &:hover {
      color: #40a9ff;
      g,
      path {
        opacity: 1;
        transition: all 0.3s;
      }
      path[stroke='white'] {
        stroke: #40a9ff;
      }
      path[fill='white'],
      rect[fill='white'] {
        fill: #40a9ff;
      }
    }

    .icon-wrapper {
      position: relative;

      .task-counter {
        width: 15px;
        height: 15px;
        position: absolute;
        background: orange;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        right: -6px;
        top: -6px;
        font-size: 8px;
      }
    }
  }
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }
  &::-webkit-scrollbar {
    width: 0px;
    background-color: none;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: none;
  }

  &.closed {
    margin-left: -80px;
    transition: 0.5s;
  }
}
@media (min-width: 769px) {
  .mobile-topbar {
    display: none;
  }
}
