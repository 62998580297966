.client-form-proposals {
  padding: 10px;
  & table {
    width: 100%;
    border-collapse: collapse;
  }
  & table {
    width: 100%;
  }

  & table,
  td {
    border-collapse: collapse;
  }

  & thead {
    display: table;
    width: calc(100% - 17px);
  }

  & tbody {
    display: block;
  }

  & th,
  td {
    width: 16.6%;
    padding: 5px;
    word-break: break-all;
  }

  & tr {
    display: table;
    width: 100%;
    box-sizing: border-box;
  }

  & td {
    text-align: left;
    border-bottom: none;
    border-left: none;
  }

  .add-proposal-button {
    margin: 0 auto;
  }

  .proposals-table-row {
    font-style: 14px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    & tr {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    p {
      // flex: 1 0 auto;

      &:nth-child(1) {
        flex: 0 0 120px;
      }
      &:nth-child(2) {
        flex: 0 0 100px;
      }
      &:nth-child(3) {
        flex: 0 0 150px;
      }
      &:nth-child(4) {
        flex: 0 0 200px;
      }
      &:nth-child(5) {
        flex: 0 0 110px;
      }
      &:nth-child(6) {
        flex: 0 0 110px;
      }
    }

    &.heading {
      padding: 5px 15px;
      color: #191919;
      font-size: 12px;
      opacity: 0.5;
    }

    &:not(.heading) {
      padding: 20px 15px;
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &:hover {
        background: #f2f3fe;
      }
    }
  }

  @media (max-width: 500px) {
    padding: 0;

    .proposals-table-row {
      font-size: 10px;
      p {
        &:nth-child(1),
        &:nth-child(2) {
          flex: 0 0 65px;
        }
        &:nth-child(3) {
          flex: 0 0 80px;
        }
      }
      &.heading {
        padding: 0 5px;
        font-size: 8px;
      }

      &:not(.heading) {
        padding: 5px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
}
