.proposal-form-tabs_content-info_block-installers_info {
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 15px 5px 5px;
  margin-top: 20px;
  position: relative;

  .block-installers_info__input--counter {
    font-size: 10px;
    color: gray;
    text-align: end;
  }

  p.field-text {
    white-space: pre-wrap;
    font-size: 0.75rem;
    line-height: 1.33;
  }

  textarea {
    // font-size: 0.75rem;
    line-height: 1.33;
  }

  .related-jobs {
    margin-bottom: 20px;
  }

  .placeholder {
    position: absolute;
    top: -10px;
    left: 12px;
    font-size: 12px;
    background: white;
    padding: 0 9px;

    @media (max-width: 499px) {
      font-size: 8px;
      transform: scale(0.75);
      top: -7px;
    }
  }

  & > div:first-child {
    max-width: 612px;
    margin-bottom: 10px;

    .field-text {
      white-space: pre-wrap;
    }

    .MuiFormControl-root {
      margin-bottom: 15px;

      @media (max-width: 499px) {
        margin-bottom: 10px;
      }
    }
  }
  &-file {
    display: flex;
    // margin-right: 15px;
  }

  .field-label {
    position: absolute;
    top: -10px;
    left: 12px;
    font-size: 12px;
    background: white;
    padding: 0 9px;
  }

  .files {
    margin-bottom: 10px;

    .file-description {
      margin-left: 12px;
      width: 100%;
      max-width: 500px;

      p {
        white-space: initial;
      }
    }
  }

  @media (max-width: 499px) {
    margin-top: 10px;
    display: block;
    font-size: 0.7rem;

    &-file {
      margin-left: 5px;
      margin-bottom: 10px;
      .customer-block__image {
        & [title='icon'] {
          width: 50px;
          height: 50px;
          & svg {
            width: 50px;
            height: 50px;
          }
        }
        div {
          &:nth-child(1) {
            width: 50px !important;
            height: 50px !important;
          }
          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}
