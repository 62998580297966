.proposal-contacts-information {
  background-color: #fff;
  font-size: 12px;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  padding-left: 60px;

  & > * {
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    & > p {
      font-weight: bold;
      flex: 0 0 50px;
    }
  }

  .tech-info {
    display: flex;
    position: relative;

    &:before {
      content: 'From:';
      position: absolute;
      font-weight: 700;
      left: -45px;
    }

    & > div {
      margin-right: 30px;
      p {
        &:nth-child(1) {
          span {
            font-weight: bold;
          }
        }
      }
    }
  }

  .columns {
    &__left {
      flex: 1 0 auto;
      // margin-bottom: 7px;
      // max-width: 80%;

      @media (max-width: 960px) {
        max-width: unset;
      }

      &.clients {
        & > div {
          &:first-of-type {
            &:before {
              content: 'To:';
              position: absolute;
              font-weight: 700;
              left: -28px;
              top: 20px;
              color: #000;
            }
          }
        }
      }

      .clients-info {
        display: grid;
        grid-template-columns: repeat(auto-fit, 200px);
        gap: 20px;
        margin-bottom: 9px;
        position: relative;
        padding-top: 20px;

        @media (max-width: 499px) {
          grid-template-columns: repeat(auto-fit, 130px);
        }

        .clients-info__phone {
          span {
            text-transform: capitalize;
          }
        }

        .clients-info__email {
          white-space: nowrap;
          width: calc(100% - 5px);
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &.clients-info--single {
          & > div {
            &:first-child {
              p:first-child {
                span {
                  &::after {
                    content: 'Client';
                  }
                }
              }
            }
          }
        }

        &.organizations-info {
          &--single {
            & > div {
              &:first-child {
                p:first-child {
                  span {
                    &::after {
                      content: 'Organization';
                    }
                  }
                }
              }
            }
          }
          & > div {
            &:first-child {
              p:first-child {
                span {
                  &::after {
                    content: 'Organizations';
                  }
                }
              }
            }
          }
        }

        & > div {
          margin-bottom: 10px;

          &:first-child {
            p:first-child {
              span {
                &::after {
                  content: 'Contacts';
                  font-weight: bold;
                  position: absolute;
                  top: 4px;
                  left: 0;
                  font-size: 8px;
                  color: #191919;
                  opacity: 0.5;
                }
              }
            }
          }
        }
      }

      .properties-info {
        display: grid;
        grid-template-columns: repeat(auto-fit, 200px);
        gap: 20px;
        margin-bottom: 9px;
        position: relative;

        @media (max-width: 499px) {
          margin-bottom: 0;
          grid-template-columns: repeat(auto-fit, 130px);
        }

        &.clients-info--not-empty {
          & > div {
            margin: 5px 10px 20px 0;
          }
        }

        &.properties-info--single {
          & > div {
            &:nth-child(1) {
              &:before {
                content: 'Property';
              }
            }
          }
        }

        & > div {
          // margin-right: 30px;
          position: relative;

          // margin-bottom: 10px;
          // width: calc(32% - 10px);
          // margin-right: 10px;
          // margin: 0 10px 20px 0;
          padding-top: 15px;

          /* &:nth-of-type(3n + 2) {
            padding: 15px 40px 0;
            margin-right: 40px;
            width: calc(32% - 40px);
            position: relative;

            @media (max-width: 960px) {
              width: calc(50% - 10px);
              margin-right: 10px;
              padding: unset;
            }
          }

          &:nth-of-type(1),
          &:nth-of-type(2) {
            @media (max-width: 960px) {
              padding-top: 15px;
            }
          }

          @media (max-width: 960px) {
            width: calc(50% - 10px);
            padding-top: 0;
            margin-bottom: 5px;
          } */

          &:nth-child(1) {
            &:before {
              content: 'Properties';
              position: absolute;
              font-size: 8px;
              opacity: 0.5;
              top: 0;
              left: 0;
              font-weight: bold;
              color: #000;
            }
          }
          p.property-relation {
            font-weight: bold;
            color: #000;
            // padding-top: 15px;
          }
        }
      }
    }
    /*  &__right {
      margin-left: auto;
      // flex: 0 1 160px;

      .proposal-info {
        // display: flex;
        p {
          span {
            font-weight: bold;
          }
        }
      }

      .work-completion-dates {
        & > p {
          font-weight: 700;
        }
      }

      .proposal-form-installation_dates-calendar {
        display: none;
      }
    } */
  }

  @media (max-width: 1200px) {
    & > * {
      flex-wrap: nowrap;
    }

    .columns__left {
      flex: auto;

      .tech-info {
        flex-wrap: wrap;

        & > div {
          white-space: nowrap;
        }
      }
    }
  }

  @media (max-width: 499px) {
    padding: 10px;
    padding-top: 20px;
    font-size: 8px;
    padding-left: 20px;

    .columns {
      &:nth-child(1) {
        margin-bottom: 20px;
      }
      &:nth-child(2) {
        flex-wrap: wrap;

        .columns__right {
          margin-left: 0;
          margin-top: 0px;
        }
      }

      .columns__left {
        .clients-info {
          margin-bottom: 5px;
          // justify-content: space-between;

          &:before {
            left: -17px !important;
            top: 20px !important;
          }
          & > div {
            margin-bottom: 5px;
            min-width: 100px;
          }
        }
        .tech-info {
          display: block;
          &:before {
            left: 0;
            top: -12px;
          }
        }
      }
    }
  }
}
