/*Wraperclass for the divicon*/
.map-label {
  position: absolute;
  bottom: 50%;
  left: -50%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
/*Wrap the content of the divicon (text) in this class*/
.map-label-content {
  order: 1;
  height: 25px;
  width: 33px;
  position: relative;
  left: -50%;
  // background-color: rgb(255, 255, 255);
  background-color: #cfcfcf;
  font-size: 10px;
  line-height: 1.1;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #444;
  padding-top: 5px;
  white-space: nowrap;
  border-radius: 12px;
  box-sizing: border-box;
  transform: translateY(32%);
  img {
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }

  &.in_process {
    background: linear-gradient(to top right, #fff0 calc(50% - 4px), rgb(255, 255, 255), #fff0 calc(50% + 4px)), #cfcfcf;
  }
  &.completed {
    background: linear-gradient(to top right, #fff0 calc(50% - 4px), rgb(0, 155, 10), #fff0 calc(50% + 4px)), #cfcfcf;
  }
  &.canceled {
    background: linear-gradient(to top right, #fff0 calc(50% - 4px), #ff0000, #fff0 calc(50% + 4px)),
      linear-gradient(to top left, #fff0 calc(50% - 4px), #ff0000, #fff0 calc(50% + 4px)), #cfcfcf;
  }
}

.map-label-content.map-label-content-icon {
  padding: 0;
  background-color: #000;

  img {
    width: 100%;
    height: 100%;
  }
}
/*Add this arrow*/
.map-label-arrow {
  order: 2;
  width: 0px;
  height: 0px;
  left: 50%;
  border-style: solid;
  border-color: #444 transparent transparent transparent;
  border-width: 20px 12px 0 12px; /*[first number is height, second/fourth are rigth/left width]*/
  margin-left: -12px;
}

/*Instance classes*/
.map-label.inactive {
  opacity: 0.5;
}

.map-label.employee > .map-label-content {
  border-color: #4b94d8;
}
.map-label.employee > .map-label-arrow {
  border-top-color: #4b94d8;
}

.map-label.redbackground > .map-label-content {
  white-space: default;
  color: #fff;
  background-color: black;
}
