.property-form {
  position: relative;

  .formClientWrapper > .add-or-update-client-form > .tabss > .tabss-list {
    display: none;
  }

  .tabss {
    margin-bottom: 20px;
  }

  .loading-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.new {
    .tabss-list {
      display: none;
    }
  }

  &__action-buttons {
    // margin-top: 20px;
    display: flex;
    justify-content: center;
    position: relative;
    .deleteBtn {
      position: absolute;
      left: 0;
    }
  }
}
