%name {
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}
.myTable {
  padding-top: 0 !important;
}
.hidden-column {
  display: none;
}
%relation {
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}

%notes {
  width: 100px;
  min-width: 50px;
  max-width: 120px;
  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
}

%email {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  .ant-carousel {
    min-width: 140px;
    .slick-dots-bottom {
      bottom: unset;
    }
  }
}

%phone {
  width: 120px;
  min-width: 100px;
  max-width: 120px;
  .ant-carousel {
    // width: 140px;
    .slick-dots-bottom {
      bottom: unset;
    }
  }
}

%reviews {
  max-width: 130px;
  width: 100px;

  .client-rating {
    cursor: pointer;
    margin-left: -1px;
    // margin-top: 6px;
    display: flex;
    align-items: center;
    max-width: 120px;

    .rating-count {
      margin-left: 5px;
      font-size: 12px;
    }
    @media (max-width: 499px) {
      .MuiRating-sizeSmall {
        font-size: 10px;
      }
    }
  }

  .vote {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #191919;
    width: 10px;
    position: relative;
    top: -1px;
  }

  @media (max-width: 768px) {
    width: 95px;
    min-width: 95px;
    max-width: 95px;
    .client-rating {
      .MuiRating-sizeSmall {
        font-size: 12px;
      }
      .vote {
        font-size: 10px;
      }
    }
  }
}

%actions {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  .show-details-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 34px;
    height: 34px;
    background-color: #ffffff;
    border: 1px solid #d6d6d6;
    color: #191919;
    box-sizing: border-box;
    transition: all 0.3s;
    border-radius: 50%;
    font-size: 24px;
    &.active,
    &:hover {
      background-color: #4285f4;
      border: 1px solid #4285f4;
      color: #ffffff;
    }
  }

  @media (max-width: 768px) {
    width: 60px;
    min-width: 60px;
    max-width: 60px;

    .custom-delete-btn {
      width: 20px;
      min-width: 20px;
      max-width: 20px;
      height: 20px;
    }
    .custom-toggle-btn {
      width: 20px;
      min-width: 20px;
      max-width: 20px;
      height: 20px;
    }
  }
}
%myTableClass {
  td {
    background: white;
    margin-top: 10px;
  }
  .ant-table .ant-table-content .ant-table-body > table {
    min-width: 100%;
    width: initial;
    border-spacing: 0 10px;

    tbody,
    thead {
      & > tr td,
      & > tr th {
        &:nth-child(1) {
          width: 100px;
          max-width: 100px;
          min-width: 60px;
        }
        &:nth-child(2) {
          width: 100px;
          max-width: 100px;
          min-width: 50px;
        }
        &:nth-child(3) {
          width: 120px;
          max-width: 120px;
          min-width: 80px;

          div {
            span {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow-x: hidden;
            }
          }
        }
        &:nth-child(7) {
          width: 40px;
          min-width: 40px;
          max-width: 40px;
        }
      }
    }

    thead {
      background-color: transparent !important;
      border: 0;

      tr {
        border: 0;

        th.ant-table-selection-column {
          align-items: center;
          vertical-align: middle;
          border-bottom: unset;
          & > span {
            transform: translateY(3px);
          }
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: fit-content;
            width: fit-content;
          }
          .ant-checkbox-inner::after {
            display: initial;
          }
        }

        th:not(.ant-table-selection-column) {
          font-size: 10px;
          line-height: 18px;
          letter-spacing: 0.01em;
          color: #191919;
          opacity: 0.5;
          border: 0;
          padding: 5px 8px;

          @media (max-width: 768px) {
            font-size: 7px;
            line-height: 9px;
          }

          div {
            height: 20px !important;

            .ant-table-column-title {
              white-space: pre-wrap;
            }
          }

          span {
            //height: 10px !important;
            font-weight: 500;
            color: #85868b;
          }
        }
      }
    }
    tbody {
      tr.clientTable-row {
        cursor: pointer;
      }

      tr:nth-last-child(2) > td {
        border-bottom: none;
      }
      tr.expanded > td {
        border-bottom: none;
      }
      tr:first-child.expanded > td {
        border-top: 1px solid #e5e5e5;
      }
      tr.expanded > td:first-child,
      tr.clientForm-row > td:first-child {
        border-left: 1px solid #e5e5e5;
      }
      tr.expanded > td:last-child,
      tr.clientForm-row > td:last-child {
        border-right: 1px solid #e5e5e5;
      }
      tr.clientForm-row > td,
      tr:last-child.clientForm-row > td {
        border-bottom: 1px solid #e5e5e5;
      }
      tr.clientForm-row > td {
        padding: 0 !important;
      }
      tr > td {
        padding: 0 5px;
        font-size: 12px;
        height: 40px;

        .ant-carousel {
          font-size: inherit;
        }

        .ant-checkbox-inner::after {
          display: initial;
        }
        .ant-clients-table-actions {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          & > div:last-child:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
.clientsTabShow {
  .myTableClass {
    .ant-empty {
      padding-top: 30px;
      padding-bottom: 15px;

      @media (max-width: 499px) {
        padding-top: 0px;
        padding-bottom: 0px;

        .ant-empty-image {
          height: 50px;
        }
      }
    }
  }

  @media (max-width: 499px) {
    & > .clients-table {
      .ant-table {
        background: #fff;
        // padding: 5px;
        padding-top: 0;
      }
    }
  }
}
// }
.ant-table-small {
  border: 0 !important;
}
.myTableClass {
  @extend %myTableClass;
  table {
    border-spacing: 0 !important;
    thead {
      tr > th {
        padding-bottom: 0 !important;
        padding-top: 5px !important;
      }
      tr > th:last-child {
        @extend %actions;
      }
    }
    tbody {
      tr > td:nth-child(1) {
        @extend %name !optional;
        @extend %reviews;
      }
      tr > td:nth-child(2) {
        @extend %relation !optional;
      }
      tr > td:nth-child(4) {
        @extend %notes !optional;
      }
      tr > td:nth-child(5) {
        @extend %email;
        .email {
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
        }
      }
      tr > td:nth-child(6) {
        @extend %phone;
      }

      /*  tr > td:nth-child(6) {
        @extend %reviews;
      } */
      tr > td:nth-child(7) {
        @extend %actions;
      }
    }
  }

  .ant-empty {
    padding-top: 30px;
    padding-bottom: 15px;

    @media (max-width: 499px) {
      padding-top: 0px;
      padding-bottom: 0px;

      .ant-empty-image {
        height: 50px;
      }
    }
  }
}

.myTableClass-selectable {
  @extend %myTableClass;
  table {
    thead {
      /* tr > th:nth-child(7) {
        @extend %reviews;
      } */
      tr > th:last-child {
        @extend %actions;
      }
    }
    tbody {
      .clientTable-row {
        cursor: pointer;
      }
      tr:not(.clientTable-row) {
        & > td.ant-table-selection-column {
          display: none;
        }
        & > td:not(.ant-table-selection-column) {
          background: transparent !important;
          .formClientWrapper > .add-or-update-client-form {
            // margin: 0 !important;
          }
        }
      }
      tr > td:nth-child(2) {
        @extend %name !optional;
        @extend %reviews !optional;
      }
      tr > td:nth-child(3) {
        @extend %relation !optional;
      }
      tr > td:nth-child(5) {
        @extend %notes !optional;
      }
      tr > td:nth-child(6) {
        @extend %email;
        .email {
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
        }
      }
      tr > td:nth-child(7) {
        @extend %phone;
      }
      /*  tr > td:nth-child(7) {
        @extend %reviews;
      } */
      tr > td:nth-child(8) {
        @extend %actions;
      }
    }
  }
}

.myTableClass .myTableClass-selectable .myTableClass-deletable {
  table {
    tr > th:nth-child(8),
    tr > td:nth-child(8) {
      max-width: 95px !important;
      min-width: 95px !important;
      width: 95px !important;
      white-space: nowrap;
    }
  }
}
.myTableClass .myTableClass-deletable {
  table {
    tr > th:nth-child(7),
    tr > td:nth-child(7) {
      max-width: 95px !important;
      min-width: 95px !important;
      width: 95px !important;
      white-space: nowrap;
    }
  }
}

.clients-table {
  overflow: scroll;
  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0px 2px;
  }

  @media (max-width: 499px) {
    .ant-table-small > .ant-table-content > .ant-table-body {
      margin: 0px 2px;

      & > table {
        border-spacing: 0 !important;
        min-width: 100%;
        thead {
          tr > th {
            font-size: 6px;
            &:nth-child(6) {
              display: none;
            }
          }
        }
        tbody {
          tr > td {
            font-size: 8px;
            div.rest-items {
              font-size: 8px;
            }
            .rating-count {
              font-size: 8px;
            }
          }
        }

        & > tbody > tr {
          position: relative;

          &:hover,
          &:active {
            td {
              background-color: #fff !important;
            }
          }
        }

        & > thead > tr > th,
        & > tbody > tr > td {
          line-height: 1.33;

          &:nth-child(1) {
            .client-rating {
              .MuiRating-sizeSmall {
                font-size: 10px;
              }
            }
          }
          &:nth-child(2) {
            white-space: pre-wrap;
            div > span {
              text-align: left !important;
            }
          }
          &:nth-child(3) {
            width: 90px;
            max-width: 90px;
            min-width: 80px;
          }
          &:nth-child(5),
          &:nth-child(4) {
            width: 80px;
            max-width: 80px;
            min-width: 80px;

            .ant-carousel {
              font-size: 8px;
              width: initial !important;
              min-width: initial !important;

              .slick-dots {
                display: none !important;
              }

              .many {
                position: relative;

                &::after {
                  position: absolute;
                  content: '...';
                  width: 100%;
                  top: 7px;
                  text-align: center;
                  font-weight: bold;
                  letter-spacing: 1px;
                }
              }
            }
          }
          &:nth-child(6) {
            display: none; //temp
            position: absolute;
            left: 0;
            background-color: transparent !important;
            border-bottom: 0;
            margin-top: 10px !important;
            & > div {
              width: 50px !important;
              .MuiRating-sizeSmall {
                font-size: 8px;
              }

              .vote {
                font-size: 6px !important;
              }
            }
          }
          &:nth-child(7) {
            // display: none;
            width: 15px;
            max-width: 15px;
            min-width: 15px;
            .custom-toggle-btn {
              margin-left: 0;
              width: 12px;
              min-width: 12px;
              max-width: 12px;
              height: 12px;
              border: none;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

div.rest-items {
  padding: 3px 3px;
  margin-left: 5px;
  // color: #722ed1;
  // background: #dfaeff;
  // border-color: #d3adf7;
  // box-sizing: border-box;
  color: #4285f4;
  background: rgba(76, 83, 239, 0.08);
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  height: fit-content;
  border-radius: 4px;
  white-space: nowrap;
  //float: right;
  cursor: default;
  //margin-left: 20%;
  //transform: translateX(50%);
}

.attached-client-delete-confirm {
  display: block;
  text-align: center;
  cursor: pointer;

  & .common-table-actions {
    justify-content: center;
  }
}
