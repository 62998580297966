%columns {
  .drag-icon {
    width: 25px;
  }

  .description,
  .term,
  .date {
    flex: 0 0 100%;
    // background: transparent;
  }

  .price-section {
    display: flex;
    align-items: center;

    .diffPrice {
      font-weight: 500;
      // font-size: 14px;
      width: 85px;
      white-space: nowrap;

      @media (max-width: 960px) {
        font-size: 10px;
        width: 60px;
      }
    }

    .price-per-unit {
      width: 90px;
      opacity: 0.5;
    }

    .qty {
      background: transparent;
      width: 70px;
      font-weight: 500;

      @media (max-width: 960px) {
        width: 60px;
      }
      // font-size: 0.7rem;
    }
    .price {
      background: transparent;
      width: 91px;
      white-space: nowrap;

      &.disabled {
        opacity: 0.5;
      }

      &.hide {
        opacity: 0;
      }

      @media (max-width: 960px) {
        width: 65px;
      }

      // @media(max-width:499px) {
      //   width: 35px !important;
      // }
    }
  }

  .date {
    width: 150px;
    background: transparent;
    .date-picker {
      height: calc(1.8125rem + 2px);
    }
  }
  .term {
    background: transparent;
    width: 150px;
  }

  .actions {
    background: transparent;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    cursor: initial;
    flex-wrap: wrap;
    width: 125px;
    // justify-content: flex-end;
    i {
      cursor: pointer;
      margin: 2.5px 3px;
    }
  }
}

%rows {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
  /*  & > div:not(:last-child) {
    margin-right: 20px;

    @media (max-width: 499px) {
      margin-right: 5px;
    }

    &.price {
      margin-right: 20px;
    }
  } */
}

.table-items.Warranty {
  .dnd-items-container {
    .dnd-item {
      &:nth-child(1) {
        .term {
          position: relative;
        }
      }
    }
  }
}

.table-items {
  margin-left: 20px;
  position: relative;

  .dragIcon {
    position: absolute;
    top: -3px;
    left: -19px;
  }

  .dnd-items-container {
    padding-left: 25px;

    .dnd-item {
      &.loading {
        opacity: 0.5;
        pointer-events: none;
      }
      .description {
        white-space: break-spaces;
      }
    }

    & > * {
      &.options + div {
        .proposal-row {
          border-top: none;
        }
      }
      &:last-child:not(.options):not(button) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      }
    }
  }

  &.Instants,
  &.Rebates {
    .dnd-items-container {
      & > .dnd-item-parent {
        /*  &:nth-child(1) {
          .date {
            position: relative;

            &:before {
              content: 'Expiration';
              position: absolute;
              top: -12px;
              font-size: 10px;
              color: #191919;

              opacity: 0.5;

              @media (max-width: 499px) {
                top: -8px;
                font-size: 6px;
              }
            }
          }
        } */

        &.dnd-items-options__wrap {
          .dnd-item-parent {
            &:not(:nth-of-type(1)) {
              .date {
                &:before {
                  content: '';
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    margin-left: 10px;

    .dnd-items-container {
      margin-left: 10px;
    }

    .dragIcon {
      left: -14px;
    }

    &.Instants,
    &.Rebates,
    &.Warranty {
      .price-section {
        .qty,
        .price-per-unit {
          display: none;
        }

        .diffPrice {
          width: 70px !important;
        }
      }
    }
  }
}

.proposal-preview,
.preview-page-layout {
  &.vertical-navigation {
    margin-top: 130px;
  }

  .dnd-items-container {
    width: 100%;
    font-size: 12px;

    @media (max-width: 960px) {
      font-size: 10px;
    }

    .dnd-item {
      @extend %rows;
      @extend %columns;

      // &:not(:first-child) {
      border-top: 1px solid rgba($color: #000000, $alpha: 0.05);
      position: relative;
      // }

      &.item-option {
        position: relative;
        padding-top: 8px;
        padding-left: 35px;

        & > *:not(span) {
          opacity: 0.4;
        }

        &.active {
          & > * {
            opacity: 1;
          }
        }

        .option-radio {
          position: absolute;
          left: -5px;
          top: 0;
        }

        .price-section {
          position: relative;
          right: -5px;
        }

        &:before {
          position: absolute;
          content: 'Please select one of the options:';
          left: 15px;
          font-size: 12px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.26);
          top: -10.5px;
          background-color: #fff;
          padding: 0 5px;

          @media (max-width: 500px) {
            font-size: 10px;
            top: -8px;
          }
        }

        & ~ .item-option {
          // padding-top: 5px;

          &:before {
            content: '';
            background-color: unset;
          }
        }

        &:first-child {
          border-top: none;
        }
        /*  & ~ :not(.item-option) {
          border-top: 1px solid rgba($color: #000000, $alpha: 0.05);
        } */

        /* &:not(:last-child) {
          &:after {
            position: absolute;
            content: '';
            width: 44px;
            height: 2px;
            bottom: -1px;
            left: 0;
            background-color: #fbfdff;
          }
        } */
      }

      &.expired {
        & > *:not(span) {
          opacity: 0.4 !important;
        }
      }

      &:not(.item-option):last-child {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.05);
      }
    }

    .dnd-items-options__wrap {
      border: 1px solid rgba($color: #000000, $alpha: 0.15);
      border-radius: 4px;
      margin-top: 4px;
      padding: 0 5px;

      & + .dnd-item {
        border-top: none;
      }
    }

    @media (max-width: 499px) {
      font-size: 8px;

      .dnd-item {
        padding: 5px;
        flex-wrap: wrap;

        .actions {
          /*  flex: 0 1 100%;
          justify-content: start; */
          width: 120px;

          & > div {
            width: initial !important;
            height: initial !important;
          }
          i {
            margin-top: 5px;

            & > div > div {
              width: 20px !important;
              height: 20px !important;
              margin-right: 0 !important;

              div {
                font-size: 20px !important;
              }
            }
          }
        }

        .price-section {
          .price-per-unit {
            width: 50px;
          }
          .qty {
            width: 40px;
          }

          .diffPrice {
            width: 45px;
            font-size: 8px;
          }

          .price {
            width: 45px;
          }

          .date {
            width: initial;
          }
        }

        &.item-option {
          padding-left: 15px;
          padding-top: 5px;
        }
      }
    }
  }

  .dnd-items-header {
    width: 100%;
    font-size: 14px;
    font-weight: 700;
  }

  .new-item-row {
    width: 100%;
    @extend %rows;
    @extend %columns;
    .ant-select-show-search {
      width: 100%;
    }
  }
}
