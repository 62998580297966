%clients-tab-service-contract {
  /* box-sizing: border-box;
  margin: 20px 0;
  padding: 0 20px 20px 20px;
  border: 1px solid rgb(211, 211, 211);
  border-radius: 10px; */
  //box-shadow: 1px 1px 6px 1px rgba(140, 140, 140, 0.79), -1px -1px 6px 1px rgba(140, 140, 140, 0.79);
  margin-bottom: 20px;
  overflow-x: auto;
}

%action {
  cursor: pointer;
  text-align: center;
  .mdi {
    font-size: 20px;
  }
  .fas {
    font-size: 20px;
    margin-top: 8px;
  }
  .far {
    font-size: 20px;
    margin-top: 8px;
  }
}
.service-contract-form {
  overflow-x: auto;
  .clients-tab-service-contract {
    @extend %clients-tab-service-contract;
  }

  .service-block {
    margin: 20px auto;
    padding: 5px;

    .freeze {
      position: relative;
      pointer-events: none;

      .cover {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(223, 223, 223, 0.603);
      }
    }
  }
  margin: auto;
  .header-fields {
    .fa-copy:hover,
    .fa-eye:hover {
      color: #1890ff;
    }
    .f-column {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .service-tocken {
      text-align: center;
      align-self: center;
      font-size: 20px;
    }
    .actions {
      display: flex;
      justify-content: space-around;
      width: 100%;
      .service-contract-name {
        width: 35%;
      }
      .service-tocken {
        width: 15%;
      }
      .mdi {
        font-size: 40px;
        line-height: 40px;
        text-align: center;
        width: 7%;
      }
      .btn {
        width: 10%;
      }
    }
    .address-fields {
      display: flex;
      .address {
        display: flex;
        justify-content: space-between;
        .mdi {
          font-size: 40px;
          text-align: center;
          line-height: 40px;
        }
      }
      .text-area {
        textarea {
          min-height: 80px;
          max-height: 280px;
        }
      }
    }
  }
  .service-contract-client-table-area {
    .clients-table-field {
      width: 100%;
      background: #38a4f8;
      padding: 5px;
      margin-top: 20px;
      .text {
        margin-right: 10px;
        font-size: 20px;
        color: white;
      }

      button {
        border-radius: 20px;
        border: none;
        background: #343a40;
        color: white;
        font-size: 18px;
      }
    }
    .service-contract-clients-table-layout {
      width: 100%;
      .service-contract-clients {
        width: 100%;
        table {
          min-width: 100%;
          max-width: 1100px !important;
          margin-top: 0;
        }
        .notes {
          max-width: 100px;
          text-overflow: ellipsis;
          overflow-x: hidden;
          white-space: nowrap;
        }
      }
    }
  }
  .action {
    @extend %action;
  }
  .billing-information {
    margin-top: 20px;
    .actions-upload {
      display: flex;
      //align-items: center;
      justify-content: space-around;
      .action {
        padding-top: 30px;
      }
      .textarea {
        width: 40%;
        textarea {
          min-height: 70px;
          max-height: 200px;
        }
      }
    }

    .dropzone-custom {
      cursor: pointer;
    }
    .upload-btn {
      text-align: center;
      font-size: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 0;
    }
  }
  .customer-information {
    .fa-copy:hover,
    .fa-eye:hover {
      color: #1890ff;
    }
    .action {
      @extend %action;
    }
  }
  .service-contract-table {
    h1 {
      text-align: center;
    }
    table {
      margin: 20px auto;
    }
    thead {
      background-color: #f5f6fa;
      th {
        font-size: 20px;
      }
    }
    .inputs {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      flex-wrap: wrap;
      .form-group {
        margin: 5px;
        max-width: 100%;
        input {
          min-width: 100px;
        }
      }
      .fas {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
        //font-size: 20px;
        line-height: 20px;
        //color: red;
      }
    }
  }
  .gallery {
    @keyframes animate-stripes {
      0% {
        background-position: 0 0;
      }

      100% {
        background-position: 60px 0;
      }
    }
    .progress-image-load {
      width: 100%;
    }
    .progress-percent {
      display: flex;
      justify-content: center;
      font-size: 20px;
    }
    .progress-bar {
      background-color: #1a1a1a;
      height: 45px;
      width: 450px;
      margin: 20px auto;
      border-radius: 5px;
      box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
    }

    .stripes {
      background-size: 30px 30px;
      background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
      );
    }

    .stripes.animated {
      animation: animate-stripes 0.6s linear infinite;
    }
    .progress-bar-inner {
      display: block;
      height: 45px;
      width: 0%;
      background-color: #34c2e3;
      border-radius: 3px;
      box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
      position: relative;
    }
    .images {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      .image {
        height: 150px;
        width: 150px;
        margin: 10px;
        position: relative;
        img {
          height: 100%;
          width: 100%;
        }
        i {
          position: absolute;
          top: -5px;
          right: -5px;
          color: white;
          font-size: 20px;
          line-height: 20px;
          border-radius: 90px;
          background: red;
          &:hover {
            background: white;
            color: red;
          }
        }
      }
    }
  }
  .instance {
    .service-instance-table {
      tr > td:nth-child(1) {
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
      }
      tr > td button {
        padding: 2px 8px;
        font-size: 24px;
      }

      tr > td:nth-child(1),
      th:nth-child(1) {
        width: 50%;
      }
      tr > td:nth-child(2),
      th:nth-child(2) {
        width: 20%;
      }
      tr > td:nth-child(3),
      th:nth-child(3) {
        width: 20%;
      }
      tr > th:nth-child(3) {
        text-align: center;
      }
      tr > td:nth-child(4),
      th:nth-child(4) {
        display: flex;
        justify-content: space-around;
        width: 100px;
      }
      tr > td:nth-child(1) .ant-select-auto-complete {
        width: 100%;
      }
    }
    .investment {
      display: flex;
      justify-content: space-between;
    }
    .instance-params {
      display: flex;
      justify-content: flex-start;
      .instant-container {
        width: 100%;
        display: flex;
        .instant {
          margin-left: auto;
        }
      }
    }
    .total {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 14px;
      margin: 40px 0;
      background: #b8babb;
      border: 1px solid black;
    }
    .description {
      width: 50%;
      max-width: 500px;
      margin: 50px auto;
      border: 1px solid grey;
      padding: 20px;
      position: relative;
      .desc {
        text-align: left;
        font-size: 16px;
      }
      .action {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
  .client-form {
    padding: 20px;
  }
  .customer-information {
    .information {
      display: flex;
      justify-content: space-around;
      width: 100%;
      textarea {
        width: 60%;
      }
    }
    .dropzone-custom {
      cursor: pointer;
      .upload-btn {
        font-size: 20px;
        text-align: center;
        margin-bottom: 0;
      }
    }
    .btns {
      display: flex;
      padding: 14px;
      margin: 40px 0;
      background: #b8babb;
      border: 1px solid black;
      button {
        font-size: 18px;
      }
      .btn-info {
        margin-left: auto;
      }
      a {
        align-self: center;
        margin: 0 10px;
      }
      i {
        font-size: 30px;
        color: black;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .text {
        text-decoration: underline;
        background: none;
        border: none;
      }
    }
  }
}

.clients-activity {
  max-width: 650px;

  .activity-list {
    max-height: 350px;
    overflow-y: auto;
    border: 1px solid;
    padding: 15px;

    .activity-item {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      span {
        padding: 0 5px;
        flex: 0 0 160px;
        white-space: nowrap;
        line-height: 1.3;

        &:first-child {
          text-align: right;
        }

        &:nth-child(2) {
          text-align: center;
        }

        &:not(:last-child) {
          border-right: 1px solid;
        }
      }
    }
  }

  .modal-footer {
    margin: 0 0 10px;
  }
}

.modal-files {
  .gallery {
    .images {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      .image {
        height: 150px;
        width: 150px;
        margin: 10px;
        position: relative;
        img {
          height: 100%;
          width: 100%;
        }
        i {
          position: absolute;
          top: -5px;
          right: -5px;
          color: white;
          font-size: 20px;
          line-height: 20px;
          border-radius: 90px;
          background: red;
          &:hover {
            background: white;
            color: red;
          }
        }
      }
    }
  }
}
