.chat-wrapper {
  .chat-container {
    //display: flex;
    //flex-direction: column;

    .loading {
      display: flex;
      align-items: center;
    }

    &.scrollable {
      //overflow-y: scroll;
      //overflow-x: hidden;
      //max-height: 65vh;
      //padding: 0 10px;
    }
  }

  .chat-input {
    height: auto;
    //width: 94% !important;
  }

  .attach-file-icon {
    cursor: pointer;
    order: 2;
  }

  .send-button {
    width: 100%;
    margin-top: 5px;
  }
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  max-width: 1900px;

  .chat-header {
    //height: 80px;
  }
  .chat-list {
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 10px;
  }
  .chat-footer {
    max-width: 90%;
    //padding-bottom: 10px;
  }
}

.chat-footer {
  .disabledIcon {
    color: grey;
    pointer-events: none;
    cursor: not-allowed;
  }
}
