.modal-file-upload {
  &__action-buttons {
    display: flex;
    justify-content: center;
  }

  .add-line {
    cursor: pointer;
    // background: #626ed4;
    // border-color: #626ed4;
    // color: white;
    margin-top: 7px;
    padding: 8px 0px;
    font-size: 14px;
    text-decoration: underline;
    // box-shadow: 0px 0px 3px black;
  }

  .upload-file-input {
    margin-bottom: 20px;
  }

  .save-btn {
    font-size: 14px;
    padding: 6px 12px;
  }

  .upload-files {
    &__upload-by-link {
      & > p {
        text-align: center;
      }

      .link-upload {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }

    &__dropzone {
      background: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 100px;
      max-width: 80%;
      margin: 0 auto 20px;
      transition: border-color 0.3s;

      .upload-drag-icon {
        margin-bottom: 10px;

        span {
          font-size: 48px;
          color: #858aff;
        }
      }

      .upload-drag-text {
        font-size: 16px;
      }

      &:hover {
        border-color: #747474;
      }
    }
  }

  .uploaded-files {
    border-top: 1px solid;

    .row-upload {
      display: flex;
      margin: 10px 0;

      .actions {
        flex: 0 0 65px;
        margin-left: 20px;

        .custom-table-actions {
          justify-content: space-between;
        }
      }

      i {
        font-size: 26px;
        align-self: center;
        margin: 0 8px;
        cursor: pointer;
      }
      .fa-check {
        margin: 0 5.5px;
      }
    }
  }

  @media (max-width: 499px) {
    .modal-body {
      padding: 10px;

      h2 {
        margin-bottom: 10px !important;

        & + div {
          font-size: 10px;
          margin-bottom: 0 !important;
          display: none !important; //temp
        }
      }

      .row-upload {
        .actions {
          flex: 0 0 40px;
          margin-left: 10px;
        }
      }
    }
  }
}
.isError {
  color: red;
}
.isNotError {
  color: black;
}
