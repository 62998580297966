.image-renderer{
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    & .image-img{
        max-width: 95%;
        max-height: 95%;
    }
}


.msdoc-renderer{
    width: 100%;
    height: 100%;
    & iframe{
        width: 100%;
        height: 93%;
        border: 0;
    }
}

.tif-wrapper{
    & canvas{
        max-width: 95%;
        max-height: 95%;
    }
}

.renderer-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    overflow-y: auto;
    height: 100%;
}

.video-wrapper{
    & video{
        width: 100%;
        max-width: 700px;
        
    
    }
}

.txt-renderer{
    display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
}

.pdf-wrapper{
    display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin-top: -20px;
}

.file_item{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: zoom-in;
    margin: 0 2px;
}

.pdf-page-info{
    padding: 0 0 10px 10px;
    font-size: 14px;
    text-align: left;
  
    @media (max-width: 768px) {
      font-size: 10px;
    }
}

.pdf-renderer__wrapper{
    display: flex;
  flex-direction: column;
  margin: 0 auto;
  height: 90vh;
  & .pdf-page{
      margin: 10px 0;
      border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px rgb(30, 30, 30);
    border-radius: 5px;
  }

  & .page-number{
      color: white;
      text-align: center;
      font-size: 10px;
  }

  & .spin-pdf{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 30px;
    height: 30px;
  }

 
}

.txt-renderer{
    background-color: white;
    height: 90vh;
    overflow: auto;
    width: 90%;
}

.html-renderer{
    display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
  & .html-body{
    height: 90vh;
    padding: 15px;
    margin: 20px 0 20px 0;
    border: 1px solid black;
  }
}

.msg-renderer{
    display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
}

.msg-sender{
    padding: 0 15px 15px 15px;
  margin-top: 20px;
  border: 1px solid black;

}

.msg-recipient{
    padding: 0 15px;
    margin-top: 20px;
    border: 1px solid black;

}
.msg-body{
    height: 100%;
    padding: 15px;
    margin: 20px 0 20px 0; 
    border: 1px solid black;

}

.image-name{
  word-break: break-all;
}

.renderer-iframe{
  width: 100%;
  height: 100%;
  border: none !important;

}

.iframe-wrapper{
  position: relative;
  width: 100%;
  height: 100%;

}

