.modal-dialog {
  &.xl-modal-w {
    max-width: 1000px;
  }

  // &>.modal-content {
  //   &>.modal-header {
  //     &>* {
  //       display: block;
  //     }
  //   }
  // }
}
