@import '../../../Layouts/AuthLayout/layoutConstants';
.proposal-form {
  position: relative;

  .card-body {
    padding: 15px 10px;
  }

  // @extend %settings;
  &-industries {
    width: 140px;
  }
  &-compact-view {
    margin: 0 1.5rem;

    @media (max-width: 499px) {
      margin: 10px 10px 10px 0;
    }
  }
  &-information_for_customer {
    width: 100%;
    margin-bottom: 10px;

    @media (max-width: 499px) {
      margin: 10px 0;
    }
  }
  &-footer_buttons_block {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    /* & > div {
      margin-bottom: 1rem;
    } */
    & > div:first-child {
      width: auto;
      button:not(:last-child) {
        margin-right: 25px;
      }
    }
    & > div:last-child {
      margin-left: 35px;
      margin-right: -5px;
      display: flex;
      & > button {
        margin: 0 5px;
        // height: 40px;
        padding: 6px 12px;
      }

      & > button:first-child {
        border: 1px solid #4c53ef12;
        color: #4285f4;
        background: #4c53ef12;
        border-radius: 4px;

        &:hover {
          background: #4c53ef29;
          transition: all 0.3s;
        }
      }
    }

    @media (max-width: 499px) {
      & > div:first-child {
        button:not(:last-child) {
          margin-right: 15px;
        }
      }
    }
  }
  .page-title-box {
    padding: 0;
  }
  &-helper-label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #00000080;
    white-space: nowrap;
    position: relative;
    &-label_action {
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(110%, 2px) scale(0.9);
      button.calendar {
        path {
          opacity: 1;
        }
      }
      button.calendar:not(:hover) {
        path {
          fill: #191919;
        }
      }
    }
  }
  &-address {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #191919;
    margin-bottom: 1rem;
  }
  &-follow_up_and_template_btns {
    display: flex;
    flex-wrap: wrap;
    margin: 0 8px;
    button {
      margin: 0 5px 1rem;
    }
    button:first-child {
      width: 230px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.01em;
      color: #000000;
      background: #ffffff;
      border: 1px solid #d6d6d6;
    }
    button:first-child:hover {
      background: #f2f3fe;
      transition: all 0.3s;
    }
    button:not(:first-child) {
      width: 145px;
    }
  }

  @media (max-width: 499px) {
    .proposal-form-follow_up_and_template_btns {
      flex-wrap: nowrap;

      button {
        font-size: 8px;
        flex: 0 1 30%;
      }
    }

    .MuiTextField-root {
      label {
        font-size: 8px;
        line-height: 8px;

        &.MuiInputLabel-outlined.MuiInputLabel-marginDense {
          transform: translate(14px, 6px) scale(1);
        }

        &.MuiInputLabel-outlined.MuiInputLabel-shrink {
          transform: translate(14px, -3px) scale(0.75);
        }
      }
      .MuiInputBase-root {
        font-size: 8px !important;

        .MuiOutlinedInput-input[type='password'] {
          padding: 0.5px 10px !important;
        }

        .MuiSelect-root {
          padding: 5.2px 10px;
        }

        /*  input {
          padding: 0.5px 10px;
          height: 20px;
        } */
      }
    }

    .my-button-class {
      height: 25px;
      font-size: 0.8rem;
    }
  }

  .ant-input {
    :hover {
      border-color: #2e5bff;
    }

    &:focus {
      box-shadow: none;
      border-color: #2e5bff;
    }
  }

  .proposal-form-header > .proposal-form-header-part1 {
    // align-items: center;
    align-items: center;
  }

  .appointmentData {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
  }

  /*   .proposal-form-tabs_content-tab_section-wrapper-tab {
    background-color: #fff;
  } */

  &.compact-view {
    .proposal-form-tabs_content-tab_section-wrapper-content-divider-freeze {
      display: none;
    }

    .btn-set-default {
      height: 25px;
    }

    .proposal-form-header-part2
      > .proposal-form-header-col:nth-child(2)
      button {
      height: 25px;
    }

    /* .my-button-class {
      font-size: 12px;
      height: 25px;
    } */

    /*.MuiTextField-root .MuiSelect-outlined.MuiSelect-outlined {
      padding: 2px 32px 4px 14px;
    }*/
    /* .MuiOutlinedInput-inputMarginDense {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
      font-size: 12px;
      // height: 19px !important;
    } */

    .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense {
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 12px;
    }

    .proposal-section-table-item-header
      > div:not(.proposal-form-tabs_content-tab_section-wrapper-content-items_name) {
      font-size: 10px;
    }

    .proposal-section-table .actions {
      height: 20px;
    }

    .ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered,
    .ant-select-lg .ant-select-selection__rendered {
      line-height: 25px;
    }

    .proposal-section-table .proposal-row {
      // margin-bottom: 15px;
    }

    .proposal-section-table.option .proposal-row {
      margin-bottom: 0px;
    }

    .proposal-section-table
      .proposal-new-row
      .proposal-input-cell
      .ant-select-lg
      .ant-select-selection__placeholder,
    .ant-select-lg,
    .proposal-form-tabs_content-tab_section-wrapper-content-items_name,
    .proposal-form .MuiTextField-root .MuiOutlinedInput-root,
    .ant-select-dropdown-menu-item,
    .MuiInputBase-root,
    .MuiTypography-colorTextSecondary {
      font-size: 12px;
    }

    .proposal-form-tabs_content-tab_section-wrapper-content-total_table
      tbody
      tr:not(:last-child)
      > td,
    .proposal-form-tabs_content-end_of_system-subtotal_table
      tbody
      tr:not(:last-child)
      > td,
    .proposal-form-tabs_content-end_of_system-total_table
      tbody
      tr:not(:last-child)
      > td {
      padding-bottom: 10px;
    }

    .proposal-tax-form-add {
      margin-top: 5px;
    }

    .proposal-tax-form-summary-item {
      margin-bottom: 15px;
    }

    .proposal-form-tabs_content-tab_section-wrapper-content-total_table_wrapper:first-child {
      margin: 10px 0 10px 10px;
    }

    .proposal-form-tabs_content-tab_section-wrapper-tab-title {
      height: 33px;
    }

    .proposal-section-table .proposal-row .proposal-input-cell input,
    .proposal-section-table .proposal-new-row .proposal-input-cell input,
    .proposal-section-table .proposal-row .proposal-input-cell,
    .proposal-section-table .proposal-row .proposal-input-cell .ant-select,
    .ant-select-auto-complete.ant-select-lg .ant-input {
      height: 25px;
      font-size: 12px;
      box-sizing: border-box;

      @media (max-width: 499px) {
        font-size: 8px;
        height: 20px;
        padding: 0px 5px;
      }
    }

    .proposal-form-tabs_content-end_of_system-discounts_table {
      padding: 10px 15px;
    }

    .ant-divider-horizontal {
      margin: 0;
      opacity: 0;
    }

    .proposal-form-tabs_content-tab_section-wrapper-content-total_table
      tbody
      tr
      > td,
    .proposal-form-tabs_content-end_of_system-subtotal_table tbody tr > td,
    .proposal-form-tabs_content-end_of_system-total_table tbody tr > td {
      font-size: 14px;
    }

    .proposal-form-tabs_content-tab_status {
      font-size: 25px;
      margin: 20px 0 10px;
    }

    .proposal-form-tabs_content-end_of_system__discounts-underline--first,
    .proposal-form-tabs_content-end_of_system__discounts-underline--second {
      height: 10px;
    }

    .proposal-form-tabs_content-end_of_system-subtotal_table,
    .proposal-form-tabs_content-end_of_system-total_table {
      margin: 10px 0 !important;
    }

    /* .proposal-form .dnd-items-container .MuiTextField-root {
      margin-bottom: 10px;
    } */

    .proposal-form-tabs_content-tab_section-wrapper {
      &-content > .table-items {
        margin-bottom: 10px;
      }
      margin-bottom: 10px;
    }

    .ant-form-item-control {
      line-height: 25px;
    }

    .proposal-form-tabs_content {
      padding-bottom: 15px;
    }

    .proposal-form-tabs_content-tab_section-wrapper-content-total_table_wrapper:last-child {
      padding-bottom: 10px;
    }

    .proposal-section-table.option {
      padding: 2px 9px 2px 8px;
      // margin-bottom: 15px;
    }

    .custom-toggle-btn {
      width: 25px;
      min-width: 25px;
      max-width: 25px;
      height: 25px;
    }

    .proposal-section-table-item-header
      > div:not(:last-child):not(:first-child) {
      margin: 5px 0;
    }

    .proposal-form-tabs_content-tab_section-wrapper-content
      > .table-items
      + :not(.table-items) {
      margin-top: -5px;
    }

    .proposal-form-tabs_content-tab_section-wrapper-tab-title {
      font-size: 12px;
    }

    .MuiInputLabel-outlined.MuiInputLabel-marginDense {
      transform: translate(14px, 7px) scale(1);
      font-size: 12px;
    }

    .MuiTextField-root
      .MuiOutlinedInput-root
      textarea.MuiOutlinedInput-inputMarginDense,
    .MuiFormControl-root
      .MuiOutlinedInput-root
      textarea.MuiOutlinedInput-inputMarginDense {
      padding: 5.5px 15px;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      font-size: 12px;
      transform: translate(16px, -6px) scale(0.75);
    }

    .proposal-form-tabs_content-tab_section-wrapper-tab {
      padding: 5px 30px;
      font-size: 12px;

      @media (max-width: 499px) {
        padding: 5px 10px;
      }
    }

    .proposal-form-header-part1 > .proposal-form-header-col:nth-child(1) {
      font-size: 18px;
    }

    .proposal-form-header-part1 > .proposal-form-header-col:nth-child(2) {
      font-size: 14px;
    }
  }

  .ant-switch-checked {
    background-color: #4285f4;
  }
}
.proposal-form-transparent {
  //opacity: 0.5;
  /*  & > .proposal-form-lock {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none !important;
    cursor: none !important;
  } */
}
.mobile-proposal {
  margin-bottom: 10px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    h4 {
      font-size: 15px;
      margin-bottom: 0;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      font-size: 10px;
      flex: 0 0 110px;

      .btn {
        font-size: 10px;
        &:nth-child(1) {
          background-color: #ffffff;
          border: 1px solid #4c53ef14;
          color: #000;
          box-shadow: unset;
        }
        &:nth-child(2) {
          background-color: #4c53ef14;
          color: #4285f4;
          border: 1px solid #4c53ef14;
          box-shadow: unset;
          margin: 0 10px;
        }
      }
    }
  }

  .mobile-proposal__actions-wrap {
    display: flex;
    flex-wrap: wrap;

    & > .column {
      width: 110px;
    }

    .proposal-form-header-col-status-canceled_info {
      width: 100%;
      margin-top: 10px;
      font-size: 10px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    .MuiTextField-root {
      margin-bottom: 0;
      margin-right: 15px;
    }

    &__proposal-code {
      font-size: 12px;
      flex: 0 0 auto;
    }
  }

  .controls,
  .address {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    .common-table-actions {
      // width: initial;
      button {
        margin-right: 3px;
        padding-top: 0;
        & > .anticon,
        .anticon-calculator > svg {
          width: 28px;
          height: 28px;
        }
      }
    }

    .column {
      flex: 0 0 30%;
      font-size: 8px;

      label.proposal-form-mobile-helper-label {
        font-size: 6px;
      }
    }
  }

  .controls {
    justify-content: flex-end;
    margin: 5px 0;
  }

  .apointment-info {
    display: flex;
    font-size: 8px;
    flex-wrap: wrap;

    & > label {
      flex: 0 0 100%;
      margin-bottom: 0;
    }

    .proposal-form-mobile-appointment {
      // flex: 0 0 70%;
      display: flex;
      flex-wrap: wrap;

      p {
        border-radius: 2px;
        padding: 5px 10px;
        color: black;
        margin-bottom: 5px;

        &.type {
          background-color: #e6fffa;
          margin-right: 5px;

          span {
            color: #45bea6;
          }
        }
        &.source {
          background-color: #ffe5e5;

          span {
            color: red;
          }
        }
      }

      & > span {
        flex: 0 0 100%;
      }
    }
  }

  .proposal-form-installation_dates {
    .proposal-form-installation_dates-table {
      tr > th:not(:first-child),
      tr > td {
        font-size: 8px;
      }
    }

    .ant-checkbox-wrapper {
      font-size: 8px;
    }
  }

  .proposal-form-property-address_notes {
    .proposal-form-helper-label,
    .proposal-form-property-address_notes-submit_block,
    .proposal-form-address {
      display: none;
    }

    .tags-wrapper {
      margin-top: 15px;

      @media (max-width: 499px) {
        margin-top: 10px;
      }
    }
  }

  label.proposal-form-mobile-helper-label {
    font-size: 6px;
  }
}
.proposal-form
  > .proposal-form-header
  > .proposal-form-header-part1
  > .heading__buttons {
  display: flex;
}
//New styles
@media (max-width: 499px) {
  .proposal-form {
    .proposal-form-header {
      margin: 0;
      .proposal-form-header-part1 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        & > .proposal-form-header-col:nth-child(1) {
          width: 100%;
          height: 15px;
          font-size: 1rem;
          margin: 0;
          justify-content: flex-start;
        }
        & > .proposal-form-header-col:nth-child(2) {
          width: 100%;
          height: 15px;
          font-size: 1rem;
          margin: 0;
          justify-content: flex-start;
        }

        .heading__buttons {
          display: flex;
          justify-content: space-between;
          button {
            margin: 0 5px;
          }
        }

        & > .proposal-form-header-col:nth-child(4) {
          margin: 0;
          width: 100%;
          //height: 30px;
          .MuiFormControl-root,
          MuiTextField-root,
          MuiFormControl-fullWidth {
            width: 70%;
          }
          .common-table-actions {
            display: flex;
            justify-content: space-between;
            width: 30%;
            .btn > svg {
              width: 18px;
              height: 20px;
            }
          }
        }
      }
      .proposal-form-header-part2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 10px;
        .proposal-form-header-col:nth-child(1) {
          width: 100%;
          margin: 0;
          .MuiFormControl-root,
          MuiTextField-root,
          MuiFormControl-fullWidth {
            height: 25px;
            .MuiInputBase-root,
            MuiOutlinedInput-root,
            MuiInputBase-fullWidth,
            MuiInputBase-formControl,
            MuiInputBase-marginDense,
            MuiOutlinedInput-marginDense {
              height: 30px;
            }
          }
        }
        .proposal-form-header-col:nth-child(2) {
          //width: 50%;
          margin: 0;
          padding: 10px 0;
          .btn {
          }
        }
      }
    }
    .appointmentData {
      & > div:nth-child(1) {
        width: 100%;
        text-align: center;
        .proposal-form-helper-label {
          margin: 0;
          height: 15px;
          font-size: 0.75rem;
        }
        .proposal-form-appointment,
        .proposal-form-appointment--appointment {
          width: 100%;
          padding: 0 !important;
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: auto;
          margin-bottom: 10px;

          .proposal-form-appointment-type {
            padding: 0;
          }

          .proposal-form-appointment-source {
            margin: 0;
          }

          .proposal-form-helper-label {
          }
        }
      }
      .proposal-form-industries {
        width: 50%;
        margin: 0;
        font-size: 0.75rem;
        .MuiFormControl-root,
        .MuiTextField-root,
        .MuiFormControl-fullWidth {
          width: 90%;
        }
      }
      .settings-proposal-compact-view {
        width: 50%;
        margin: 0;
        font-size: 0.75rem;
      }

      .proposal-form-installation_dates {
        font-size: 0.75rem;
        width: 100%;
        justify-content: space-between;
        margin: 10px 0;
        .proposal-form-installation_dates-calendar {
          width: auto;
        }
      }

      & > .proposal-form-installation_dates > div > .d-flex,
      .w-100,
      .align-items-center
        > .proposal-form-installation_dates-table
        > thead
        > tr
        > th {
        font-size: 0.75rem;
        width: auto;
      }
    }

    .settings-header {
      margin-bottom: 10px;
      .proposal-header {
        margin-bottom: 5px;
        & > pre {
          padding-bottom: 5px;
          span {
            font-size: 1rem !important;
          }
        }
        & > table {
          tbody {
            tr {
              height: 20px !important;
              td {
                pre {
                  font-size: 1rem;
                }
                span {
                  font-size: 1rem !important;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }

    .proposal-contact-info {
      margin: 5px 0;
    }

    .proposal-contact-summary {
      .proposal-contact-summary__section {
        margin: 5px 0 10px 0;
        left: 0;
        .proposal-contact-summary__section-primary {
          //position: relative;
        }
      }
    }

    .proposal-form-follow_up_and_template_btns {
      margin: 0 0 5px 0;
      justify-content: space-between;
      .btn {
        margin: 0 5px 0 0;
      }
    }

    .row > .col > div > .proposal-form-tabs_changer {
    }

    .row > .col > div > .proposal-form-tabs_content {
      padding: 0;
      padding-bottom: 5px;
      .proposal-form-tabs_content-tab_action_block {
        margin: 5px 0;
        padding: 0 5px;
      }
      .proposal-form-tabs_content-info_block {
        padding: 5px;
        &
          > .proposal-form-tabs_content-info_block-notes_and_modules
          > .proposal-form-tabs_content-tab_action_block
          > .proposal-form-tabs_content-tab_action_block-button {
          margin: 0;
          padding: 0;
          height: 30px;
        }
      }
      .proposal-form-tabs_content-tab_status {
        margin-left: 5px;
        font-size: 2rem;
        height: 40px;
      }
    }
  }
}

.accounting-modal {
  @media all and (min-width: 769px) {
    width: 950px !important;
  }
  @media all and (max-width: 768px) {
    width: 95%;
  }
}

.appointmentData {
  @media (min-width: 1260px) {
    margin-top: 20px;
  }
}

.proposal-form
  > .proposal-form-header
  > .proposal-form-header-part2
  > .buttons {
  @media (max-width: 500px) {
    margin-top: 40px !important;
  }
}

.proposal-form-installation_dates {
  flex: 0 100%;
  justify-content: center;
}
