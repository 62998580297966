.matrix-settings {
  .resources {
    //position: relative;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 2px;
    // margin-right: 20px;
    width: auto;
    padding-right: 20px;

    .resource {
      outline: none;
    }

    .draggable-content {
      display: flex;
      // overflow-x: auto;
      // overflow-y: hidden;
      // padding: 2px;
      // margin-right: 20px;
      // width: auto;
    }
  }
}
