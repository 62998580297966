.compact-dropdown > .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body {
  .ant-drawer-header {
    .ant-drawer-header-title {
      button {
        margin-left: 12px;
      }
    }
  }
  .ant-drawer-body > ul > li {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 10px;
  }
}
