.loading-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 1000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
