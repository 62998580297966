.address-input {
  .ant-select-show-search {
    width: 100%;
  }
}

.client-properties {
  //border: 1px solid #d6d6d6;
  //border-radius: 4px;
  padding: 10px;
  // margin-bottom: 25px;

  .attach-new-property {
    margin-bottom: 10px;

    .relation-input {
      width: 49%;
      margin-bottom: 10px;
    }

    .tabss-content {
      padding: 0;
      border: none;
    }

    button {
      margin-top: -10px;
    }
  }

  h5 {
    margin-bottom: 0;
    display: flex;
    p {
      padding: 5px 8px;
      color: #4285f4;
      background: rgba(76, 83, 239, 0.08);
      display: inline-block;
      text-align: center;
      width: 40px;
      font-size: 12px;
      border-radius: 4px;
      margin-right: 15px;
    }
    .custom-toggle-btn {
      margin-left: auto;
    }
  }

  table {
    // margin-top: 15px;
    min-width: 100%;
    tr > td:nth-child(1),
    tr > th:nth-child(1) {
      width: 200px;
      max-width: 200px;
      min-width: 200px;

      @media (max-width: 499px) {
        width: 80px;
        max-width: 80px;
        min-width: 80px;
      }
    }
    tr > td:nth-child(2),
    tr > th:nth-child(2) {
      @media (max-width: 499px) {
        width: 30px;
        max-width: 30px;
        min-width: 30px;
      }
    }
    tr > td:nth-child(3),
    tr > th:nth-child(3) {
      width: 150px;
      max-width: 150px;
      min-width: 150px;
    }
    tr > td:last-child,
    tr > th:last-child {
      width: 25px !important;

      .common-table-actions {
        justify-content: flex-end;
      }
    }
    thead {
      th {
        padding: 0 5px !important;
        border: 0;
        opacity: 0.5;
        font-size: 12px;
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: #f2f3fe;
        }
        td {
          padding: 0;
          border: 0;
          //text-decoration-line: underline;
          color: #191919;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
          padding: 3px 0;

          &.notes {
            max-width: 100px;
          }
        }
      }
    }
  }

  .add-property-button {
    margin: 0 auto;
  }

  @media (max-width: 499px) {
    padding: 0px;
    margin-bottom: 0px;

    h5 {
      align-items: center;
      p {
        width: 25px;
        font-size: 6px;
        padding: 5px;
        line-height: 6px;
      }
      span {
        font-size: 20px;
      }
      .custom-toggle-btn {
        transform: scale(0.7);
      }
    }

    table {
      margin-bottom: 0;
      margin-top: 0;

      thead {
        tr {
          background-color: white !important;
          th {
            font-size: 6px !important;
          }
        }
      }

      tr {
        .common-table-actions {
          button {
            padding: 0;

            svg {
              width: 14px;
            }
          }
        }
      }
    }

    .MuiTextField-root {
      input {
        padding: 0.5px 10px;
      }
    }
  }
}

.modal__form {
  & .relation-input {
    width: 47.5%;
    margin-left: 11px;
  }
}
