.ant-checkbox-checked .ant-checkbox-inner {
  //background-color: #4285f4;
  border-color: #4285f4;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #4285f4;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0;
  padding: 0;
}

.ant-checkbox + span {
  padding: 0;
}
