$fieldHeight: 49px;
.card_container {
  position: relative;
  padding: 27px 37px 37px 30px;
  width: 622px;
  background: #f0f5ff;
  border: 1px dashed #d6d6d6;
  border-radius: 20px;
  margin: 0 auto;

  label {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #6c757d;
  }

  input {
    height: 49px;
    border: 1px solid #d6d6d6;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 10px;
    font-size: 16px;
  }

  .card_holder {
    position: absolute;
    top: 36px;
    right: 37px;
    width: 272px;
    display: flex;
    flex-direction: column;
  }

  .address_box {
    padding-left: 7px;
    .state,
    .postcode,
    .city,
    .address {
      position: relative;
      & > span {
        position: absolute;
        top: 75px;
        left: 0;
      }
    }

    .state,
    .postcode {
      width: 77px;
      input {
        width: inherit;
      }
    }

    .city,
    .address {
      width: 185px;
      input {
        width: inherit;
      }
    }

    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .btn-submit {
    position: absolute;
    bottom: -50px;
    right: 0;
  }

  .error_message {
    position: absolute;
    bottom: -25px;
    left: 0;
    color: red;
  }
  &-error {
    display: flex;
    flex-wrap: wrap;
    & > div {
      flex: 1;
      height: 0 /* 49px */;
      /* border: 1px solid red; */
      /* border-radius: 4px; */
    }
    & > div:last-child:not(:first-child) {
      margin-left: 30px;
    }
  }
  &-error-text {
    display: block;
    width: 100%;
    font-size: 12px;
    color: red;
  }
  /* &-error:hover {
    visibility: hidden;
  } */
  &-cardNumber,
  &-expiry,
  &-cvv {
    position: absolute;
  }
  &-cardNumber,
  &-expiry {
    left: 37.5px;
    width: 264.5px;
  }
  &-cvv {
    left: 309px;
    width: 273px;
  }

  &-cardNumber {
    top: calc(#{61.5px} + #{$fieldHeight});
  }
  &-expiry,
  &-cvv {
    top: calc(#{157.5px} + #{$fieldHeight});
  }

  /*  @media (max-width: 499px) {
    padding: 10px 10px 20px;
    border-radius: 10px;
  } */
}

.error_card {
  background: #ffe0ea;
  border: solid red 1px;
}
