.ant-popover-disabled-compatible-wrapper button {
  margin: 0 !important;
}
.ant-popover-disabled-compatible-wrapper {
  padding: 0.2rem !important;
}
.common-table-actions {
  // width: 100%;
  display: flex;
  align-items: center;
  //justify-content: space-around;
  .btn-light {
    background-color: unset;
    border-color: unset;
    border: none;
    box-shadow: none;
    padding: 0.25rem;
    &:hover,
    &:active,
    &:visited,
    &:disabled,
    &.disabled {
      background-color: unset !important;
      border-color: unset !important;
      border: none !important;
      box-shadow: none !important;
      button {
        background-color: unset !important;
        border-color: unset !important;
        border: none !important;
        box-shadow: none !important;
      }
    }

    // svg {
    //   fill: none;
    // }
  }
  button:hover {
    path {
      fill: #4285f4;
    }
  }

  .delete,
  .detach,
  .close {
    &:hover {
      path {
        fill: #f5222d;
      }
    }
  }
  .confirm,
  .confirm2 {
    &:hover {
      path {
        fill: rgb(28, 206, 28);
      }
    }
  }

  .btn-light {
    &.disabled {
      transform: translateX(-16%);
      opacity: 0.5;
      button {
        path {
          fill: #191919;
        }
      }
    }
    &:disabled:hover {
      path {
        fill: none;
      }
    }
  }

  @media (max-width: 499px) {
    svg {
      width: 12px;
    }
  }
}

div.ant-popover-inner > div > div > div.ant-popover-buttons {
  & > button.ant-btn.ant-btn-primary {
    background-color: #4285f4;
    border-color: #4285f4;
  }

  & > button.ant-btn-primary:active,
  & > button.ant-btn-primary.active {
    background-color: #444bd8;
    border-color: #444bd8;
  }

  & > button.ant-btn-primary:hover,
  & > button.ant-btn-primary:focus,
  & > button.ant-btn-primary:disabled,
  & > button.ant-btn-primary.disabled {
    background-color: #626afd;
    border-color: #626afd;
  }
}
