.tabss {
  //background-color: #f2f3fe;
  //border-bottom: 1px solid #f2f3fe;

  .tabss-list {
    list-style: none;
    padding: 0;
    // overflow-x: auto;
    display: flex;
    background: #fff;
    margin-bottom: -1px;
    border-top: 5px solid #fff;
    overflow-x: auto;
    padding-bottom: 3px;

    .tabss-list-item {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border: 1px solid #f2f3fe;
      background-color: #f2f3fe;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      cursor: pointer;
      white-space: nowrap;
      & > * {
        margin-left: 5px;
      }
      &-label {
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.01em;
        color: #191919;
        margin-left: 0;
      }

      .counter {
        color: #191919;
        background: #ffffff;
        width: 30px;
        height: 20px;
        font-size: 12px;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.active {
        background-color: #fff;
        // border-bottom: none;
        .counter {
          color: #4285f4;
          background: rgba(76, 83, 239, 0.08);
          border: 1px solid rgba(76, 83, 239, 0.08);
        }
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      }

      &:not(:last-child) {
        margin-right: 3px;
      }
      /* svg.shadow {
        path {
          fill: #4285f4;
        }
        filter: drop-shadow(0 7px 2px #4c53ef7a);
      } */
    }

    .tabss-list-new {
      padding: 10px;
      font-size: 10px;
      color: #4285f4;
      cursor: pointer;

      &.disabled {
        pointer-events: none;
        color: rgba(0, 0, 0, 0.38);
      }
    }
  }
  .tabss-content {
    //margin: 0 10px 10px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #f2f3fe;
    border-radius: 5px;
    border-top-left-radius: 0;
    width: 100%;
    //border-left-width: 5px;
    //border-right-width: 5px;
    //padding: 15px;
    //padding-top: 8px;
    .proposal-table {
      padding: 0 !important;
    }
    .clientsTableBody > tr:last-child > td {
      border-bottom: none;
    }

    .notificationBlock-wrapper {
      margin-bottom: 10px;
    }
  }

  .tabss-content-no-margin_padding {
    //margin: 0 10px 10px;
    padding: 0;
    padding-top: 0;
  }

  @media (max-width: 768px) {
    border-width: 5px;

    .tabss-list {
      // margin-bottom: 5px;

      .tabss-list-item {
        padding: 3px;

        &-label {
          font-size: 6px;
        }

        /*  & > * {
          margin-left: 5px;
        } */

        .counter {
          width: 8px;
          height: 9px;
          font-size: 4px;
        }

        svg {
          width: 8px;
          height: 8px;
        }
      }
    }

    .tabss-content {
      // margin: 0 5px 5px;
      padding: 5px;
    }
    .tabss-content-no-margin_padding {
      // margin: 0 5px 5px;
      padding: 0;
      padding-top: 0;
    }
  }
}
